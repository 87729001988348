import React, {useEffect, useState} from 'react';
import QrModel from './QrModel';
import {RxCross1} from 'react-icons/rx'
import apiClient from '../../api/apiClient';
import { toast } from 'react-toastify';

const SocialMedia = ({qrInfo,step,setStep,setQrData,...props}) => {
  console.log(qrInfo,"zxsa");
let initialState={
  "basicName":qrInfo?.qr_info?.info?.name||"",
  "description":qrInfo?.qr_info?.info?.description||"",
  "channel":"",
  "backgroundCol":qrInfo?.qr_info?.info?.color_palette?.background||"",
  "TextCol":qrInfo?.qr_info?.info?.color_palette?.text||"",
  "buttonCol":qrInfo?.qr_info?.info?.color_palette?.button||"",
  "welcome":"",
  "name":qrInfo?.name||"",
  "folder":qrInfo?.folder||"",
  qr_info: { type: "SocialMedia" },
  "inp":qrInfo?.qr_info?.info?.social_links||[],
}
const [socialMedia,setsocialMedia]=useState(initialState)
const [modalShow, setModalShow] = React.useState(false); 
const [field, setfield] = useState([]);
const onClick=(e) => {
  console.log(e);
  const index = field.findIndex((b) => e.id === b.id )
    if(index !== -1){

    }
  else
    setfield([...field, e])
  
}
const del=(e) =>{
  const dol = field.filter((b) => b.id !== e.id)
  setfield(dol)
}
  const handleChange=(key,value)=>{
    setsocialMedia({...socialMedia,[key]:value})
  }
  console.log(socialMedia.inp,"oioio");
  
  const handleSubmit=async(e)=>{
    e.preventDefault()
    console.log(socialMedia);
    socialMedia.qr_info.info = {
      name: socialMedia.basicName,
      description: socialMedia.description,
      social_links: socialMedia.inp,
      color_palette: {
      button: socialMedia.buttonCol,
      text: socialMedia.TextCol,
      background: socialMedia.backgroundCol,},
      file: socialMedia.file,
    };
  //   const result=await apiClient.post("/qrcode",socialMedia)
  // if(!result.ok) return toast.error("Error")
  //   console.log(result);
  }
  const handleInpChange=(index,value,type)=>{
   
if(socialMedia.inp.length>0&&index<=socialMedia.inp?.length-1){
  socialMedia.inp[index].value=value
  setsocialMedia((prevState) => ({
    ...prevState,
    inp:socialMedia.inp,
  }));
}else{  setsocialMedia((prevState) => ({
    ...prevState,
    inp: [...prevState.inp, 
      {type, value}
    ],
  }));
}
  }
const [folder,setFolder]=useState()
const fetchData=async()=>{
  const result =await apiClient.get("/folder")
setFolder(result.data.results)
}
useEffect(() => {
  fetchData()
 }, [])

 const [errors, setErrors] = useState({});
 const validateForm = () => {
   const newErrors = {};
   if (!socialMedia.basicName) {
     newErrors.basicName = 'basicName is required';
   }
   if (!socialMedia.description) {
     newErrors.description = 'description is required';
   }
   if (!socialMedia.channel) {
     newErrors.channel = 'channel is required';
   }
   if (!socialMedia.backgroundCol) {
     newErrors.backgroundCol = 'background is required';
   }
   if (!socialMedia.TextCol) {
     newErrors.TextCol = 'Text is required';
   }
   if (!socialMedia.buttonCol) {
     newErrors.buttonCol = 'button is required';
   }
   if (!socialMedia.name) {
     newErrors.name = 'name is required';
   }
   if (!socialMedia.folder) {
     newErrors.folder = 'folder is required';
   }
   setErrors(newErrors);
 
   
   return Object.keys(newErrors).length === 0;
 };
 useEffect(()=>{
  socialMedia.qr_info.info = {
    name: socialMedia.basicName,
    description: socialMedia.description,
    social_links: socialMedia.inp,
    color_palette: {
    button: socialMedia.buttonCol,
    text: socialMedia.TextCol,
    background: socialMedia.backgroundCol,},
    file: socialMedia.file,
  };
  const {folder,type,name,qr_info}=socialMedia
  setQrData({folder,type,name,qr_info})
 },[socialMedia])

 const nextStep=()=>{
  const formIsValid = validateForm();
  if (formIsValid) {
    const {folder,type,name,qr_info}=socialMedia
    console.log("object");
    if(props?.handleSubmit)
    return props?.handleSubmit({folder,type,name,qr_info})
     
 
setStep(step+1)}}
  return (


    <div className="container">
      <div className="row">
        <div className="col-11">
          <form onSubmit={handleSubmit} id="myForm">
            <div>
              <h5 className="fw-normal">Basic Information</h5>
              <div className="mb-2">
                <label htmlFor="" className="font-13">
                  basic Information*
                </label>
                <input
                  type="text"
                  name=""
                  value={socialMedia.basicName}
                  onChange={(e)=>handleChange("basicName",e.target.value)}
                  id=""
                  placeholder="Enter name"
                  className="form-control shadow-none light-grey-bg border-0"
                />
                 <span  className="text-danger font-12">
         {errors.basicName}
       </span>
              </div>
              <div className="mb-2">
                <label htmlFor="" className="font-13">
                  Description*
                </label>
                <input
                  type="text"
                  name=""
                  value={socialMedia.description}
                  onChange={(e)=>handleChange("description",e.target.value)}
                  id=""
                  placeholder="Select"
                  className="form-control shadow-none light-grey-bg border-0"
                />
                 <span  className="text-danger font-12">
         {errors.description}
       </span>
              </div>
              <h5 className="">Social Media Channel</h5>
              <div className="d-flex align-items-center flex-wrap mb-3">
                <div className="social" onClick={() => onClick({"Website":"urls", "link":"www.website.com", "id":"1"})}>
                  <img src={require("../../assets/urls.png")} alt="" className="social-links" />
                </div>
                <div className="social" onClick={() => onClick({"Website":"facebook", "link":"www.facebook.com", "id":"2"})}>
                <img src={require("../../assets/facebook.png")} alt="" className="social-links" />
                </div>
                <div className="social" onClick={() => onClick({"Website":"twitter", "link":"www.twitter.com", "id":"3"})}>
                <img src={require("../../assets/twitter.png")} alt="" className="social-links" />
                </div>
                <div className="social" onClick={() => onClick({"Website":"instagram", "link":"www.instagram.com", "id":"4"})}>
                <img src={require("../../assets/instagram.png")} alt="" className="social-links" />
                </div>
                <div className="social" onClick={() => onClick({"Website":"youtube", "link":"www.youtube.com", "id":"5"})}>
                <img src={require("../../assets/youtube.png")} alt="" className="social-links" />

                </div>
                <div className="social" onClick={() => onClick({"Website":"pinterest", "link":"www.pinterest.com", "id":"6"})}>
                <img src={require("../../assets/pinterest.png")} alt="" className="social-links" />

                </div>
                <div className="social" onClick={() => onClick({"Website":"google", "link":"www.google.com", "id":"7"})}>
                <img src={require("../../assets/google.png")} alt="" className="social-links" />

                </div>
                <div className="social" onClick={() => onClick({"Website":"linkedin", "link":"www.Linked-in.com", "id":"8"})}>
                <img src={require("../../assets/linkedin.png")} alt="" className="social-links" />

                </div>
                <div className="social" onClick={() => onClick({"Website":"skype", "link":"www.skype.com", "id":"9"})}>
                <img src={require("../../assets/skype.png")} alt="" className="social-links" />

                </div>
              </div>
              {
                field?.map((item,index) => {
                 
                  return(
                    <div className='position-relative'>
                    <button className='border-0 bg-transparent position-absolute' style={{right:"0"}} onClick={() => del(item)}><RxCross1/></button>
                    <h4>{item.Website}</h4>
                    <input type="text" disabled className='form-control' placeholder={item.link} />
                    <input type="text" 
                    // value={socialMedia.inp?.[index]?.value}
                     onChange={(e) => handleInpChange(`${index}`,e.target.value,item.Website)} className='form-control mt-2' placeholder=""/>
                    </div>
                  )
                })
              }


                {qrInfo && socialMedia?.inp.map((item,index)=>
                 <div className='position-relative'>
                 <button className='border-0 bg-transparent position-absolute' style={{right:"0"}} onClick={() => del(item)}><RxCross1/></button>
                 <h4>{item.Website}</h4>
                 <input type="text" value={socialMedia.inp?.[index]?.type} disabled className='form-control' placeholder={item.link} />
                 <input type="text" 
                 value={socialMedia.inp?.[index]?.value}
                  onChange={(e) => handleInpChange(`${index}`,e.target.value,item.Website)} className='form-control mt-2' placeholder=""/>
                 </div>
                )}





              <div className="mb-2">
                <label htmlFor="" className="font-13">
                  Youtube Channel*
                </label>
                <input
                  type="text"
                  name=""
                  value={socialMedia.channel}
                  onChange={(e)=>handleChange("channel",e.target.value)}
                  id=""
                  placeholder="Select"
                  className="form-control shadow-none light-grey-bg border-0"
                />
                 <span  className="text-danger font-12">
         {errors.channel}
       </span>
              </div>
            </div>
            <div className="row mt-4">
              <h5>Color palette</h5>
              <div className="col-sm-6">
                <label htmlFor="" className="font-13 form-label mb-0 w-100">
                  Background
                </label>
                <div className="border-0 light-grey-bg rounded-3 d-flex align-items-center justify-content-between py-2 px-2">
                  <p className="mb-0 color-display">{socialMedia.backgroundCol||"#3278E4"}</p>
                  <input
                    type="color"
                    value={socialMedia.backgroundCol}
                    onChange={(e)=>handleChange("backgroundCol",e.target.value)}
                    className="shadow-none border-0 color-input"
                    defaultValue="#3278E4"
                  />
                   <span  className="text-danger font-12">
         {errors.backgroundCol}
       </span>
                </div>
              </div>
              <div className="col-sm-6 mt-sm-0 mt-3">
                <label htmlFor="" className="font-13 form-label mb-0 w-100">
                  Text
                </label>
                <div className="border-0 light-grey-bg justify-content-between rounded-3 d-flex align-items-center py-2 px-2">
                  <p className="mb-0 color-display">{socialMedia.TextCol||"#ffff"}</p>
                  <input
                    type="color"
                    id=""
                    value={socialMedia.TextCol}
                    onChange={(e)=>handleChange("TextCol",e.target.value)}
                    className="shadow-none border-0 color-input"
                    defaultValue="#ffffff"
                  />
                   <span  className="text-danger font-12">
         {errors.TextCol}
       </span>
                </div>
              </div>
              <div className="col-sm-6 mt-sm-0 mt-3">
                <label htmlFor="" className="font-13 form-label mb-0 w-100">
                  Button
                </label>
                <div className="border-0 light-grey-bg justify-content-between rounded-3 d-flex align-items-center py-2 px-2">
                  <p className="mb-0 color-display">{socialMedia.buttonCol||"#3278E4"}</p>
                  <input
                    type="color"
                    value={socialMedia.buttonCol}
                    onChange={(e)=>handleChange("buttonCol",e.target.value)}
                    className="shadow-none border-0 color-input"
                    defaultValue="#5389FF"
                  />
                   <span  className="text-danger font-12">
         {errors.buttonCol}
       </span>
                </div>
              </div>
            </div>
            <h5 className="mt-3">Welcome Screen</h5>
            <p>Welcome</p>
            <h5 className="mt-3">Internal notes</h5>
            <div>
              <label htmlFor="for-name" className="form-label font-13 ps-2 mb-0">
                Name your code
              </label>
              <input
                type="text"
                value={socialMedia.name}
                onChange={(e)=>handleChange("name",e.target.value)}
                name=""
                id="for-name"
                className="form-control shadow-none light-grey-bg border-0"
                placeholder="Enter name"
              />
                <span  className="text-danger font-12">
                  {errors.name}
                </span>
            </div>
            <div className="mt-3">
              
              <div className="dropdown">
      <label htmlFor="" className="form-label font-13 mb-0 ps-2">
        Folder
      </label>
       
      </div>
              <div className="dropdown">
                <button
                  className="form-select shadow-none light-grey-bg border-0 text-start grey-clr"
                  type="button"
                  data-bs-toggle="dropdown"
                  aria-expanded="false"
                >
                  {!socialMedia?.folder?"Folder": folder?.find(x=>x._id===socialMedia.folder).name}
                </button>
                <ul className="dropdown-menu w-100 p-3">
                  <li onClick={() => setModalShow(true)}
                    className="blue-button-cl pointer"
                    style={{borderBottom:"1px solid black"}}
                    // data-bs-toggle="modal"
                    // data-bs-target="#folderModal"
                  >
                    <i className="bi bi-plus blue-button-cl fs-5" /> Add
                    new Folder
                  </li>
                  {folder?.map((item)=>
                   <li style={{cursor:"pointer"}} onClick={()=>setsocialMedia({...socialMedia,folder:item._id})} >{item.name}</li>
                   )}
                </ul>
                     


              </div>
              <span  className="text-danger font-12">
         {errors.folder}
       </span>
            </div>
            {/* <div className="mt-3">
              <div className="dropdown">
                <label htmlFor="" className="form-label font-13 mb-0 ps-2">
                  Folder
                </label>
              </div>
              <div className="dropdown">
                <button
                  className="form-select shadow-none light-grey-bg border-0 text-start grey-clr"
                  type="button"
                  data-bs-toggle="dropdown"
                  aria-expanded="false"
                >
                  Folder
                </button>
                <ul className="dropdown-menu w-100 p-3">
                  <li
                    onClick={() => setModalShow(true)}
                    className="blue-button-cl pointer"
                    style={{ borderBottom: "1px solid black" }}
                    // data-bs-toggle="modal"
                    // data-bs-target="#folderModal"
                  >
                    <i className="bi bi-plus blue-button-cl fs-5" /> Add new
                    Folder
                  </li>
                  {folder?.map((item) => (
                    <li
                      style={{ cursor: "pointer" }}
                      onClick={() => setsocialMedia({ ...socialMedia, folder: item._id })}
                    >
                      {item.name}
                    </li>
                  ))}
                </ul>
                <span  className="text-danger font-12">
         {errors.folder}
       </span>
              </div>
            </div> */}
            {step&&
      <div className="d-flex align-items-center mt-3">
              <div>
                <button onClick={() => setStep(step - 1)} className="light-blue2 px-5 py-2 blue-button-cl border-0 rounded-3">
                  Back
                </button>
              </div>
              <div className="ms-3">
                <button onClick={nextStep} className="px-5 py-2 text-white blue-button-bg border-0 rounded-3">
                  Next
                </button>
              </div>
            </div>}
      {qrInfo&&
      <div className="d-flex align-items-center mt-3">
             
              <div className="ms-3">
                <button onClick={nextStep} className="px-5 py-2 text-white blue-button-bg border-0 rounded-3">
                 Save
                </button>
              </div>
            </div>}
          </form>
          {modalShow&&  <QrModel  show={modalShow}
       handleChange={handleChange}
       value={socialMedia}
       fetchData={fetchData}
        onHide={() => setModalShow(false)}  />}
          
        </div>
      </div>
    </div>

  )
}

export default SocialMedia
