import React from "react";
import { useState, useEffect } from "react";
import QrModel from "./QrModel";
import apiClient from "../../api/apiClient";
import { toast } from 'react-toastify'

const Feedback = ({qrInfo,step,setStep,setQrData,...props}) => {
  console.log(qrInfo,"aqaq");
  let initialState = {
    color:qrInfo?.qr_info?.info?.color|| "",
    heaadline:qrInfo?.qr_info?.info?.headline|| "",
    Description:qrInfo?.qr_info?.info?.description|| "",
    name:qrInfo?.name||"",
    folder:qrInfo?.folder||"",
    website: qrInfo?.qr_info?.info?.website||"",
    categories: [],
    qr_info: { type: "Feedback" },
  };

  const [feedBack, setFeedBack] = useState(initialState);
  const [modalShow, setModalShow] = React.useState(false);
  const handleChange = (key, value) => {
    setFeedBack({ ...feedBack, [key]: value });
  };
  const handleSubmit = async (e) => {
    e.preventDefault();
    console.log(feedBack);
    feedBack.qr_info.info = {
      color: feedBack.color,
      headline: feedBack.heaadline,
      description: feedBack.Description,
      website: feedBack.website,
    };
    // const result = await apiClient.post("/qrcode", feedBack);
    // if (!result.ok) return toast.error("Error");
    // console.log(result);
  };
  const [folder, setFolder] = useState();
  const fetchData = async () => {
    const result = await apiClient.get("/folder");
    setFolder(result.data.results);
  };
  useEffect(() => {
    fetchData();
  }, []);

  const [errors, setErrors] = useState({});
  const validateForm = () => {
    const newErrors = {};
    if (!feedBack.color) {
      newErrors.color = 'color is required';
    }
    if (!feedBack.heaadline) {
      newErrors.heaadline = 'headline is required';
    }
    if (!feedBack.Description) {
      newErrors.Description = 'Description is required';
    }
    if (!feedBack.name) {
      newErrors.name = 'name is required';
    }
    if (!feedBack.folder) {
      newErrors.folder = 'folder is required';
    }
    if (!feedBack.website) {
      newErrors.website = 'website is required';
    }
    if (!feedBack.qr_info) {
      newErrors.qr_info = 'qr_info is required';
    }
    setErrors(newErrors);
  
    
    return Object.keys(newErrors).length === 0;
  };
  useEffect(()=>{
    feedBack.qr_info.info = {
      categories:feedBack.categories,
      color: feedBack.color,
      headline: feedBack.heaadline,
      description: feedBack.Description,
      website: feedBack.website,
    };
      const {folder,type,name,qr_info}=feedBack
      setQrData({folder,type,name,qr_info})
  },[feedBack])

  const nextStep=()=>{
    const formIsValid = validateForm();
    if (formIsValid) {
      const {folder,type,name,qr_info}=feedBack
      console.log("object");
      if(props?.handleSubmit)
      return props?.handleSubmit({folder,type,name,qr_info})
   
    setStep(step+1)}
  }
  const [field, setfield] = useState([]);
  const handleInpChange=(index,value,type)=>{
   
    if(feedBack.categories.length>0&&index<=feedBack.categories?.length-1){
      feedBack.categories[index]=value
      setFeedBack((prevState) => ({
        ...prevState,
        categories:feedBack.categories,
      }));
    }else{ setFeedBack((prevState) => ({
      ...prevState,
      categories: [...prevState.categories, value],
    }));
    }
      }

      const pushField=()=>{
        console.log(field);
        setfield([...field, ""]);
      }
      console.log(feedBack,"feeeed");
  return (
    <div className="container">
      <div className="row">
        <div className="col-11">
          <form onSubmit={handleSubmit} id="myForm">
            <div>
              <h5 className="fw-normal d-flex flex-wrap mb-2">Color Palette</h5>
              <div className="row">
                <div className="col-md-6">
                  <div className="mb-3">
                    <label htmlFor="" className="form-label font-13 mb-0 ps-2">
                      Background
                    </label>
                    <div className="border-0 rounded-3 d-flex align-items-center light-grey-bg justify-content-between py-2 px-2">
                      <p className="mb-0 color-display">
                        {feedBack.color || "#3278E4"}
                      </p>
                      <input
                        type="color"
                        value={feedBack.color}
                        onChange={(e) => handleChange("color", e.target.value)}
                        className="shadow-none border-0 color-input"
                        defaultValue="#3278E4"
                      />
                      <span  className="text-danger font-12">
         {errors.color}
       </span>
                    </div>
                  </div>
                </div>
                <h5>Basic Information</h5>
                <div className="col-12 mb-2">
                  <label htmlFor="" className="form-label font-13 mb-0 ps-2">
                    Headline*
                  </label>
                  <input
                    type="text"
                    name=""
                    value={feedBack.heaadline}
                    onChange={(e) => handleChange("heaadline", e.target.value)}
                    id=""
                    placeholder="Enter name"
                    className="light-grey-bg border-0 form-control shadow-none"
                  />
                  <span  className="text-danger font-12">
         {errors.heaadline}
       </span>
                </div>
                <div className="col-12 mb-2">
                  <label htmlFor="" className="form-label font-13 mb-0 ps-2">
                    Description*
                  </label>
                  <input
                    type="text"
                    value={feedBack.Description}
                    onChange={(e) =>
                      handleChange("Description", e.target.value)
                    }
                    name=""
                    id=""
                    placeholder="Select"
                    className="light-grey-bg border-0 form-control shadow-none "
                  />
                  <span  className="text-danger font-12">
         {errors.Description}
       </span>
                </div>
              </div>
            </div>
            <h5 className="mb-2">Internal Notes</h5>
            <div className="mb-2">
              <label htmlFor="" className="form-label font-13 mb-0 ps-2">
                Name your code*
              </label>
              <input
                type="text"
                value={feedBack.name}
                onChange={(e) => handleChange("name", e.target.value)}
                name=""
                id=""
                placeholder="Enter name"
                className="light-grey-bg border-0 form-control "
              />
              <span  className="text-danger font-12">
         {errors.name}
       </span>
            </div>
            <div className="mt-3">
              
              <div className="dropdown">
      <label htmlFor="" className="form-label font-13 mb-0 ps-2">
        Folder
      </label>
       
      </div>
              <div className="dropdown">
                <button
                  className="form-select shadow-none light-grey-bg border-0 text-start grey-clr"
                  type="button"
                  data-bs-toggle="dropdown"
                  aria-expanded="false"
                >
                  {!feedBack?.folder?"Folder": folder?.find(x=>x._id===feedBack.folder).name}
                </button>
                <ul className="dropdown-menu w-100 p-3">
                  <li onClick={() => setModalShow(true)}
                    className="blue-button-cl pointer"
                    style={{borderBottom:"1px solid black"}}
                    // data-bs-toggle="modal"
                    // data-bs-target="#folderModal"
                  >
                    <i className="bi bi-plus blue-button-cl fs-5" /> Add
                    new Folder
                  </li>
                  {folder?.map((item)=>
                   <li style={{cursor:"pointer"}} onClick={()=>setFeedBack({...feedBack,folder:item._id})} >{item.name}</li>
                   )}
                </ul>
                     


              </div>
              <span  className="text-danger font-12">
         {errors.folder}
       </span>
            </div>
            <h5 className="my-3">Category</h5>
    <div>
    {
                field?.map((item,index) => {
                 
                  return(
                    <div className='position-relative'>
                   
                    <h4>{item.Website}</h4>
                  
                    <input type="text" 
                    // value={feedBack.inp?.[index]?.value}
                     onChange={(e) => handleInpChange(`${index}`,e.target.value,item.Website)} className='form-control mt-2' placeholder=""/>
                    
                    




                    </div>
                  )
                })
              }
      <div className="dropdown-container">
        <button onClick={pushField}
          id="add-select-button"
          type="button"
          className="light-grey-bg w-100 border-0 py-2 blue-button-cl fw-bold d-flex align-items-center justify-content-center rounded-3"
        >
          <i className="bi bi-plus blue-button-cl fw-bold fs-5" />
          New
        </button>
      </div>
    </div>
            <h5 className="my-3">Contact Information</h5>
            <div>
              <label htmlFor="" className="form-label font-13 mb-0 ps-2">
                Website*
              </label>
              <input
                type="text"
                value={feedBack.website}
                onChange={(e) => handleChange("website", e.target.value)}
                className="form-control light-grey-bg shadow-none border-0"
                placeholder="name.com"
              />
              <span  className="text-danger font-12">
         {errors.website}
       </span>
            </div>
            {step&&
      <div className="d-flex align-items-center mt-3">
              <div>
                <button onClick={() => setStep(step - 1)} className="light-blue2 px-5 py-2 blue-button-cl border-0 rounded-3">
                  Back
                </button>
              </div>
              <div className="ms-3">
                <button onClick={nextStep} className="px-5 py-2 text-white blue-button-bg border-0 rounded-3">
                  Next
                </button>
              </div>
            </div>}
      {qrInfo&&
      <div className="d-flex align-items-center mt-3">
             
              <div className="ms-3">
                <button onClick={nextStep} className="px-5 py-2 text-white blue-button-bg border-0 rounded-3">
                 Save
                </button>
              </div>
            </div>}
          </form>
          {modalShow && (
            <QrModel
              show={modalShow}
              handleChange={handleChange}
              value={feedBack}
              fetchData={fetchData}
              onHide={() => setModalShow(false)}
            />
          )}
        </div>
      </div>
    </div>
  );
};

export default Feedback;
