import React, { useEffect } from 'react';
import lottie from 'lottie-web';
import animationData  from "./animation_logs4fye.json"
const Loader = () => {
    useEffect(() => {
        lottie.loadAnimation({
          container: document.getElementById('lottie-container'),
          animationData: animationData,
          loop: true, // Set to false if you don't want the animation to loop
          autoplay: true, // Set to false if you want to manually control the animation
          rendererSettings: {
            // Set your preferred dimensions here
            width: 200,
            height: 80,
          },
        });
      }, []);
  return (
    <div id="lottie-container">
      
    </div>
  )
}

export default Loader
