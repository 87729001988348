import React from 'react'
import { Link } from 'react-router-dom'

function NavigateWrapper({to,children}) {
  return (
    <Link style={{ color: 'inherit', // Preserves the default text color
    textDecoration: 'inherit', }} to={to} onClick={(e)=>{
        e.stopPropagation();
    }}>
        {children}
    </Link>
  )
}

export default NavigateWrapper