import React, { useEffect, useRef, useState } from 'react'
import apiClient from '../api/apiClient'
import { toast } from 'react-toastify'
import { useNavigate,useParams  } from 'react-router-dom'
import { toPng } from 'html-to-image'
import QRCodeStyling from 'qr-code-styling'
import Loader from './Loader'
let tempObj={
  width: 280,
  height: 280,
  type: 'svg',
  data:"https://example.com",
  dotsOptions: {
    color: "#000000",
    type: "square"
  },
  backgroundOptions:{
    color:"#ffffff",
  },
  cornersSquareOptions:{
    type: "dot"
  },
  imageOptions: {
    hideBackgroundDots: true,
    margin: 4,
    imageSize: 0.4,
    crossOrigin: "anonymous",
  }
}
function getStyleObject(data){
  return new QRCodeStyling(data||tempObj);
}
const StepThree = () => {
  const { id } = useParams();
  const[loading,setLoading]=useState(false)
  const ref = useRef(null);
  const [qrCode,setQrCode]=useState()
  const[qrData,setQrData]=useState()
  const[config,setConfig]=useState(null)
  const[frameConfig,setFrameConfig]=useState()
  // useEffect(() => {
  //   qrCode.append(ref.current);
  // }, []);
  useEffect(() => {
    if(config)
    qrCode.update(config)
  }, [config])
    const fetchQr=async()=>{
      const result=await apiClient.get(`/qrcode/${id}`)
      if(!result.ok) return
      let qrobj=getStyleObject(result.data.result.qr_data)
      qrobj.append(ref.current);
      setQrCode(qrobj)
      setConfig(result.data.result.qr_data)
      setFrameConfig(result.data.result.frame_data)
     
    }

    const htmlToImage = async() => {
      const contentToCapture = await document.getElementById("myContainer");
    
      toPng(contentToCapture, { cacheBust: false })
        .then((dataUrl) => {
          const link = document.createElement("a");
          link.download = "my-image-name.png";
          link.href = dataUrl;
          link.click();
        })
        .catch((err) => {
          console.log(err);
        });
    };
useEffect(()=>{
  fetchQr()
},[])
useEffect(()=>{
  
if(qrData?.qr_info)
  setConfig({
    ...config,data:formatQrData(qrData.qr_info.type,qrData.qr_info.info)
  })
},[qrData])
useEffect(() => {
  if(frameConfig?.frameColor)
  document.documentElement.style.setProperty('--frame-color', frameConfig.frameColor);
}, [frameConfig?.frameColor])
  const handleImageChange=async(e)=>{
    
    const formdata=new FormData()
      formdata.append("file",e.target.files[0])
      const res=await apiClient.post("/upload-file",formdata)
      if(!res.ok) return
      console.log(res.data.result);
      setConfig((prevConfig) => ({...prevConfig,image:res.data.result }))
    

  }

const navigate=useNavigate()
  const [isOpen, setIsOpen] = useState(false);

  const toggleDropdown = () => {
    setIsOpen(!isOpen);
  };
 
  const iconData = [
    { icon: "icon1.png", path: { cornersSquareOptions:{type: 'square'}}},
    { icon: "icon2.png", path: { dotsOptions:{type: 'dots'}, cornersDotOptions:{type: ''}, cornersSquareOptions:{type: ''}}},
    { icon: "icon3.png", path: { cornersDotOptions:{type: ''}, cornersSquareOptions:{type: 'extra-rounded'}, dotsOptions:{type: 'extra-rounded'},}},
    { icon: "icon4.png", path: { cornersSquareOptions:{type: 'dots'}, cornersDotOptions:{type: 'dots'}} },
    { icon: "icon1.png", label: 'Copy' },
    { icon: "icon1.png", label: 'Search' },
    { icon: "icon1.png", label: 'View' },
    { icon: "icon1.png", label: 'Send' },
    { icon: "icon1.png", label: 'Info' },
  ];
  const handleGoBack = () => {
    window.history.back(); // This will navigate back to the previous page in the browser history.
  };

  const updateConfig = (path) =>{

    // First set its initial values
   
    setConfig((prevConfig) => ({...prevConfig, cornersDotOptions:{type: 'square'},
       cornersSquareOptions:{type: 'square'}, dotsOptions:{type: 'square'} }));
       if(path){
         setConfig((prevConfig) => ({...prevConfig, ...path }));
       }
  }

  const setFrame = (path) =>{
    setFrameConfig((prevConfig) => ({...prevConfig, ...path }))
    // console.log(frameConfig);
  }

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true)
    const contentToCapture = document.getElementById("myContainer");
    const dataUrl = await toPng(contentToCapture, { cacheBust: false })
      .then((dataUrl) => dataUrl)
      .catch((err) => {
        console.log(err);
      });
    const formdata = new FormData();
    var data = await fetch(dataUrl).then((res) => res.blob());
    formdata.append("file", data, "image.png");
    
    const res = await apiClient.post("/upload-file", formdata);
    if (!res.ok) return toast.error("Failed to upload data");
    const combinedObject = {
      frame_data: frameConfig,
      image:res.data.result,
      qr_data: config,
    };
    console.log(combinedObject);
    const result=await apiClient.put(`/qrcode/${id}`,combinedObject)
    setLoading(true)
if(!result.ok) return toast.error("Error")
navigate("/admin/qrode")
  }

  return (
    <>
    <div className='container-md pb-5  ' >

<div className="row " >
    <div className="col-xl-7 ">
      <div className="my-container" ></div>
      <form onSubmit={handleSubmit} className="main-pills">
      <ul
        className="nav nav-pills mb-3 light-blue2 p-2 rounded-3"
        id="pills-tab"
        role="tablist"
      >
        <li className="nav-item" role="presentation">
          <button
            className="nav-link active"
            id="pills-home-tab"
            data-bs-toggle="pill"
            data-bs-target="#pills-home9"
            type="button"
            role="tab"
            aria-controls="pills-home"
            aria-selected="true"
          >
            Code
          </button>
        </li>
        <li className="nav-item" role="presentation">
          <button
            className="nav-link"
            id="pills-profile-tab"
            data-bs-toggle="pill"
            data-bs-target="#pills-profile9"
            type="button"
            role="tab"
            aria-controls="pills-profile"
            aria-selected="false"
          >
            Frame
          </button>
        </li>
        <li className="nav-item" role="presentation">
          <button
            className="nav-link"
            id="pills-contact-tab"
            data-bs-toggle="pill"
            data-bs-target="#pills-contact9"
            type="button"
            role="tab"
            aria-controls="pills-contact"
            aria-selected="false"
          >
            Logo
          </button>
        </li>
      </ul>
      <div className="tab-content" id="pills-tabContent">
        <div
          className="tab-pane fade show active"
          id="pills-home9"
          role="tabpanel"
          aria-labelledby="pills-home-tab"
          tabIndex={0}
        >
          <h6>Code</h6>
          <div className="row">
            <div className="col-sm-6">
            <label htmlFor="" className="font-13 form-label mb-0 w-100">Color</label>
              <div className="border-0 light-grey-bg rounded-3 d-flex align-items-center justify-content-between py-2 px-2">
                <p className="mb-0">{config?.dotsOptions?.color || '#000000'}</p>
                <input type="color" className="shadow-none border-0"
                onChange={(e) => setConfig((prevConfig) => ({...prevConfig, dotsOptions:{color: e.target.value} }))} 
                value={config?.dotsOptions?.color || '#000000'} />
              </div>
            </div>
            <div className="col-sm-6 mt-sm-0 mt-3">
            <label htmlFor="" className="font-13 form-label mb-0 w-100">Background</label>
              <div className="border-0 light-grey-bg justify-content-between rounded-3 d-flex align-items-center py-2 px-2">
                <p className="mb-0">{config?.backgroundOptions?.color || '#ffffff'}</p>
                <input type="color" className="shadow-none border-0"
                onChange={(e) => setConfig((prevConfig) => ({...prevConfig, backgroundOptions:{color: e.target.value} }))}
                value={config?.backgroundOptions?.color || '#ffffff'} />
              </div>
            </div>
          </div>
          <ul
            className="nav nav-pills mb-3 light-blue2 p-2 rounded-3 mt-4"
            role="tablist"
          >
            <li className="nav-item" role="presentation">
              <button
                className="nav-link"
                type="button"
                role="tab"
              >Edges</button>
            </li>
          </ul>
          <div className="tab-content" id="pills-tabContent">
            <div
              className="tab-pane fade show active"
              id="pills-basic9"
              role="tabpanel"
              aria-labelledby="pills-basic-tab"
              tabIndex={0}
            >
              <div className="row align-items-center">
                <div className="col-sm-2 mt-3">
                  {/* <label htmlFor="" className="font-13 form-label mb-0 w-100">Dots Styles</label>
                  <select className="form-select py-2 shadow-none light-grey-bg" placeholder='Style Type..'
                   onChange={(e) => setConfig((prevConfig) => ({...prevConfig, dotsOptions:{type: e.target.value} }))}
                   value={config?.dotsOptions?.type || 'square'} >
                    <option value="square" selected>Square</option>
                    <option value="dots">Dots</option>
                    <option value="rounded">Rounded</option>
                    <option value="extra-rounded">Extra rounded</option>
                    <option value="classy">Classy</option>
                    <option value="classy-rounded">Classy rounded</option>
                  </select> */}

                  <div className="dropdown custom-icons-dropdown">
                    <button className="btn btn-light dropdown-toggle" type="button" id="dropdownMenuButton"
                      data-bs-toggle="dropdown" aria-expanded="false" onClick={toggleDropdown}> 
                      <img width='20' src={require("../assets/icons/icon1.png")} alt='a' className="icon me-4" />
                    </button>
                    <div className={`dropdown-menu `} aria-labelledby="dropdownMenuButton" width='250px'>
                     
                          <div className="row">
                              {
                                iconData.map((ele, i) =>{
                                  return(
                                    <div className="col-4" onClick={()=> updateConfig(ele.path)} key={i}>
                                      <div className="dropdown-item p-3 w-auto">
                                      <img src={require(`../assets/icons/${ele.icon}`)} alt='a' className="icon" />
                                      </div>
                                    </div>
                                  )
                                })
                              }
                            </div>
                    </div>
                  </div>
                </div>


                <div className="col-sm-5 mb-2">
                <label htmlFor="" className="font-13 form-label mb-0 w-100">Inner</label>
                  <div className="border-0 rounded-3 d-flex align-items-center light-grey-bg justify-content-between py-2 px-2">
                    <p className="mb-0">{config?.cornersDotOptions?.color || '#000000'}</p>
                    <input type="color" className="shadow-none border-0" 

                    onChange={(e) => setConfig((prevConfig) => ({...prevConfig, cornersDotOptions:{color: e.target.value} }))}
                    value={config?.cornersDotOptions?.color || '#000000'}  />
                    
                  </div>
                </div>
                <div className="col-sm-5 mb-2">
                <label htmlFor="" className="font-13 form-label mb-0 w-100">Outer</label>
                  <div className="border-0 justify-content-between light-grey-bg rounded-3 d-flex align-items-center py-2 px-2">
                    <p className="mb-0">{config?.cornersSquareOptions?.color || '#000000'}</p>
                    <input type="color" className="shadow-none border-0"

                    onChange={(e) => setConfig((prevConfig) => ({...prevConfig, cornersSquareOptions:{color: e.target.value} }))}
                    value={config?.cornersSquareOptions?.color || '#000000'}  />

                  </div>
                </div>
              </div>
            </div>
            <div
              className="tab-pane fade"
              id="pills-advanced9"
              role="tabpanel"
              aria-labelledby="pills-advanced-tab"
              tabIndex={0}
            >
              <div className="row align-items-center">
                <div className="col-sm-4 mb-2">
                  <label htmlFor="" className="font-13 form-label mb-0 w-100">Dots Styles</label>
                  <select className="form-select py-2 shadow-none light-grey-bg" placeholder='Style Type..'
                   onChange={(e) => setConfig((prevConfig) => ({...prevConfig, dotsOptions:{type: e.target.value} }))}
                   value={config?.dotsOptions?.type || 'square'} >
                    <option value="square">Square</option>
                    <option value="dots">Dots</option>
                    <option value="rounded">Rounded</option>
                    <option value="extra-rounded">Extra rounded</option>
                    <option value="classy">Classy</option>
                    <option value="classy-rounded">Classy rounded</option>
                  </select>
                </div>

                <div className="col-sm-4 mb-2">
                  <label htmlFor="" className="font-13 form-label mb-0 w-100">Corner Dots Styles</label>
                  <select className="form-select py-2 shadow-none light-grey-bg" placeholder='Style Type..'
                   onChange={(e) => setConfig((prevConfig) => ({...prevConfig, cornersDotOptions:{type: e.target.value} }))}
                    value={config?.cornersDotOptions?.type || 'square'} >
                    <option value="square" >Square</option>
                    <option value="dot" >dot</option>
                  </select>
                </div>

                <div className="col-sm-4 mb-2">
                  <label htmlFor="" className="font-13 form-label mb-0 w-100">Corner Square Styles</label>
                  <select className="form-select py-2 shadow-none light-grey-bg" placeholder='Style Type..'
                   onChange={(e) => setConfig((prevConfig) => ({...prevConfig, cornersSquareOptions:{type: e.target.value} }))}
                   value={config?.cornersSquareOptions?.type || 'square'} >
                    <option value="square">Square</option>
                    <option value="dot">Dot</option>
                    <option value="extra-rounded">Extra rounded</option>
                  </select>
                </div>


              </div>
            </div>
          </div>
        </div>
        <div
          className="tab-pane fade"
          id="pills-profile9"
          role="tabpanel"
          aria-labelledby="pills-profile-tab"
          tabIndex={0}
        >
          <div>
            <div className="row">
              <div>
                <label htmlFor="" className="form-label mb-0">Type</label>
                <div className="row align-items-center mt-1">
                  <div className="col-3">
                    <div>
                      <img
                        src={require("../assets/no frame.png")}
                        alt=""
                        className={`frame-img ${frameConfig?.type==='none' && 'border border-5 border-primary'}`}
                        onClick={() => setFrame({type:'none'})}
                      />
                    </div>
                  </div>
                  <div className="col-3">
                    <div>
                      <img
                        src={require("../assets/down.png")}
                        alt=""
                        className={`frame-img ${frameConfig?.type==='frame3' && 'border border-5 border-primary'}`}
                        onClick={() => setFrame({type:'frame3'})}
                      />
                    </div>
                  </div>
                  <div className="col-3">
                    <div>
                      <img
                        src={require("../assets/static.png")}
                        alt=""
                        className={`frame-img ${frameConfig?.type==='frame1' && 'border border-5 border-primary'}`}
                        onClick={() => setFrame({type:'frame1'})}
                      />
                    </div>
                  </div>
                  <div className="col-3">
                    <div>
                      <img
                        src={require("../assets/up.png")} 
                        alt="" 
                        className={`frame-img ${frameConfig?.type==='frame2' && 'border border-5 border-primary'}`} 
                        onClick={() => setFrame({type:'frame2'})}
                        />
                    </div>
                  </div>
                </div>
              </div>
              <div className="row mt-3">
                <div className="col-9">
                  <label htmlFor="" className="form-label mb-0 font-13">
                    Custom text
                  </label>
                  <input
                    type="text"
                    placeholder="Scan me"
                    className="form-control shadow-none light-grey-bg border-0"
                    value={frameConfig?.text || ''}
                    onChange={(e) => setFrame({text: e.target.value})}
                  />
                </div>
                <div className="col-3">
                  <label htmlFor="" className="form-label mb-0 font-13">
                    Size
                  </label>
                  <select
                    name=""
                    id=""
                    className="form-select shadow-none light-grey-bg border-0"
                    onChange={(e) => setFrame({textSize: e.target.value })}
                    value={frameConfig?.textSize || '20'} 
                    >
                    <option value="10">10</option>
                    <option value="15">15 </option>
                    <option value="20">20</option>
                    <option value="25">25</option>
                    <option value="30">30</option>
                  </select>
                </div>
              </div>
              <div className="row mt-3">
                <div className="col-6">
                <label htmlFor="" className="font-13 form-label mb-0 w-100">Frame Color</label>
                  <div className="border-0 light-grey-bg rounded-3 d-flex align-items-center justify-content-between py-2 px-2">
                    <p className="mb-0">{frameConfig?.frameColor || '#ffffff'}</p>
                    <input type="color" className="shadow-none border-0" 
                    onChange={(e) => setFrame({frameColor: e.target.value })}
                    value={frameConfig?.frameColor || '#ffffff'} />
                    
                  </div>
                </div>
                <div className="col-6">
                <label htmlFor="" className="font-13 form-label mb-0 w-100">Text Color</label>
                  <div className="border-0 light-grey-bg justify-content-between rounded-3 d-flex align-items-center py-2 px-2">
                    <p className="mb-0">{frameConfig?.textColor || '#ffffff'}</p>
                    <input type="color" className="shadow-none border-0" 
                    onChange={(e) => setFrame({textColor: e.target.value })}
                    value={frameConfig?.textColor || '#ffffff'} />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div
          className="tab-pane fade"
          id="pills-contact9"
          role="tabpanel"
          aria-labelledby="pills-contact-tab"
          tabIndex={0}
        >
          <div action="" className="main-height">
            <div className="d-flex">
              <label htmlFor="img-file">
                <img src={require("../assets/plus.png")} alt="" className="plus-img" />
              </label>
              <input
                type="file"
                defaultValue=""
                className="d-none"
                id="img-file"
                accept='png, jpeg, jpg'
                onChange={handleImageChange}
              />
              <div className="ms-2">
                <img src={require("../assets/none.png")} alt="" className="plus-img" 
                onClick={() => setConfig((prevConfig) => ({...prevConfig, image: "" }))}/>
              </div>
            </div>
          </div>
        </div>
      </div>
      {!loading?
      <div className="d-flex align-items-center mt-3">
              <div>
                <button  onClick={(e)=>{
                  e.preventDefault()
                  handleGoBack()
                  }}  className="light-blue2 px-5 py-2 blue-button-cl border-0 rounded-3">
                  Cancel 
                </button>
              </div>
            
              <div className="ms-3">
                <button type='submit'  className="px-5 py-2 text-white blue-button-bg border-0 rounded-3">
                  Save
                </button>
              </div>
            
            </div>: <div className='d-flex justify-content-center'>
             <Loader />
           </div>}
    </form>
    </div>

    <div className="col-xl-4 offset-xl-1 col-md-5 p-5 mt-md-0 mt-3 " >
          <h3 className="fw-normal">Preview</h3>
          <div className="mt-3">
           



          <div id="myContainer" style={{background:'transparent', display:"inline-block"}}>
                <div className={ `frame ${frameConfig?.type !== 'none' ? 'active '+ frameConfig?.type : ''}`}>
                <div className="frame-item">
                  {/* Qr Code */}
                   <div className="qr" ref={ref} ></div>
                </div>
                   <p className="text mb-3" style={
                    {color: frameConfig?.textColor, fontSize: frameConfig?.textSize+'px'}}>
                    {frameConfig?.text || ''}</p>
                </div>
          </div>
    




          {/* </div> */} <br /> <br />
            <button className='px-5 py-2 text-white blue-button-bg border-0 rounded-3' onClick={htmlToImage}>
              Download</button>
          </div>
          <div className="mt-5">
            <h6 className="text-dark">Scan to see your QR code live.</h6>
            <p className="grey-clr mb-0 font-13">
              You will be able to modify and customize the design of the code in the
              final step of this code creation process.
            </p>
          </div>
        </div>
        </div>
        </div>

    </>
  )
}

export default StepThree

function formatQrData(type, data) {
  switch (type) {
    case "Url":
      return data.url;

    case "Text": {
      const {  text } = data;
      return `${text}`;
    }

    case "Whatsapp": {
      const { rec_phone, text } = data;
      return `https://wa.me/${rec_phone}?text=${encodeURIComponent(text)}`;
    }

    case "SMS": {
      const { rec_phone, text } = data;
      return `sms:${rec_phone}?body=${encodeURIComponent(text)}`;
    }

    case "Email": {
      const { to, subject, text } = data;
      return `mailto:${to}?subject=${encodeURIComponent(subject)}&body=${encodeURIComponent(text)}`;
    }
    

    case "VCard": {
      const vCard = [
        "BEGIN:VCARD",
        "VERSION:3.0",
        `N:${data.last_name};${data.first_name};;;`,
        `FN:${data.first_name} ${data.last_name}`,
        `ORG:${data.company}`,
        `TEL;TYPE=work,voice:${data.phone}`,
        `EMAIL:${data.email}`,
        `ADR;TYPE=work:;;${data.location.street};${data.location.city};${data.location.state};${data.location.zip};${data.location.country}`,
        `URL:${data.website}`,
        "END:VCARD"
      ].join("\r\n");
      
      return vCard;
    }


    default:
      return "";
  }
}
