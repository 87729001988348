import React from 'react'
import { useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
const About = () => {
  const navigate=useNavigate()
  const {isLoggedIn} = useSelector((state) => state.auth)
  const handleNavigate=()=>{
    if(isLoggedIn)
    {
      navigate("/admin/createqr")
    }
    else{
      navigate("/login")
    }
  }
  return (
    <>
    {/* -----------------
    start|| hero
----------------- */}
    <div className="hero-sec bg-lightblue mx-3">
      <div className="mycontainer">
        <div className="row d-flex justify-content-center py-5 mb-5 ">
          <div className="col-12 col-md-8 col-lg-6 text-center">
            <div>
              <h1 className="fw-bolder display-3">
                <span className=" blue-clr">About Us</span>
              </h1>
              <p>
                Elevate your business, captivate your audience, and connect with
                simplicity. At InstantQRcodes.com, we empower you to create,
                customize, and track QR codes like never before.
              </p>
            </div>
            <div className="mt-5">
              <a
              style={{cursor:"pointer"}}
              onClick={()=>handleNavigate()}
                className="bg-blue w-auto text-center text-decoration-none px-3 bg-myblue text-white py-3 px-4 rounded-4 fs-6 bs-opacity mt-3 mt-md-0"
              >
                <span className="me-3">Create QR code</span>
                <i className="fa-solid fa-arrow-right" />
              </a>
            </div>
            <div className="blogs-img mt-5">
              <img src={require("../assets/barcode.png")} alt="" />
            </div>
          </div>
        </div>
      </div>
    </div>
    {/* -----------------
    end|| hero 
----------------- */}
    {/* -----------------
    start || Benefits
----------------- */}
    <div className="Benefits py-5">
      <div className="mycontainer">
        <div className="row d-flex flex-column-reverse flex-md-row justify-content-between align-items-center">
          <div className="col-md-6 mt-5 mt-md-0">
            <div className="p-lg-5">
              <img src={require("../assets/track.png")} alt="track" className="img-fluid" />
            </div>
          </div>
          <div className="col-md-6">
            <div className="p-lg-5">
              <h2 className=" display-3 fw-bold">
                <span className=" blue-clr fw-bold">Benefits from</span>
                QR Scanner.
              </h2>
              <p className="mb-0">
                In publishing and graphic design, Lorem ipsum is a placeholder
                text commonly used to demonstrate the visual form of a document
                or a typeface without relying on meaningful content. In
                publishing and graphic design, Lorem ipsum is a placeholder text
                commonly used.
              </p>
              <div className="mt-5">
                <a
                  href="#"
                  className="bg-blue w-auto text-center text-decoration-none px-3 bg-myblue text-white py-3 px-4 rounded-4 fs-6 bs-opacity mt-3 mt-md-0"
                >
                  <span className="me-3">Create QR code</span>
                  <i className="fa-solid fa-arrow-right" />
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    {/* -----------------
        end || Benefits
----------------- */}
    {/* -----------------
    start || stats
----------------- */}
    <div className="stats py-2">
      <div className="mycontainer">
        <div className="row d-flex blue-bg justify-content-between align-items-center rounded-4 py-3 p-sm-4">
          <div className="col-sm-6 my-3 mt-lg-0 col-lg-3">
            <div className="text-center brd">
              <p className="text-white mb-0 mb-lg-2">QR Codes Generated</p>
              <h3 className="text-white">1.5M+</h3>
            </div>
          </div>
          <div className="col-sm-6 my-3 mt-lg-0 col-lg-3">
            <div className="text-center brd brd2">
              <p className="text-white mb-0 mb-lg-2">Visits to QR Codes</p>
              <h3 className="text-white">1.5M+</h3>
            </div>
          </div>
          <div className="col-sm-6 my-3 mt-lg-0 col-lg-3">
            <div className="text-center brd">
              <p className="text-white mb-0 mb-lg-2">Users</p>
              <h3 className="text-white">1.9k+</h3>
            </div>
          </div>
          <div className="col-sm-6 my-3 mt-lg-0 col-lg-3">
            <div className="text-center">
              <p className="text-white mb-0 mb-lg-2">Dynamic QR Codes</p>
              <h3 className="text-white">1.3M+</h3>
            </div>
          </div>
        </div>
      </div>
    </div>
    {/* -----------------
        end || stats
----------------- */}
    {/* -----------------
    start || types
----------------- */}
    <div className="types py-3">
      <div className="mycontainer">
        <div className="row d-flex  lightble-bg justify-content-between align-items-center rounded-4 p-4">
          <div className="col-sm-6">
            <div className="row d-flex flex-column">
              <div className="col-md-7 col-lg-6 mt-2">
                <div className="d-flex align-items-center items p-2">
                  <div>
                    <img src={require("../assets/cardqr.png")} alt="" />
                  </div>
                  <div>
                    <p className="blue-clr mb-0 mx-3">Dynamic QR Codes</p>
                  </div>
                </div>
              </div>
              <div className="col-md-8 col-lg-7 mt-2">
                <div className="d-flex align-items-center items p-2">
                  <div>
                    <img src={require("../assets/cardqr.png")} alt="" />
                  </div>
                  <div>
                    <p className="blue-clr mb-0 mx-3">Static QR Codes</p>
                  </div>
                </div>
              </div>
              <div className="col-md-10 col-lg-9 mt-2">
                <div className="d-flex align-items-center items p-2">
                  <div>
                    <img src={require("../assets/cardqr.png")} alt="" />
                  </div>
                  <div>
                    <p className="blue-clr mb-0 mx-3">
                      Fully customized landing pages
                    </p>
                  </div>
                </div>
              </div>
              <div className="col-md-7 col-lg-6 mt-2">
                <div className="d-flex align-items-center items p-2">
                  <div>
                    <img src={require("../assets/cardqr.png")} alt="" />
                  </div>
                  <div>
                    <p className="blue-clr mb-0 mx-3">QR Code Statistics</p>
                  </div>
                </div>
              </div>
              <div className="col-md-8 col-lg-7 mt-2">
                <div className="d-flex align-items-center items p-2">
                  <div>
                    <img src={require("../assets/cardqr.png")} alt="" />
                  </div>
                  <div>
                    <p className="blue-clr mb-0 mx-3">No Coding Required</p>
                  </div>
                </div>
              </div>
              <div className="col-lg11 mt-2">
                <div className="d-flex align-items-center items p-2">
                  <div>
                    <img src={require("../assets/cardqr.png")} alt="" />
                  </div>
                  <div>
                    <p className="blue-clr mb-0 mx-3">
                      Customized Colors &amp; Shapes for QR Codes
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="col-sm-6 mt-4 mt-sm-0">
            <img src={require("../assets/aboutbtm.png")} alt="about" className="img-fluid" />
          </div>
        </div>
      </div>
    </div>
    {/* -----------------
        end || types
----------------- */}
    <div className="services-faq">
      <div className="mycontainer">
        <div className="row justify-content-center py-md-5 my-5">
          <div className="col-lg-9">
            <h2 className="display-4 blue-clr fw-md text-center mb-4">
              Frequently Asked Question
            </h2>
            <div className="accordion" id="accordionExample">
              <div className="accordion-item mt-4">
                <h2 className="accordion-header" id="headingOne">
                  <button
                    className="accordion-button"
                    type="button"
                    data-bs-toggle="collapse"
                    data-bs-target="#collapseOne"
                    aria-expanded="true"
                    aria-controls="collapseOne"
                  >
                    <p className="fw-bolder mb-0 blue-clr bg-transparent">
                      In publishing and graphic design, Lorem ipsum is a
                      placeholder?
                    </p>
                  </button>
                </h2>
                <div
                  id="collapseOne"
                  className="accordion-collapse collapse show"
                  aria-labelledby="headingOne"
                  data-bs-parent="#accordionExample"
                >
                  <div className="accordion-body">
                    <p className="mb-0 small">
                      In publishing and graphic design, Lorem ipsum is a
                      placeholder text commonly used to demonstrate the visual
                      form of a document or a typeface without relying on
                      meaningful content.
                    </p>
                  </div>
                </div>
              </div>
              <div className="accordion-item mt-4">
                <h2 className="accordion-header" id="headingTwo">
                  <button
                    className="accordion-button collapsed"
                    type="button"
                    data-bs-toggle="collapse"
                    data-bs-target="#collapseTwo"
                    aria-expanded="false"
                    aria-controls="collapseTwo"
                  >
                    <p className="fw-bolder mb-0 blue-clr bg-transparent">
                      In publishing and graphic design, Lorem ipsum is a
                      placeholder?
                    </p>
                  </button>
                </h2>
                <div
                  id="collapseTwo"
                  className="accordion-collapse collapse"
                  aria-labelledby="headingTwo"
                  data-bs-parent="#accordionExample"
                >
                  <div className="accordion-body">
                    <p className="mb-0 small">
                      In publishing and graphic design, Lorem ipsum is a
                      placeholder text commonly used to demonstrate the visual
                      form of a document or a typeface without relying on
                      meaningful content.
                    </p>
                  </div>
                </div>
              </div>
              <div className="accordion-item mt-4">
                <h2 className="accordion-header" id="headingThree">
                  <button
                    className="accordion-button collapsed"
                    type="button"
                    data-bs-toggle="collapse"
                    data-bs-target="#collapseThree"
                    aria-expanded="false"
                    aria-controls="collapseThree"
                  >
                    <p className="fw-bolder mb-0 blue-clr bg-transparent">
                      In publishing and graphic design, Lorem?
                    </p>
                  </button>
                </h2>
                <div
                  id="collapseThree"
                  className="accordion-collapse collapse"
                  aria-labelledby="headingThree"
                  data-bs-parent="#accordionExample"
                >
                  <div className="accordion-body">
                    <p className="mb-0 small">
                      In publishing and graphic design, Lorem ipsum is a
                      placeholder text commonly used to demonstrate the visual
                      form of a document or a typeface without relying on
                      meaningful content.
                    </p>
                  </div>
                </div>
              </div>
              <div className="accordion-item mt-4">
                <h2 className="accordion-header" id="headingFour">
                  <button
                    className="accordion-button collapsed"
                    type="button"
                    data-bs-toggle="collapse"
                    data-bs-target="#collapseFour"
                    aria-expanded="false"
                    aria-controls="collapseFour"
                  >
                    <p className="fw-bolder mb-0 blue-clr bg-transparent">
                      In publishing and graphic ?
                    </p>
                  </button>
                </h2>
                <div
                  id="collapseFour"
                  className="accordion-collapse collapse"
                  aria-labelledby="headingFour"
                  data-bs-parent="#accordionExample"
                >
                  <div className="accordion-body">
                    <p className="mb-0 small">
                      In publishing and graphic design, Lorem ipsum is a
                      placeholder text commonly used to demonstrate the visual
                      form of a document or a typeface without relying on
                      meaningful content.
                    </p>
                  </div>
                </div>
              </div>
              <div className="accordion-item mt-4">
                <h2 className="accordion-header" id="headingFive">
                  <button
                    className="accordion-button collapsed"
                    type="button"
                    data-bs-toggle="collapse"
                    data-bs-target="#collapseFive"
                    aria-expanded="false"
                    aria-controls="collapseFive"
                  >
                    <p className="fw-bolder mb-0 blue-clr bg-transparent">
                      In publishing and graphic design, Lorem?
                    </p>
                  </button>
                </h2>
                <div
                  id="collapseFive"
                  className="accordion-collapse collapse"
                  aria-labelledby="headingFive"
                  data-bs-parent="#accordionExample"
                >
                  <div className="accordion-body">
                    <p className="mb-0 small">
                      In publishing and graphic design, Lorem ipsum is a
                      placeholder text commonly used to demonstrate the visual
                      form of a document or a typeface without relying on
                      meaningful content.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </>
  )
}

export default About
