import React from 'react'
import { Link } from 'react-router-dom'

const ImagesScan = ({qrData}) => {
    console.log(qrData,"object");
    return (
       <div className="smartphone">
         <div className='smartphone-inner'>
            <div className='sec-h d-flex flex-column container text-white p-0'  >
                <div className='p-2'  style={{backgroundColor:`${qrData?.qr_info?.info?.color || "#3278E4"}`,color:`${qrData?.qr_info?.info?.text || "white"}`}}>
                    <Link className="text-center d-block text-decoration-none text-white">{qrData?.qr_info?.info?.link ||"Website Link"}</Link>

                    <div className="ms-2 h2 fw-semibold">{qrData?.qr_info?.info?.headline ||"Name"} </div>
                    <div className="ms-2 fs-5">{qrData?.qr_info?.info?.description ||"Description "} </div>
                </div>
                <div className='mt-4 text-center  w-100 h-50'>

                    <img className='h-100 w-100 object-fit-contain' src={qrData?.qr_info?.info?.file} alt="" />
                </div>
            </div>

        </div>
       </div>
    )
}

export default ImagesScan
