import {  Paper, Typography,Button } from '@mui/material';
import { Box, Stack } from '@mui/system';
import React, {  useState } from 'react';

import { useEffect } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import useApi from '../hooks/useApi';
import apiClient from '../api/apiClient';
import AdminButton from '../components/mui/AdminButton';
import TableMui from '../components/mui/TableMui';
import { UserMenuOptions } from '../components/mui/FieldEditDropdown';
import Paginate from '../components/mui/Paginate';
let limit=10
const BlogsDashboard=()=>{
    const [count,setCount]=useState(0)
    const [blogs,setBlogs]=useState()
    const [loading, setLoading] = useState(false);
    const [current, setCurrent] = useState();
    const [deleteModal, setDeleteModal] = useState(false);
    const {data,error,request}=useApi((endpoint)=>apiClient.get(endpoint))
    const navigate = useNavigate();
    const [offset,setOffset]=useState(0)

    let Endpoint = `blog/?limit=${limit}`;
    useEffect(()=>{
      fetchData(Endpoint)
    },[])
    const fetchData = async (endpoint) => {
      setLoading(true)
     const response= await request(endpoint)
     if(error)
     return 
    if(!count)
    console.log(response,"pol");
    setCount(response.data.count)
    setBlogs(response.data.results)
    

    
    setLoading(false)


    }



    const handlePageChange = (event,value) => {

      value=((value - 1) * limit)
      fetchData(Endpoint)
      setOffset(value)



    }

    const handleDelete= async (item)=>{
    
      const response= await apiClient.delete(`blog/${item._id}/`)
      if(response.status!=204)
      return 
      else{
       fetchData(Endpoint+offset)
        toast.success('deleted success fully')
 
        setDeleteModal(false)
      }
     }
     const handleTrash= async (id)=>{
    
      const response= await apiClient.put(`blog/${id}/`,{status:"T"})
      if(!response.ok)
      return 
     
      toast.success('Trashed successfully')
       fetchData(Endpoint)
 
     
     }
     const blogDetail=(item)=>{
    console.log(item,"kopl");
    
      navigate('/blogs-detail/'+item._id,{state:item});
  
      }
    const blogEdit=(item)=>{
  
  
      navigate('/admin/blog-edit/'+item._id,{state:item});
  
      }
      
      return (
<div className='container'>
     
      <div className=''>
        <Box component={Paper} sx={{ marginBottom: "20px", padding: "20px" }}>
          
      {/* {deleteModal && <DeleteConfirmation  values={{handleConfirm:()=>handleDelete(current.id),show:deleteModal,onHide:()=>setDeleteModal(false), message:`Are you sure you want to delete Candidate ${current.title}`}} />} */}
 
         <AdminButton onClick={()=>navigate('/admin/blog')}
                              name="Add New"

                            />
        <Typography variant="h5" sx={{ marginBottom: "10px" }}>
      Blogs
        </Typography>

          <Box>
            <TableMui
              styleTableTh={{backgroundColor:"#3278E4", fontWeight: "bold", whiteSpace: "nowrap" }}
              loading={loading}
              th={{
                title: "Title",
                    description: "Description",
                    createdAt: "Date",
                    status:"Status",
                    actions:"Action",
              }}
              td={blogs}

              customFields={[
                {name:"status",data:(value,item)=>(
                <>
                   {value == "D" ? "Draft" : value == "T" ? "Trash" : "Publish"}
                    </> 
                  )},
                {name:"description",data:(value,item)=>(
                <>
                   {value.substring(0, 20) + "..."}
                    </> 
                  )},
                {name:"createdAt",data:(value,item)=>(
                <>
                   {value.split('T')[0]}
                    </> 
                  )},
                
                {name:"actions",data:(value,item)=>(
                <div className='d-flex gap-3 justify-content-center'>
            
             {/* <AdminButton onClick={()=>handleTrash(item._id)}
                                        name="Trash"
                                        style={{backgroundColor:'red',"&:hover": { backgroundColor: "#e32636" }}}
                                        disabled={item.status=="T"}
                                      />
                <AdminButton onClick={()=>blogDetail(item)}
                                        name="Detail"
          
          
                                      />
                <AdminButton onClick={()=>blogEdit(item)}
                                        name="Edit"
                                        style={{  backgroundColor: "#b09150",
                                        "&:hover": { backgroundColor: "#c9a55a" }}}
                                      />
          
                <AdminButton className="btn btn-danger" style={{backgroundColor:'red',"&:hover": { backgroundColor: "#e32636" }}} onClick={()=>{
          
          handleDelete(item)
                }}
                                        name="Delete"
          
                                      /> */}
                                      <UserMenuOptions handleDelete={handleDelete} handleTrash={handleTrash} blogDetail={blogDetail}  blogEdit={blogEdit} item={item} />
                </div>
              )}]}
                />

          </Box>
          <Paginate count={count} limit={limit} onChange={handlePageChange}/>
          </Box>
          </div>
          </div>

      )
    }
    export default BlogsDashboard




