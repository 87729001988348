import React, { useEffect, useState } from 'react'
import apiClient from '../api/apiClient'
import { useNavigate } from 'react-router-dom'
import { useSelector } from 'react-redux'
import Loader from '../components/Loader'

const Prices = () => {
const navigate=useNavigate()
const {isLoggedIn} = useSelector((state) => state.auth)
const [packag,setPackag]=useState()
const [load,setLoad]=useState(false)
const fetchData=async()=>{
  const result =await apiClient.get("/packages")
  console.log(result);
  setPackag(result.data.results)
}


const subscription=async(id)=>{
  setLoad(true)
  const result=await apiClient.post(`/subscription/`,{pkg_id :id})
  if(!result.ok) return
  // window.location.href =`${result.data.url}`
  setLoad(false)
  window.open(`${result.data.url}`, '_blank')
  console.log(result);
}


useEffect(()=>{
  fetchData()
},[])
  return (
    <>
    
    <div className="package pt-5">
      <div className="mycontainer">
        <div className="row d-flex justify-content-center align-items-center">
          <div className="col-12 col-md-8 col-lg-7 col-xl-6 text-center">
            <h2 className=" display-3">
              <span className="blue-clr fw-bold">Get your package</span>
            </h2>
            <p className="mb-0 mt-3 fw-md">
              Elevate your business, captivate your audience, and connect with
              simplicity. At InstantQRcodes.com, we empower you to create,
              customize, and track QR codes like never before.
            </p>
          </div>
        </div>
      </div>
    </div>
    {/* -----------------
        end || Get your package
----------------- */}
    {/* -----------------
        start || cards 
    ----------------- */}
    <div>
      <div className="mycontainer py-3">
        <div className="row d-flex justify-content-between">
          <div className="col-12 col-sm-6 col-lg-4">
            <div className="card2 mx-2 d-flex flex-column p-4 border-0 mt-3">
              <div className="blue-bg text-center align-items-center text-white py-4 rounded-4">
                <p className=' text-white'>Free 7 days</p>
                <h3>Free</h3>
              </div>
              <div className=" card-body">
                <p className="card-text mt-5">
                  In publishing and graphic design, Lorem ipsum is a placeholder
                  text commonly used to demonstrate the visual form of a
                </p>
              </div>
              {/* <div className="mt-auto">
                <button className="form-control blue-bg text-white py-3 rounded-4">
                  Choose
                </button>
              </div> */}
            </div>
          </div>
          {packag?.map((pack)=>
          
          <div className="col-12 col-sm-6 col-lg-4">
            <div className="card2 mx-2 d-flex flex-column p-4 border-0 mt-3">
              <div className="blue-bg text-center align-items-center text-white py-4 rounded-4">
                <p className=' text-white'>{pack.type}</p>
                <h3>{pack.price}/ {pack.type}</h3>
              </div>
              <div className=" card-body">
                <p className="card-text mt-5">
                 {pack.description}
                </p>
              </div>
             <div className="mt-auto">
             {!isLoggedIn?  <button onClick={()=>  navigate("/login")} className="form-control blue-bg text-white py-3 rounded-4">
                  Choose
                </button>:
               <button disabled={load} onClick={()=>subscription(pack._id)} className="form-control blue-bg text-white py-3 rounded-4">
                  Choose
                </button>}
              </div>
            </div>
          </div>)}
          {/* <div className="col-12 col-sm-6 col-lg-4">
            <div className="card2 mx-2 d-flex flex-column p-4 border-0 mt-3">
              <div className="blue-bg text-center align-items-center text-white py-4 rounded-4">
                <p className=' text-white'>Annual</p>
                <h3>£290</h3>
              </div>
              <div className=" card-body">
                <p className="card-text mt-5">
                  In publishing and graphic design, Lorem ipsum is a placeholder
                  text commonly used to demonstrate the visual form of a
                </p>
              </div>
              <div className="mt-auto">
                <button className="form-control blue-bg text-white py-3 rounded-4">
                  Choose
                </button>
              </div>
            </div>
          </div> */}
        </div>
      </div>
    </div>
    {/* -----------------
            end || cards 
        ----------------- */}
    {/* -----------------
        start || cards 
    ----------------- */}
    <div className="package my-5">
      <div className="mycontainer">
        <div className="row d-flex justify-content-center align-items-center">
          <div className="col-lg-6  text-center">
            <h2 className=" display-3">
              <span className="blue-clr">All plans include</span>
            </h2>
          </div>
        </div>
      </div>
    </div>
  </>
  )
}

export default Prices
