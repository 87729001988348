import React, { useEffect, useRef, useState } from 'react'
import apiClient from '../api/apiClient'
import { toast } from 'react-toastify'
import { useNavigate,useParams  } from 'react-router-dom'
import { toPng } from 'html-to-image'
import QRCodeStyling from 'qr-code-styling'
import Url from './Qrcode/Url'
import BulkUrl from './Qrcode/BulkUrl'
import Feedback from './Qrcode/Feedback'
import SocialMedia from './Qrcode/SocialMedia'
import Whatsapp from './Qrcode/Whatsapp'
import Email from './Qrcode/Email'
import Pdf from './Qrcode/Pdf'
import Sms from './Qrcode/Sms'
import Text from './Qrcode/Text'
import Vcard from './Qrcode/Vcard'
import Images from './Qrcode/Images'
const qrCode = new QRCodeStyling({
  width: 280,
  height: 280,
  type: 'svg',
  data:"https://example.com",
  
  // image:"https://upload.wikimedia.org/wikipedia/commons/5/51/Facebook_f_logo_%282019%29.svg",
  image:"https://bartztestbucket.s3.us-east-2.amazonaws.com/istockphoto-1477641458-1024x1024.jpg",
  dotsOptions: {
    color: "#000000",
    type: "square"
  },
  backgroundOptions:{
    color:"#ffffff",
  },
  cornersSquareOptions:{
    type: "dot"
  },
  imageOptions: {
    hideBackgroundDots: true,
    margin: 4,
    imageSize: 0.4,
    crossOrigin: "anonymous",
  }
});
const ContentEdit = () => {
  const { id } = useParams();
const navigate=useNavigate()
 const [qrInfo,setQrInfo] =useState()
 const [select,setSelect] =useState()
 const [qrData, setQrData] = useState()
 console.log(select,"sasasa");


  const fetchQr=async()=>{
    const result=await apiClient.get(`/qrcode/${id}`)
    if(!result.ok) return
    setSelect(result.data.result.qr_info.type)
    const { frame_data,qr_data, ...restOfData } = result.data.result;
     setQrInfo(restOfData)
   console.log(result,"ll");
  }
  useEffect(()=>{
    fetchQr()
  },[])


  const handleSubmit = async (data) => {
    const result=await apiClient.put(`/qrcode/${id}`,data)
if(!result.ok) return toast.error("Error")
navigate("/admin/qrode")
  }
 console.log(qrInfo,"asd");
 console.log(qrData,"asssd");
  return (
    <>
    <div className='container-md pb-5  ' >

<div className="row " >
{select == "Url" && <Url qrInfo={qrInfo} setQrData={setQrData} handleSubmit={handleSubmit}/>}
                {select == "BulkUrl" && <BulkUrl qrInfo={qrInfo} handleSubmit={handleSubmit} />}
                {select == "Feedback" && <Feedback setQrData={setQrData} qrInfo={qrInfo} handleSubmit={handleSubmit}/>}
                {select == "SocialMedia" && <SocialMedia setQrData={setQrData}  qrInfo={qrInfo} handleSubmit={handleSubmit}   />}
                {select == "Whatsapp" && <Whatsapp  qrInfo={qrInfo} handleSubmit={handleSubmit}/>}
                {select == "Email" && <Email qrInfo={qrInfo} handleSubmit={handleSubmit} />}
                {select == "Pdf" && <Pdf setQrData={setQrData} qrInfo={qrInfo} handleSubmit={handleSubmit}/>}
                {select == "SMS" && <Sms qrInfo={qrInfo} handleSubmit={handleSubmit}/>}
                {select == "Text" && <Text  qrInfo={qrInfo} handleSubmit={handleSubmit}/>}
                {select == "VCard" && <Vcard  qrInfo={qrInfo} handleSubmit={handleSubmit}/>}
                {select == "Images" && <Images setQrData={setQrData} qrInfo={qrInfo} handleSubmit={handleSubmit} />}
    
        </div>
        </div>

    </>
  )
}

export default ContentEdit



function formatQrData(type, data) {
  switch (type) {
    case "Url":
      return data.url;

    case "Text": {
      const {  text } = data;
      return `${text}`;
    }

    case "WhatsApp": {
      const { rec_phone, text } = data;
      return `https://wa.me/${rec_phone}?text=${encodeURIComponent(text)}`;
    }

    case "SMS": {
      const { rec_phone, text } = data;
      return `sms:${rec_phone}?body=${encodeURIComponent(text)}`;
    }

    case "Email": {
      const { to, subject, text } = data;
      return `mailto:${to}?subject=${encodeURIComponent(subject)}&body=${encodeURIComponent(text)}`;
    }
    

    case "VCard": {
      const vCard = [
        "BEGIN:VCARD",
        "VERSION:3.0",
        `N:${data.last_name};${data.first_name};;;`,
        `FN:${data.first_name} ${data.last_name}`,
        `ORG:${data.company}`,
        `TEL;TYPE=work,voice:${data.phone}`,
        `EMAIL:${data.email}`,
        `ADR;TYPE=work:;;${data.location.street};${data.location.city};${data.location.state};${data.location.zip};${data.location.country}`,
        `URL:${data.website}`,
        "END:VCARD"
      ].join("\r\n");
      
      return vCard;
    }


    default:
      return "";
  }
}
