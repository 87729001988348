import React,{useEffect, useState} from 'react'
import QrModel from '../components/Qrcode/QrModel'
import apiClient from '../api/apiClient';
import { Link } from 'react-router-dom';
import Loader from '../components/Loader';
const Folders = () => {
  const [modalShow, setModalShow] = React.useState(false);
  const [folder, setFolder] = React.useState();
  const [count, setCount] = React.useState();
const [load,setLoad]=useState(false)
  const fetchData=async()=>{
    setLoad(true)
    const result=await apiClient.get("/folder")
    setLoad(false)
    console.log(result);
    setCount(result.data.count)
    setFolder(result.data.results)
  }
  useEffect(() => {

    fetchData()
  }, [])
  console.log(folder,"kkkk");
  function formatDateFromTimestamp(timestamp) {
    const date = new Date(timestamp);
  
    const year = date.getFullYear();
    const month = (date.getMonth() + 1).toString().padStart(2, '0');
    const day = date.getDate().toString().padStart(2, '0');
  
    return `${year}-${month}-${day}`;
  }
  return (
    <>
    <section>
    <div className="container">
      <div className="d-flex align-items-center justify-content-between ">
        <div className="me-sm-3">
          <h2 className="blue-button-cl fw-normal ps-sm-4">Folders</h2>
        </div>
        <div>
          <button
          onClick={()=>setModalShow(true)}
            type="button"
            // data-bs-toggle="modal"
            // data-bs-target="#folderModal"
            className="border-0 text-white px-4 py-2 rounded-3 button-bg mb-0"
          >
            Create New folders
          </button>
        </div>
      </div>
      <div className="shadow bg-white rounded-4 py-3 px-4 mt-3">
        <div className="folder-height ">
          <div className="row pe-2 ">

 




{!load? <>{count?
           folder?.map((item)=>
            <Link to={`/admin/folders/${item._id}`} className='text-decoration-none col-xl-3 col-lg-4 col-sm-6 mt-3'>
          
              <div className="light-blue2 px-3 py-3 rounded-3">
                <div className="d-flex justify-content-between">
                  <div className="d-flex">
                    <div>
                      <img
                        src={require("../assets/folder.png")}
                        alt=""
                        className="folder-img rounded-2"
                      />
                    </div>
                    <div className="ps-2">
                      <p className="button-clr">{item.name}</p>
                    </div>
                  </div>
                  <div>
                    <img
                      src="https://chart.apis.google.com/chart?cht=qr&chs=250x250&chl="
                      alt=""
                      className="img-fold"
                    />
                  </div>
                </div>
                <div className="d-flex align-items-center justify-content-between mt-2 flex-wrap">
                  <div>
                    <p className="font-12 mb-0 grey-clr">
                      Updated: {formatDateFromTimestamp(item.updatedAt)}
                    </p>
                  </div>
                  <div>
                    <p className="font-12 mb-0 grey-clr">
                      Created: {formatDateFromTimestamp(item.createdAt)}
                    </p>
                  </div>
                </div>
              </div>
            </Link>
            ): 
            <div className='m-auto'>

          
            <div className='d-flex justify-content-center aling-items-center' > 
            
                   <div style={{width:"20rem"}}>

                <img src={require("../assets/WhatsApp Image 2023-11-04 at 3.35.38 PM.jpeg")} alt="" />
                   </div>


             

            </div>
           <h3 className='text-center my-4'>Create your first folder</h3>
           <h6 className='text-center'>Structure your codes and campaigns into folders.</h6>


            </div>
          }</>: <div className='d-flex justify-content-center'>
            <Loader/>
            </div>}
            {/* <div className="col-xl-3 col-lg-4 col-sm-6 mt-3">
              <div className="light-blue2 px-3 py-3 rounded-3">
                <div className="d-flex justify-content-between">
                  <div className="d-flex">
                    <div>
                      <img
                        src={require("../assets/folder.png")}
                        alt=""
                        className="folder-img rounded-2"
                      />
                    </div>
                    <div className="ps-2">
                      <p className="button-clr">Folder name here</p>
                    </div>
                  </div>
                  <div>
                    <img
                      src="https://chart.apis.google.com/chart?cht=qr&chs=250x250&chl="
                      alt=""
                      className="img-fold"
                    />
                  </div>
                </div>
                <div className="d-flex align-items-center justify-content-between mt-2 flex-wrap">
                  <div>
                    <p className="font-12 mb-0 grey-clr">
                      Updated: 3 Oct 2023
                    </p>
                  </div>
                  <div>
                    <p className="font-12 mb-0 grey-clr">
                      Created: 3 Oct 2023
                    </p>
                  </div>
                </div>
              </div>
            </div> */}
            {/* <div className="col-xl-3 col-lg-4 col-sm-6 mt-3">
              <div className="light-blue2 px-3 py-3 rounded-3">
                <div className="d-flex justify-content-between">
                  <div className="d-flex">
                    <div>
                      <img
                        src={require("../assets/folder.png")}
                        alt=""
                        className="folder-img rounded-2"
                      />
                    </div>
                    <div className="ps-2">
                      <p className="button-clr">Folder name here</p>
                    </div>
                  </div>
                  <div>
                    <img
                      src="https://chart.apis.google.com/chart?cht=qr&chs=250x250&chl="
                      alt=""
                      className="img-fold"
                    />
                  </div>
                </div>
                <div className="d-flex align-items-center justify-content-between mt-2 flex-wrap">
                  <div>
                    <p className="font-12 mb-0 grey-clr">
                      Updated: 3 Oct 2023
                    </p>
                  </div>
                  <div>
                    <p className="font-12 mb-0 grey-clr">
                      Created: 3 Oct 2023
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-xl-3 col-lg-4 col-sm-6 mt-3">
              <div className="light-blue2 px-3 py-3 rounded-3">
                <div className="d-flex justify-content-between">
                  <div className="d-flex">
                    <div>
                      <img
                        src={require("../assets/folder.png")}
                        alt=""
                        className="folder-img rounded-2"
                      />
                    </div>
                    <div className="ps-2">
                      <p className="button-clr">Folder name here</p>
                    </div>
                  </div>
                  <div>
                    <img
                      src="https://chart.apis.google.com/chart?cht=qr&chs=250x250&chl="
                      alt=""
                      className="img-fold"
                    />
                  </div>
                </div>
                <div className="d-flex align-items-center justify-content-between mt-2 flex-wrap">
                  <div>
                    <p className="font-12 mb-0 grey-clr">
                      Updated: 3 Oct 2023
                    </p>
                  </div>
                  <div>
                    <p className="font-12 mb-0 grey-clr">
                      Created: 3 Oct 2023
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-xl-3 col-lg-4 col-sm-6 mt-3">
              <div className="light-blue2 px-3 py-3 rounded-3">
                <div className="d-flex justify-content-between">
                  <div className="d-flex">
                    <div>
                      <img
                        src={require("../assets/folder.png")}
                        alt=""
                        className="folder-img rounded-2"
                      />
                    </div>
                    <div className="ps-2">
                      <p className="button-clr">Folder name here</p>
                    </div>
                  </div>
                  <div>
                    <img
                      src="https://chart.apis.google.com/chart?cht=qr&chs=250x250&chl="
                      alt=""
                      className="img-fold"
                    />
                  </div>
                </div>
                <div className="d-flex align-items-center justify-content-between mt-2 flex-wrap">
                  <div>
                    <p className="font-12 mb-0 grey-clr">
                      Updated: 3 Oct 2023
                    </p>
                  </div>
                  <div>
                    <p className="font-12 mb-0 grey-clr">
                      Created: 3 Oct 2023
                    </p>
                  </div>
                </div>
              </div>
            </div> */}
          </div>
        </div>
      </div>
    </div>
  </section>
  {/* ========= Modal ======= */}
  {/* <div className="modal fade" id="folderModal">
    <div className="modal-dialog modal-dialog-centered">
      <div className="modal-content rounded-4">
        <div className="modal-header bg-modal rounded-modal">
          <div className="d-flex align-items-center">
            <img src={require("../assets/folder-2.png")} alt="" className="img-folder2" />
            <h4
              className="modal-title fs-6 blue-button-cl fw-normal ps-2"
              id="exampleModalLabel"
            >
              Create Folder
            </h4>
          </div>
        </div>
        <div className="modal-body px-sm-5">
          <form action="">
            <div className="mb-5">
              <label htmlFor="" className="blue-button-cl form-label">
                Folder name
              </label>
              <input
                type="text"
                className="form-control shadow-none light-blue2 border-0 folder-place"
                placeholder="Folder name here"
              />
            </div>
            <div className="mt-4 d-flex align-items-center">
              <div className="w-100 me-3">
                <button
                  type="button"
                  className="bl-bg w-100 border-0 py-2 px-sm-5 rounded-3 button-clr"
                  data-bs-dismiss="modal"
                >
                  Cancel
                </button>
              </div>
              <div className="w-100">
                <button
                  type="button"
                  className="button-bg w-100 border-0 py-2 px-sm-5 rounded-3 text-white"
                >
                  Create
                </button>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div> */}

{modalShow&&  <QrModel  show={modalShow}
      //  handleChange={handleChange}
      //  value={url}
      fetchData={ fetchData}
        onHide={() => setModalShow(false)}  />}





  </>
  )
}

export default Folders