import React from 'react'
import { useState } from 'react'
import QrModel from './QrModel'
import { useEffect } from 'react'
import apiClient from '../../api/apiClient'
import { toast } from 'react-toastify'

const Text = ({qrInfo,step,setStep,setQrData,...props}) => {
let initialState={
  "text":qrInfo?.qr_info.info.text||"",
  "name":qrInfo?.name || "",
  "folder":qrInfo?.folder ||"",
  "qr_info":{"type":"Text"}

}
const [texts,setTexts]=useState(initialState)
const [folder,setFolder]=useState()
const [modalShow, setModalShow] = React.useState(false);
const handleChange=(key,value)=>{
  setTexts({...texts,[key]:value})
}
const fetchData=async()=>{
  const result =await apiClient.get("/folder")
setFolder(result.data.results)
}
const handleSubmit=async(e)=>{
  e.preventDefault()
  console.log(texts);
  texts.qr_info.info={text:texts.text}
 
}

useEffect(() => {
  fetchData()
 }, [])


 const [errors, setErrors] = useState({});
const validateForm = () => {
  const newErrors = {};
  if (!texts.text) {
    newErrors.text = 'text is required';
  }
  if (!texts.name) {
    newErrors.name = 'Name is required';
  }
  if (!texts.folder) {
    newErrors.folder = 'Folder is required';
  }
  setErrors(newErrors);

  
  return Object.keys(newErrors).length === 0;
};

 const nextStep=()=>{
  const formIsValid = validateForm();
  if (formIsValid) {
  texts.qr_info.info={text:texts.text}
  const {folder,type,name,qr_info}=texts
  if(props?.handleSubmit)
  return props?.handleSubmit({folder,type,name,qr_info})
  setQrData({folder,type,name,qr_info})
  step &&setStep(step+1)}}


  return (


    <div className="container">
      <div className="row">
        <div className="col-11">
        <form onSubmit={handleSubmit} id="myForm">
    <div>
      <h5 className="fw-normal">Add Content</h5>
      <span className="font-12">Text (upto 300 characters)*</span>
      {/* <textarea name="" id="" 
                                                  class="form-control shadow-none light-grey-bg border-0"
                                                  placeholder="Enter your text here"></textarea> */}
    </div>
    <div className="input-wrap">
      <textarea
        id="data"
        value={texts.text}
        onChange={(e)=>handleChange("text",e.target.value)}
        className="input-1x form-control shadow-none light-grey-bg border-0"
        placeholder="Enter your text here"
        cols={30}
        rows={4}
        defaultValue={"video"}
      />
       <span  className="text-danger font-12">
         {errors.text}
       </span>
    </div>
  
    <h5 className="mt-3">Internal notes</h5>
    <div>
      <label htmlFor="for-name" className="form-label font-13 ps-2 mb-0">
        Name your code
      </label>
      <input
        type="text"
        name=""
        value={texts.name}
        onChange={(e)=>handleChange("name",e.target.value)}
        id="for-name"
        className="form-control shadow-none light-grey-bg border-0"
        placeholder="Enter name"
      />
       <span  className="text-danger font-12">
         {errors.name}
       </span>
    </div>
    <div className="mt-3">
              
              <div className="dropdown">
      <label htmlFor="" className="form-label font-13 mb-0 ps-2">
        Folder
      </label>
       
      </div>
              <div className="dropdown" style={{cursor:"pointer"}}>
                <button
                  className="form-select shadow-none light-grey-bg border-0 text-start grey-clr"
                  type="button"
                  data-bs-toggle="dropdown"
                  aria-expanded="false"
                >
                  {!texts?.folder?"Folder": folder?.find(x=>x._id===texts.folder).name}
                </button>
                <ul className="dropdown-menu w-100 p-3">
                  <li onClick={() => setModalShow(true)}
                    className="blue-button-cl pointer"
                    style={{borderBottom:"1px solid black"}}
                    // data-bs-toggle="modal"
                    // data-bs-target="#folderModal"
                  >
                    <i className="bi bi-plus blue-button-cl fs-5" /> Add
                    new Folder
                  </li>
                  {folder?.map((item)=>
                   <li style={{}} onClick={()=>setTexts({...texts,folder:item._id})} >{item.name}</li>
                   )}
                </ul>
                <span  className="text-danger font-12">
         {errors.folder}
       </span>


              </div>
            </div>
            {step&&
      <div className="d-flex align-items-center mt-3">
              <div>
                <button onClick={() => setStep(step - 1)} className="light-blue2 px-5 py-2 blue-button-cl border-0 rounded-3">
                  Back
                </button>
              </div>
              <div className="ms-3">
                <button onClick={nextStep} className="px-5 py-2 text-white blue-button-bg border-0 rounded-3">
                  Next
                </button>
              </div>
            </div>}
      {qrInfo&&
      <div className="d-flex align-items-center mt-3">
             
              <div className="ms-3">
                <button onClick={nextStep} className="px-5 py-2 text-white blue-button-bg border-0 rounded-3">
                 Save
                </button>
              </div>
            </div>}
  </form>

  {modalShow&&  <QrModel  show={modalShow}
       handleChange={handleChange}
       value={texts}
       fetchData={fetchData}
        onHide={() => setModalShow(false)}  />}
        </div>
      </div>
    </div>

  )
}

export default Text
