import React, { useState } from 'react'
import { useEffect } from 'react'
import apiClient from '../../api/apiClient'
import QrModel from './QrModel'
import { toast } from 'react-toastify'

const Pdf = ({qrInfo,step,setStep,setQrData,...props}) => {
  console.log(qrInfo,"azsx");
  let initialState={
    "company":qrInfo?.qr_info?.info?.company||"",
    "headline":qrInfo?.qr_info?.info?.headline||"",
    "description":qrInfo?.qr_info?.info?.description||"",
    "website":qrInfo?.qr_info?.info?.website||"",
    "file":qrInfo?.qr_info?.info?.file||"",
    "backgroundCol":qrInfo?.qr_info?.info?.backgroud_color||"",
    "TextCol":qrInfo?.qr_info?.info?.text_color||"",
    "buttonCol":qrInfo?.qr_info?.info?.button_color||"",
    "name":qrInfo?.name||"",
    qr_info: { type: "Pdf" },
    "folder":qrInfo?.folder||""
  }

const [pdf,setPdf]=useState(initialState)

const handleChange=async(key,value)=>{
  if (key == "file") {
    let formdata = new FormData();
    formdata.append("file", value);
    const result = await apiClient.post("/upload-file", formdata);
    setPdf({ ...pdf, [key]: result.data.result });
    console.log({ ...pdf, [key]: result.data.result },"kkkkkk");
    return;
  }
  setPdf({...pdf,[key]:value})
}

const handleSubmit=async(e)=>{
  e.preventDefault()
  console.log(pdf);
  pdf.qr_info.info = {
    headline: pdf.headline,
    company: pdf.company,
    description: pdf.description,
    website: pdf.website,
    button_color: pdf.buttonCol,
    text_color: pdf.TextCol,
    backgroud_color: pdf.backgroundCol,
    file: pdf.file,
  };
//   const result=await apiClient.post("/qrcode",pdf)
// if(!result.ok) return toast.error("Error")
//   console.log(result);
}

const [modalShow, setModalShow] = React.useState(false);
const [folder,setFolder]=useState()
const fetchData=async()=>{
  const result =await apiClient.get("/folder")
setFolder(result.data.results)
}
useEffect(() => {
  fetchData()
 }, [])

 const [errors, setErrors] = useState({});
 const validateForm = () => {
   const newErrors = {};
   if (!pdf.company) {
     newErrors.company = 'company is required';
   }
   if (!pdf.headline) {
     newErrors.headline = 'headline is required';
   }
   if (!pdf.description) {
     newErrors.description = 'description is required';
   }
   if (!pdf.website) {
     newErrors.website = 'website is required';
   }
   if (!pdf.file) {
     newErrors.file = 'file is required';
   }
   if (!pdf.backgroundCol) {
     newErrors.backgroundCol = 'backgroundCol is required';
   }
   if (!pdf.TextCol) {
     newErrors.TextCol = 'TextCol is required';
   }
   if (!pdf.buttonCol) {
     newErrors.buttonCol = 'buttonCol is required';
   }
   if (!pdf.name) {
     newErrors.name = 'name is required';
   }
   if (!pdf.qr_info) {
     newErrors.qr_info = 'qr_info is required';
   }
   if (!pdf.folder) {
     newErrors.folder = 'folder is required';
   }
   setErrors(newErrors);
 
   
   return Object.keys(newErrors).length === 0;
 };
useEffect(()=>{ pdf.qr_info.info = {
  headline: pdf.headline,
  company: pdf.company,
  description: pdf.description,
  website: pdf.website,
  button_color: pdf.buttonCol,
  text_color: pdf.TextCol,
  backgroud_color: pdf.backgroundCol,
  file: pdf.file,
};
  const {folder,type,name,qr_info}=pdf
  setQrData({folder,type,name,qr_info})},[pdf])
 const nextStep=()=>{
  const formIsValid = validateForm();
  if (formIsValid) {
    const {folder,type,name,qr_info}=pdf
  console.log("object");
  if(props?.handleSubmit)
  return props?.handleSubmit({folder,type,name,qr_info})
  setStep(step+1)}
}

  return (


    <div className="container">
      <div className="row">
        <div className="col-11">
        <form onSubmit={handleSubmit} id="myForm">
    <div>
      <div className="row">
        <h5>Basic Information</h5>
        <div className="col-12 mb-2">
          <label htmlFor="" className="form-label font-13 mb-0 ps-2">
            Company
          </label>
          <input
            type="text"
            name=""
            value={pdf.company}
            onChange={(e)=>handleChange("company",e.target.value)}
            id=""
            placeholder="Company name"
            className="light-grey-bg border-0 form-control shadow-none"
          />
          <span  className="text-danger font-12">
         {errors.company}
       </span>
        </div>
        <div className="col-12 mb-2">
          <label htmlFor="" className="form-label font-13 mb-0 ps-2">
            Headline
          </label>
          <input
            type="text"
            value={pdf.headline}
            onChange={(e)=>handleChange("headline",e.target.value)}
            name=""
            id=""
            placeholder="Enter name"
            className="light-grey-bg border-0 form-control shadow-none"
          />
          <span  className="text-danger font-12">
         {errors.headline}
       </span>
        </div>
        <div className="col-12 mb-2">
          <label htmlFor="" className="form-label font-13 mb-0 ps-2">
            Description
          </label>
          <input
            type="text"
            name=""
            value={pdf.description}
            onChange={(e)=>handleChange("description",e.target.value)}
            id=""
            placeholder="More information about your gallery"
            className="light-grey-bg border-0 form-control shadow-none "
          />
          <span  className="text-danger font-12">
         {errors.description}
       </span>
        </div>
        <div className="col-12 mb-2">
          <label htmlFor="" className="form-label font-13 mb-0 ps-2">
            Website*
          </label>
          <input
            type="text"
            value={pdf.website}
            onChange={(e)=>handleChange("website",e.target.value)}
            className="form-control light-grey-bg shadow-none border-0"
            placeholder="https:\\websiteyour.com"
          />
          <span  className="text-danger font-12">
         {errors.website}
       </span>
        </div>
      </div>
    </div>
    <div className="mt-3">
      <label
        htmlFor="upload-image"
        className="border-dots d-flex justify-content-between align-items-center flex-wrap"
      >
        <div>
          <p className="grey-clr">Drag and Drop images here</p>
        </div>
        <div className="d-flex flex-column align-items-center">
          <img src={require("../../assets/plus.png")} alt="" className="plus-img" />
          {/* <button
            type="button"
            className="light-grey-bg border-0 rounded-3 py-2 px-3 mt-2 blue-button-cl"
          >
            Upload image
          </button> */}
        </div>
      </label>
      <input 
            onChange={(e)=>handleChange("file",e.target.files[0])} type="file" className="d-none" id="upload-image" />
    <span  className="text-danger font-12">
         {errors.file}
       </span>
    </div>
    <div className="row mt-4">
      <h5>Color palette</h5>
      <div className="col-sm-6">
        <label htmlFor="" className="font-13 form-label mb-0 w-100">
          Background
        </label>
        <div className="border-0 light-grey-bg rounded-3 d-flex align-items-center justify-content-between py-2 px-2">
          <p className="mb-0">{pdf.backgroundCol||"#3278E4"}</p>
          <input
            type="color"
            value={pdf.backgroundCol}
            onChange={(e)=>handleChange("backgroundCol",e.target.value)}
            className="shadow-none border-0"
            defaultValue="#3278E4"
          />
          <span  className="text-danger font-12">
         {errors.backgroundCol}
       </span>
        </div>
      </div>
      <div className="col-sm-6 mt-sm-0 mt-3">
        <label htmlFor="" className="font-13 form-label mb-0 w-100">
          Text
        </label>
        <div className="border-0 light-grey-bg justify-content-between rounded-3 d-flex align-items-center py-2 px-2">
          <p className="mb-0">{pdf.TextCol||"#ffff"}</p>
          <input
            type="color"
            value={pdf.TextCol}
            onChange={(e)=>handleChange("TextCol",e.target.value)}
            className="shadow-none border-0"
            defaultValue="#ffffff"
          />
          <span  className="text-danger font-12">
         {errors.TextCol
         }
       </span>
        </div>
      </div>
      <div className="col-sm-6 mt-sm-0 mt-3">
        <label htmlFor="" className="font-13 form-label mb-0 w-100">
          Button
        </label>
        <div className="border-0 light-grey-bg justify-content-between rounded-3 d-flex align-items-center py-2 px-2">
          <p className="mb-0">{pdf.TextCol||"#5389FF"}</p>
          <input
            type="color"
            value={pdf.buttonCol}
            onChange={(e)=>handleChange("buttonCol",e.target.value)}
            className="shadow-none border-0"
            defaultValue="#5389FF"
          />
          <span  className="text-danger font-12">
         {errors.buttonCol}
       </span>
        </div>
      </div>
    </div>
    <h5 className="my-3">Welcome Screen</h5>
    <p>Welcome</p>
    <h5 className="my-3">Internal Notes</h5>
    <div className="mb-2">
      <label htmlFor="" className="form-label font-13 mb-0 ps-2">
        Name your code*
      </label>
      <input
        type="text"
        name=""
        value={pdf.name}
            onChange={(e)=>handleChange("name",e.target.value)}
        id=""
        placeholder="Enter name"
        className="light-grey-bg border-0 form-control "
      />
      <span  className="text-danger font-12">
         {errors.name}
       </span>
    </div>
    <div className="mt-3">
              
              <div className="dropdown">
      <label htmlFor="" className="form-label font-13 mb-0 ps-2">
        Folder
      </label>
       
      </div>
              <div className="dropdown">
                <button
                  className="form-select shadow-none light-grey-bg border-0 text-start grey-clr"
                  type="button"
                  data-bs-toggle="dropdown"
                  aria-expanded="false"
                >
                  {!pdf?.folder?"Folder": folder?.find(x=>x._id===pdf.folder).name}
                </button>
                <ul className="dropdown-menu w-100 p-3">
                  <li onClick={() => setModalShow(true)}
                    className="blue-button-cl pointer"
                    style={{borderBottom:"1px solid black"}}
                    // data-bs-toggle="modal"
                    // data-bs-target="#folderModal"
                  >
                    <i className="bi bi-plus blue-button-cl fs-5" /> Add
                    new Folder
                  </li>
                  {folder?.map((item)=>
                   <li style={{cursor:"pointer"}} onClick={()=>setPdf({...pdf,folder:item._id})} >{item.name}</li>
                   )}
                </ul>
                     


              </div>
            </div>
            {step&&
      <div className="d-flex align-items-center mt-3">
              <div>
                <button onClick={() => setStep(step - 1)} className="light-blue2 px-5 py-2 blue-button-cl border-0 rounded-3">
                  Back
                </button>
              </div>
              <div className="ms-3">
                <button onClick={nextStep} className="px-5 py-2 text-white blue-button-bg border-0 rounded-3">
                  Next
                </button>
              </div>
            </div>}
      {qrInfo&&
      <div className="d-flex align-items-center mt-3">
             
              <div className="ms-3">
                <button onClick={nextStep} className="px-5 py-2 text-white blue-button-bg border-0 rounded-3">
                 Save
                </button>
              </div>
            </div>}
  </form>
  {modalShow && (
            <QrModel
              show={modalShow}
              fetchData={fetchData}
              handleChange={handleChange}
              onHide={() => setModalShow(false)}
            />
          )}
        </div>
      </div>
    </div>

  )
}

export default Pdf
