import React ,{useState} from 'react'
import { Link } from 'react-router-dom'
import {toast } from 'react-toastify';
import { useNavigate } from "react-router-dom";
import apiClient from '../api/apiClient';
import { useDispatch } from 'react-redux';
import { login } from '../redux/authSlice';
import Loader from '../components/Loader';
const Login = () => {
  const [loading, setloading]=useState(false)
  let initialState={
    "email":"",
    "password":""
  }
  const navigate = useNavigate();
  const dispatch=useDispatch()
  const [loginInfo,setlogin]=useState(initialState)
  const handleChange=(key,value)=>{
    setlogin({...loginInfo,[key]:value})
  }
  const handleSubmit=async(e)=>{
    e.preventDefault()
    setloading(true)
    const result=await apiClient.post("/auth/login",loginInfo)
    setloading(false)
    if(!result.ok) return toast.error("Error");
dispatch(login({token:result.data.token}))
    navigate("/admin/dashboard")
  }
  return (
    <div className="container-fluid">
      
    <section className="p-sm-4 py-3  px-md-4 px-2 d-flex flex-column">
      <div className="row main-h   mx-xl-5 mx-lg-4 mx-1 b-radius align-items-center">
        <div className="col-6 bg-right  p-5 px-lg-5 px-md-4 px-3 b-radius-left col-1-res">
          <div className="row d-flex align-items-end h-50">
            <div>
              <h6 className=" pt-2 fs-4 clr-btn fw-bold">
                Description Heading
              </h6>
              <p className="mb-0">
                Lorem ipsum dolor sit amet consectetur adipisicing elit.
                Dignissimos sint neque unde reiciendis nesciunt blanditiis,
                voluptatibus nobis minus voluptates distinctio aperiam fugiat
                iusto delectus ipsam.
              </p>
            </div>
          </div>
          <div className="row px-xl-5 px-lg-4 px-md-3 py-3 mt-2 d-flex align-items-end h-50 ">
            <img
              className="img-one object-contain"
              src={require("../assets/home-pic.png")}
              alt=""
            />
          </div>
        </div>
        <div className="col-6 mt-sm-0 mt-2 py-3 pt-5 px-lg-5 px-md-4 px-3 d-flex flex-column b-radius-right col-2-res">
          <div className="row">
            <div className="d-flex align-items-end h-60">
              <div className=" text-center">
                <p className="blue-m fs-3 fw-md mb-0">Logo here</p>
                <h6 className=" fs-2">Welcome to Our System</h6>
                <p className="px-xl-4 px-lg-2">
                  Lorem ipsum dolor sit amet consectetur adipisicing elit.
                  Quaerat deleniti, expedita maiores fugit eos ad!
                </p>
              </div>
            </div>
            <div className="col-xl-8 col-lg-10 col-md-11 col-11 mx-auto mt-4">
              <form onSubmit={handleSubmit}>
                <input
                required
                  type="email"
                  value={loginInfo.email}
                  onChange={(e)=>handleChange("email",e.target.value)}
                  className="form-control border-0 txt p-2 px-4 p-clr"
                  placeholder="Email"
                />
                <input
                  type="password"
                  required
                  value={loginInfo.password}
                  onChange={(e)=>handleChange("password",e.target.value)}
                  className="form-control mt-3 border-0 txt p-2 px-4 p-clr"
                  placeholder="Password"
                />
                <div className="row mt-3 log-res">
                  <div className="col my-auto rem-res pe-0">
                    <input role="button" type="checkbox" id="remember-me" />
                    <label
                      className="p-clr ms-1"
                      role="button"
                      htmlFor="remember-me"
                    >
                      Remember Me
                    </label>
                  </div>
                  {!loading?
                  <div className="col text-end log-btn">
                    <button
                    
                      type="submit"
                      className="btn ps-0  rounded-pill active-btn"
                    >
                      <span className="btn-inner rounded-pill">Log In</span>
                    </button>
                    {/* <a href="index.html" class="btn ps-0  rounded-pill active-btn" ><span class="btn-inner rounded-pill">Log In</span></a> */}
                  </div>: <div className='d-flex justify-content-center loader-1'>
      <Loader />
    </div>}
                </div>
              </form>
            </div>
          </div>
          <div className="text-center mt-auto">
            <p className="mb-0 text-dark">
              Don't Have An Account?
              <Link to="/signup" className=" text-decoration-none ">
                Register
              </Link>
              <Link
                to="/resetpassword"
                className="d-block p-clr text-decoration-none mt-2"
              >
                Change Password ||
              </Link>
            </p>
          </div>
        </div>
      </div>
    </section>
  </div>
  )
}

export default Login
