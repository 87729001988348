import React from "react";
import { useState ,useEffect} from "react";
import QrModel from "./QrModel";
import apiClient from "../../api/apiClient";
import { toast } from "react-toastify";
const BulkUrl = ({qrInfo,step,setStep,setQrData,...props}) => {
  let initialState = {
    file:qrInfo?.qr_info.info.file|| "",
    types:qrInfo?.qr_info.info.type || "",
    CSV:qrInfo?.qr_info.info.csv|| "",
    url:[],
    "name":qrInfo?.name || "",
  "folder":qrInfo?.folder ||"",
    qr_info: { type:"BulkUrl"},
  };

  const [bulk, setBulk] = useState(initialState);
  const [modalShow, setModalShow] = React.useState(false);
  const handleChange = async(key, value) => {
    if(key=="file"){
    
      const file =value;
      const reader = new FileReader();
  
  reader.onload = function (e) {
    const csvContent = e.target.result;
    const lines = csvContent.split('\n');

    const csvData = [];
    lines.forEach(line => {
      const values = line.split(',');
      const url = values[0];
      const name = values[1];
      if(url&&url!=="URL")
      csvData.push({  url,name });
    });
    setBulk({ ...bulk, url: csvData });
    console.log(csvData);
  };

  reader.readAsText(file);
   
    return 
    }else
    setBulk({ ...bulk, [key]: value });
  };
  const handleSubmit=async(e)=>{
    e.preventDefault()
    // console.log(bulk);
    // bulk.qr_info.info = {
    //   file: bulk.file,
    //   csv: bulk.CSV,
    //   type: bulk.types,
    // };
    // const result = await apiClient.post("/qrcode", bulk);
    // if (!result.ok) return toast.error("Error");
    // console.log(result);
  }
  const [folder, setFolder] = useState();
  const fetchData = async () => {
    const result = await apiClient.get("/folder");
    setFolder(result.data.results);
  };
  useEffect(() => {
    fetchData();
  }, []);

  const [errors, setErrors] = useState({});
const validateForm = () => {
  const newErrors = {};
  if (!bulk.url) {
    newErrors.file = 'file is required';
  }
  if (!bulk.types) {
    newErrors.types = 'types is required';
  }

  if (!bulk.name) {
    newErrors.name = 'name is required';
  }
  if (!bulk.folder) {
    newErrors.folder = 'folder is required';
  }
  if (!bulk.qr_info) {
    newErrors.qr_info = 'qr_info is required';
  }
  setErrors(newErrors);

  
  return Object.keys(newErrors).length === 0;
};
  const nextStep=()=>{
    const formIsValid = validateForm();
  if (formIsValid) {
 
    bulk.qr_info.info = {
      url: bulk.url,
      type: bulk.types,
    };
      const {folder,types,name,qr_info}=bulk
      console.log(bulk,qr_info,"bulkk");
      if(props?.handleSubmit)
      return props?.handleSubmit({folder,name,qr_info})
      setQrData({folder,types,name,qr_info})
    setStep(step+1)}
  
}
  console.log(bulk,"loi");
  return (
    <div className="container">
      <div className="row">
        <div className="col-11">
          <form onSubmit={handleSubmit} id="myForm">
            <div>
              <h5 className="fw-normal d-flex flex-wrap mb-2">
                Upload Bulk QR Codes{" "}
                <span className="blue-button-cl ms-1">file example</span>
              </h5>
              <div className="mb-3">
                <div className="mb-3 d-sm-flex align-items-center">
                  <label
                    htmlFor="file-upload"
                    className="custom-file-upload blue-button-bg text-white px-5 rounded-3 py-2"
                  >
                    Upload
                  </label>
                  <div id="file-name" className="ms-2 grey-clr" />
                  <input type="file" onChange={(e)=>handleChange("file",e.target.files[0])} id="file-upload" className="d-none" />
                </div>
                <span  className="text-danger font-12">
         {errors.file}
       </span>
              </div>
              <div className="mb-3">
                <label htmlFor="" className="form-label font-13 mb-0 ps-2">
                  Type
                </label>
                <select
                  name=""
                  id=""
                  value={bulk.types}
                  onChange={(e)=>handleChange("types",e.target.value)}
                  className="form-select shadow-none light-grey-bg border-0 "
                >
                  <option value="GD">Generate dynamic codes from file</option>
                  <option value="GS">Generate Static codes from file</option>
                  <option value="GB">Generate bulk codes, redeemable once</option>
                </select>
                <span  className="text-danger font-12">
         {errors.types}
       </span>
              </div>
              <div className="mb-3">
                <label htmlFor="" className="form-label font-13 mb-0 ps-2">
                  Choose CSV-delimiter
                </label>
                <select
                  name=""
                  value={bulk.CSV}
                  onChange={(e)=>handleChange("CSV",e.target.value)}
                  id=""
                  className="form-select shadow-none light-grey-bg border-0 "
                >
                  <option value="C">Comma</option>
                  <option value="S">Semicolon</option>
                </select>
              </div>
              <span  className="text-danger font-12">
         {errors.CSV}
       </span>
            </div>
            <div>
              <label htmlFor="for-name" className="form-label font-13 ps-2 mb-0">
                Name your code
              </label>
              <input
                type="text"
                name=""
                value={bulk.name}
                onChange={(e)=>handleChange("name",e.target.value)}
                id="for-name"
                className="form-control shadow-none light-grey-bg border-0"
                placeholder="Enter name"
              />
               <span  className="text-danger font-12">
         {errors.name}
       </span>
            </div>
            <div className="mt-3">
              
              <div className="dropdown">
      <label htmlFor="" className="form-label font-13 mb-0 ps-2">
        Folder
      </label>
       
      </div>
              <div className="dropdown">
                <button
                  className="form-select shadow-none light-grey-bg border-0 text-start grey-clr"
                  type="button"
                  data-bs-toggle="dropdown"
                  aria-expanded="false"
                >
                  {!bulk?.folder?"Folder": folder?.find(x=>x._id===bulk.folder).name}
                </button>
                <ul className="dropdown-menu w-100 p-3">
                  <li onClick={() => setModalShow(true)}
                    className="blue-button-cl pointer"
                    style={{borderBottom:"1px solid black"}}
                    // data-bs-toggle="modal"
                    // data-bs-target="#folderModal"
                  >
                    <i className="bi bi-plus blue-button-cl fs-5" /> Add
                    new Folder
                  </li>
                  {folder?.map((item)=>
                   <li style={{cursor:"pointer"}} onClick={()=>setBulk({...bulk,folder:item._id})} >{item.name}</li>
                   )}
                </ul>
                     


              </div>
              <span  className="text-danger font-12">
         {errors.folder}
       </span>
            </div>
            {step&&
      <div className="d-flex align-items-center mt-3">
              <div>
                <button onClick={() => setStep(step - 1)} className="light-blue2 px-5 py-2 blue-button-cl border-0 rounded-3">
                  Back
                </button>
              </div>
              <div className="ms-3">
                <button onClick={nextStep} className="px-5 py-2 text-white blue-button-bg border-0 rounded-3">
                  Next
                </button>
              </div>
            </div>}
      {qrInfo&&
      <div className="d-flex align-items-center mt-3">
             
              <div className="ms-3">
                <button onClick={nextStep} className="px-5 py-2 text-white blue-button-bg border-0 rounded-3">
                 Save
                </button>
              </div>
            </div>}
          </form>
          {modalShow&&  <QrModel  show={modalShow}
       handleChange={handleChange}
       value={bulk}
       fetchData={fetchData}
        onHide={() => setModalShow(false)}  />}
        </div>
      </div>
    </div>
  );
};

export default BulkUrl;
