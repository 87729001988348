import React from 'react'
const SocialMediaScan = ({ qrData }) => {
    console.log(qrData, "object");
    const data = [
        {
            icon: <img className='object-fit-contain' src={require("../assets/facebook.png")} alt="" />,
            url: "https://Facebook.com",
            text: "Facebook"
        },
        {
            icon: <img className='object-fit-contain' src={require("../assets/twitter.png")} alt="" />,
            url: "https://Twitter.com",
            text: "Twitter"
        },
        {
            // icon: <img className='object-fit-contain' src={require("../assets/insta.png")} alt="" />,
            url: "https://Instagram.com",
            text: "Instagram"
        },

    ]
    return (
        <div className="smartphone">
            <div className='smartphone-inner'>
                <div className=' sec-h d-flex flex-column container text-white p-0'  >
                    <div className='p-2' style={{ backgroundColor: `${qrData?.qr_info?.info?.color_palette.background || "#3278E4"}`, color: `${qrData?.qr_info?.info?.color_palette.text || "white"}` }}>
                        <div className="ms-2 h2 fw-semibold"> {qrData?.qr_info?.info?.name || "Name"} </div>
                        <div className="ms-2 fs-5 ">{qrData?.qr_info?.info?.description || "Description"} </div>
                    </div>
                    <div className='mt-4  text-dark w-100'>
                        {
                            qrData?.qr_info?.info?.social_links?.map((e) => {
                                return (
                                    <div className='row mx-0 mt-2 p-2 '>
                                        <div className="col-2" style={{ maxHeight: "60px" }}>
                                        <img src={require(`./../assets/${e.type}.png`)} alt="" className="social-links" />
                                        </div>
                                        <div className="col-10">https://{e.type}.com <br /> {e.value}</div>
                                    </div>)
                            })
                        }
                    </div>

                </div>

            </div>
        </div>
    )
}

export default SocialMediaScan
