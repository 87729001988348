import React,{useState,useEffect} from 'react'
import { Link, useParams,useNavigate } from 'react-router-dom'
import apiClient from '../api/apiClient'
import { downloadFile } from '../modules/dowloadHelpers'
import useApi from '../hooks/useApi'
import MoreVertIcon from '@mui/icons-material/MoreVert';
import Popover from '@mui/material/Popover';
import ArchiveIcon from '@mui/icons-material/Archive';
import EditIcon from '@mui/icons-material/Edit';
import QrPopup from '../components/FieldEditDropdown'
import Loader from '../components/Loader'
import PropTypes from 'prop-types';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';

const Qrcode = () => {
  const [value, setValue] = React.useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const [load, setload]=useState(false)
  const navigate=useNavigate()
  const[qr, setQr]=useState()
  const [type,setType]=useState()
const {id}=useParams()
const fetchQr=async()=>{
  setload(true)
  const result=await apiClient.get(`/qrcode?archive=false&type=${type||""}&folder=${id||""}`)
  if(!result.ok) return 
  setload(false)
  setQr(result.data.results)
  console.log(result,"kl");
}
const {request,loading,data}=useApi(()=>apiClient.get(`/folder/${id||""}`))
const fetchFolderData=async()=>{
  setload(true)
  const result=await request()
  setload(false)
  if(!result.ok) return 
}
const handleArchive=async(id)=>{
  const result=await apiClient.put(`/qrcode/${id}`,{archive:true})
  if(!result.ok) return 
  fetchQr()
}
useEffect(()=>{
  fetchQr()
  if(id)
  fetchFolderData()
},[type])
console.log(qr);

function formatDateFromTimestamp(timestamp) {
  const date = new Date(timestamp);

  const year = date.getFullYear();
  const month = (date.getMonth() + 1).toString().padStart(2, '0');
  const day = date.getDate().toString().padStart(2, '0');

  return `${year}-${month}-${day}`;
}
const [anchorEl, setAnchorEl] = React.useState(null);

const handleClick = (event) => {
  setAnchorEl(event.currentTarget);
};

const handleClose = () => {
  setAnchorEl(null);
};

const open = Boolean(anchorEl);
// const id = open ? 'simple-popover' : undefined;

  return (
    
      <section>
        
      <div className="container">
    {id&&    <div>
          <h3 className='fw-normal ps-sm-4'>Folder:{data?.name}</h3>
        </div>}
        <div className="d-flex align-items-center justify-content-between ">
          <div className="me-sm-3">
            <h2 className="blue-button-cl fw-normal ps-sm-4">QR Codes</h2>
          </div>
          <div>
              <Link
              style={{textDecoration:"none"}}
              to="/admin/createqr"
                type="button"
                className="border-0 text-white px-4 py-2 rounded-3 button-bg mb-0"
              >
                Create QR Codes
              </Link>
          </div>
        </div>
        <Box className=" shadow bg-white rounded-4 py-3 px-4 mt-3 " sx={{ width: '100%' }}>
      <Box >
        <Tabs value={value} onChange={handleChange} aria-label="basic tabs example">
          <Tab sx={{ borderBottom: 3, borderColor: 'divider' }} className='mx-2' onClick={()=>setType("")} label="All" {...a11yProps(0)} />
          <Tab sx={{ borderBottom: 3, borderColor: 'divider' }}   className='mx-2' onClick={()=>{setType("Static")
        setload(true)}} label="Static" {...a11yProps(1)} />
          <Tab sx={{ borderBottom: 3, borderColor: 'divider' }}  className='mx-2' onClick={()=>{setType("Dynamic")
        setload(true)}} label="Dynamic" {...a11yProps(2)} />
        </Tabs>
      </Box>
      <CustomTabPanel value={value} index={0}>
  
              <div className="  ">
               {!load? <div className=" row folder-height2 pe-lg-5">
                  {qr?.map((item)=>
                  <div className=" col-lg-6 mb-3 " >
                    <div className="d-sm-flex align-items-end  justify-content-between light-blue2 rounded-4 p-3" style={{height:"250px"}}>
                      <div className="w-100  pe-3">
                        <div className=" d-flex border-bottom border-primary w-100">
                          {/* <div className="mt-2">
                            <img
                              src={require("../assets/profile-img.png")}
                              alt=""
                              className="member-img"
                            />
                          </div> */}
                          <div className="ps-2 d-flex w-100 justify-content-between">
                            <p className=" blue-button-cl">{item.name}</p>
                            <QrPopup 
                         onEdit={()=>navigate(`/admin/qrode/modifyContent/${item._id}`)}
                         handleArchive={()=>handleArchive(item._id)}
                         
                         />
                            {/* <p>Member@gmail.com</p> */}
                          </div>
                        </div>
                        <div className="mt-3">
                          <p className="mb-0 blue-button-cl">Creation Date:</p>
                        </div>
                        <div className="d-flex justify-content-between flex-wrap">
                          <div>
                            <p className="mb-0">{formatDateFromTimestamp(item.createdAt)}</p>
                          </div>
                          {/* <div className="">
                            <button className="blue-button-bg text-white px-3 py-2 rounded-3 border-0">
                              Save to folder
                            </button>
                          </div> */}
                        </div>
                        <div className="d-flex align-items-center mt-3">
                          <div className="me-3">
                            <i className="bi bi-three-dots dots-bg p-2 rounded-3 blue-button-cl" />
                          </div>
                          <div className="w-100">
                              <button onClick={()=>navigate(`/admin/qrode/modify/${item._id}`)} className="dots-bg px-3 py-2 border-0 rounded-3 w-100 blue-button-cl">
                              Modify
                            </button>
                          </div>
                        </div>
                      </div>
                      <div className="mt-sm-0 mt-3">
                        <div className="text-center" >
                          <img
                            src={item.image}
                            alt=""
                            className="group-img object-fit-fill"
                          />
                        </div>
                        <div className="mt-2">
                          <button onClick={()=>downloadFile(item.image)} className="rounded-3 border-blue blue-button-cl bg-transparent px-2 py-1 w-100">Download</button>
                          {/* <select
                            name=""
                            id=""
                            className="rounded-3 border-blue blue-button-cl bg-transparent px-2 py-1 w-100"
                          >
                            <option value="">Download</option>
                          </select> */}
                        </div>
                      </div>
                    </div>
                  </div>)}
                 
                </div>: <div className='d-flex justify-content-center'>
      <Loader />
    </div>}
              </div>
           
      </CustomTabPanel>
      <CustomTabPanel value={value} index={1}>
      
      <div className="  ">
               {!load? <div className=" row folder-height2 pe-lg-5">
                  {qr?.map((item)=>
                  <div className=" col-lg-6 mb-3 " >
                    <div className="d-sm-flex align-items-end  justify-content-between light-blue2 rounded-4 p-3" style={{height:"250px"}}>
                      <div className="w-100  pe-3">
                        <div className=" d-flex border-bottom border-primary w-100">
                          {/* <div className="mt-2">
                            <img
                              src={require("../assets/profile-img.png")}
                              alt=""
                              className="member-img"
                            />
                          </div> */}
                          <div className="ps-2 d-flex w-100 justify-content-between">
                            <p className=" blue-button-cl">{item.name}</p>
                            <QrPopup 
                         onEdit={()=>navigate(`/admin/qrode/modifyContent/${item._id}`)}
                         handleArchive={()=>handleArchive(item._id)}
                         
                         />
                            {/* <p>Member@gmail.com</p> */}
                          </div>
                        </div>
                        <div className="mt-3">
                          <p className="mb-0 blue-button-cl">Creation Date:</p>
                        </div>
                        <div className="d-flex justify-content-between flex-wrap">
                          <div>
                            <p className="mb-0">{formatDateFromTimestamp(item.createdAt)}</p>
                          </div>
                          {/* <div className="">
                            <button className="blue-button-bg text-white px-3 py-2 rounded-3 border-0">
                              Save to folder
                            </button>
                          </div> */}
                        </div>
                        <div className="d-flex align-items-center mt-3">
                          <div className="me-3">
                            <i className="bi bi-three-dots dots-bg p-2 rounded-3 blue-button-cl" />
                          </div>
                          <div className="w-100">
                              <button onClick={()=>navigate(`/admin/qrode/modify/${item._id}`)} className="dots-bg px-3 py-2 border-0 rounded-3 w-100 blue-button-cl">
                              Modify
                            </button>
                          </div>
                        </div>
                      </div>
                      <div className="mt-sm-0 mt-3">
                        <div className="text-center" >
                          <img
                            src={item.image}
                            alt=""
                            className="group-img object-fit-fill"
                          />
                        </div>
                        <div className="mt-2">
                          <button onClick={()=>downloadFile(item.image)} className="rounded-3 border-blue blue-button-cl bg-transparent px-2 py-1 w-100">Download</button>
                          {/* <select
                            name=""
                            id=""
                            className="rounded-3 border-blue blue-button-cl bg-transparent px-2 py-1 w-100"
                          >
                            <option value="">Download</option>
                          </select> */}
                        </div>
                      </div>
                    </div>
                  </div>)}
                 
                </div>: <div className='d-flex justify-content-center'>
      <Loader />
    </div>}
              </div>
           
      </CustomTabPanel>
      <CustomTabPanel value={value} index={2}>
      
      <div className="  ">
               {!load? <div className=" row folder-height2 pe-lg-5">
                  {qr?.map((item)=>
                  <div className=" col-lg-6 mb-3 " >
                    <div className="d-sm-flex align-items-end  justify-content-between light-blue2 rounded-4 p-3" style={{height:"250px"}}>
                      <div className="w-100  pe-3">
                        <div className=" d-flex border-bottom border-primary w-100">
                          {/* <div className="mt-2">
                            <img
                              src={require("../assets/profile-img.png")}
                              alt=""
                              className="member-img"
                            />
                          </div> */}
                          <div className="ps-2 d-flex w-100 justify-content-between">
                            <p className=" blue-button-cl">{item.name}</p>
                            <QrPopup 
                         onEdit={()=>navigate(`/admin/qrode/modifyContent/${item._id}`)}
                         handleArchive={()=>handleArchive(item._id)}
                         
                         />
                            {/* <p>Member@gmail.com</p> */}
                          </div>
                        </div>
                        <div className="mt-3">
                          <p className="mb-0 blue-button-cl">Creation Date:</p>
                        </div>
                        <div className="d-flex justify-content-between flex-wrap">
                          <div>
                            <p className="mb-0">{formatDateFromTimestamp(item.createdAt)}</p>
                          </div>
                          {/* <div className="">
                            <button className="blue-button-bg text-white px-3 py-2 rounded-3 border-0">
                              Save to folder
                            </button>
                          </div> */}
                        </div>
                        <div className="d-flex align-items-center mt-3">
                          <div className="me-3">
                            <i className="bi bi-three-dots dots-bg p-2 rounded-3 blue-button-cl" />
                          </div>
                          <div className="w-100">
                              <button onClick={()=>navigate(`/admin/qrode/modify/${item._id}`)} className="dots-bg px-3 py-2 border-0 rounded-3 w-100 blue-button-cl">
                              Modify
                            </button>
                          </div>
                        </div>
                      </div>
                      <div className="mt-sm-0 mt-3">
                        <div className="text-center" >
                          <img
                            src={item.image}
                            alt=""
                            className="group-img object-fit-fill"
                          />
                        </div>
                        <div className="mt-2">
                          <button onClick={()=>downloadFile(item.image)} className="rounded-3 border-blue blue-button-cl bg-transparent px-2 py-1 w-100">Download</button>
                          {/* <select
                            name=""
                            id=""
                            className="rounded-3 border-blue blue-button-cl bg-transparent px-2 py-1 w-100"
                          >
                            <option value="">Download</option>
                          </select> */}
                        </div>
                      </div>
                    </div>
                  </div>)}
                 
                </div>: <div className='d-flex justify-content-center'>
      <Loader />
    </div>}
              </div>
          
      </CustomTabPanel>
    </Box>
        {/* <div className="static-main">
          <ul className="nav nav-pills mb-3" id="pills-tab" role="tablist">
            <li className="nav-item" role="presentation">
              <button
                className="nav-link active"
                id="pills-home-tab"
                data-bs-toggle="pill"
                data-bs-target="#pills-home"
                type="button"
                role="tab"
                aria-controls="pills-home"
                aria-selected="true"
                onClick={()=>setType("")}
              >
                All
              </button>
            </li>
            <li className="nav-item" role="presentation">
              <button
                className="nav-link"
                id="pills-profile-tab"
                data-bs-toggle="pill"
                data-bs-target="#pills-profile"
                type="button"
                role="tab"
                aria-controls="pills-profile"
                aria-selected="false"
                onClick={()=>setType("Static")}
              >
                Static
              </button>
            </li>
            <li className="nav-item" role="presentation">
              <button
                className="nav-link"
                id="pills-contact-tab"
                data-bs-toggle="pill"
                data-bs-target="#pills-contact"
                type="button"
                role="tab"
                aria-controls="pills-contact"
                aria-selected="false"
                onClick={()=>setType("Dynamic")}
              >
                Dynamic
              </button>
            </li>
          </ul>
          <div className="tab-content" id="pills-tabContent">
            <div
              className="tab-pane fade show active"
              id="pills-home"
              role="tabpanel"
              aria-labelledby="pills-home-tab"
              tabIndex={0}
            >
              <div className="  ">
               {!load? <div className=" row folder-height2 pe-lg-5">
                  {qr?.map((item)=>
                  <div className=" col-lg-6 mb-3 " >
                    <div className="d-sm-flex align-items-end  justify-content-between light-blue2 rounded-4 p-3" style={{height:"250px"}}>
                      <div className="w-100  pe-3">
                        <div className=" d-flex border-bottom border-primary w-100">
                         
                          <div className="ps-2 d-flex w-100 justify-content-between">
                            <p className=" blue-button-cl">{item.name}</p>
                            <QrPopup 
                         onEdit={()=>navigate(`/admin/modifyContent/${item._id}`)}
                         handleArchive={()=>handleArchive(item._id)}
                         
                         />
                            
                          </div>
                        </div>
                        <div className="mt-3">
                          <p className="mb-0 blue-button-cl">Creation Date:</p>
                        </div>
                        <div className="d-flex justify-content-between flex-wrap">
                          <div>
                            <p className="mb-0">{formatDateFromTimestamp(item.createdAt)}</p>
                          </div>
                          
                        </div>
                        <div className="d-flex align-items-center mt-3">
                          <div className="me-3">
                            <i className="bi bi-three-dots dots-bg p-2 rounded-3 blue-button-cl" />
                          </div>
                          <div className="w-100">
                              <button onClick={()=>navigate(`/admin/modify/${item._id}`)} className="dots-bg px-3 py-2 border-0 rounded-3 w-100 blue-button-cl">
                              Modify
                            </button>
                          </div>
                        </div>
                      </div>
                      <div className="mt-sm-0 mt-3">
                        <div className="text-center" >
                          <img
                            src={item.image}
                            alt=""
                            className="group-img object-fit-fill"
                          />
                        </div>
                        <div className="mt-2">
                          <button onClick={()=>downloadFile(item.image)} className="rounded-3 border-blue blue-button-cl bg-transparent px-2 py-1 w-100">Download</button>
                         
                        </div>
                      </div>
                    </div>
                  </div>)}
                 
                </div>: <div className='d-flex justify-content-center'>
      <Loader />
    </div>}
              </div>
            </div>
            <div
              className="tab-pane fade"
              id="pills-profile"
              role="tabpanel"
              aria-labelledby="pills-profile-tab"
              tabIndex={0}
            >
          <div className=" shadow bg-white rounded-4 py-3 px-4 mt-3">
                <div className=" row folder-height2 pe-lg-5">
                  {qr?.map((item)=>
                  <div className=" col-lg-6 mb-3">
                    <div className="d-sm-flex align-items-end justify-content-between light-blue2 rounded-4 p-3">
                      <div className="w-100 pe-3">
                        <div className="d-flex border-bottom border-primary w-100">

                          <div className="ps-2 d-flex w-100 justify-content-between">
                            <p className=" blue-button-cl">{item.name}</p>
                            <QrPopup 
                         onEdit={()=>navigate(`/admin/modifyContent/${item._id}`)}
                         handleArchive={()=>handleArchive(item._id)}
                         
                         />
                          
                          </div>
                        </div>
                        <div className="mt-3">
                          <p className="mb-0 blue-button-cl">Creation Date:</p>
                        </div>
                        <div className="d-flex justify-content-between flex-wrap">
                          <div>
                            <p className="mb-0">{formatDateFromTimestamp(item.createdAt)}</p>
                          </div>
                          
                        </div>
                        <div className="d-flex align-items-center mt-3">
                          <div className="me-3">
                            <i className="bi bi-three-dots dots-bg p-2 rounded-3 blue-button-cl" />
                          </div>
                          <div className="w-100">
                            <button onClick={()=>navigate(`/admin/modify/${item._id}`)} className="dots-bg px-3 py-2 border-0 rounded-3 w-100 blue-button-cl">
                              Modify
                            </button>
                          </div>
                        </div>
                      </div>
                      <div className="mt-sm-0 mt-3">
                        <div className="text-center">
                          <img
                            src={item.image}
                            alt=""
                            className="group-img"
                          />
                        </div>
                        <div className="mt-2">
                          <button onClick={()=>downloadFile(item.image)} className="rounded-3 border-blue blue-button-cl bg-transparent px-2 py-1 w-100">Download</button>
                          
                        </div>
                      </div>
                    </div>
                  </div>)}
                 
                </div>
              </div>
            </div>
            <div
              className="tab-pane fade"
              id="pills-contact"
              role="tabpanel"
              aria-labelledby="pills-contact-tab"
              tabIndex={0}
            >
             <div className=" shadow bg-white rounded-4 py-3 px-4 mt-3">
                <div className=" row folder-height2 pe-lg-5">
                  {qr?.map((item)=>
                  <div className=" col-lg-6 mb-3">
                    <div className=" d-sm-flex align-items-end justify-content-between light-blue2 rounded-4 p-3">
                      <div className="w-100 pe-3">
                        <div className="d-flex border-bottom border-primary w-100">
                          
                          <div className="ps-2 d-flex w-100 justify-content-between">
                            <p className=" blue-button-cl">{item.name}</p>
                            <QrPopup 
                         onEdit={()=>navigate(`/admin/modifyContent/${item._id}`)}
                         handleArchive={()=>handleArchive(item._id)}
                         
                         />
                           
                          </div>
                        </div>
                        <div className="mt-3">
                          <p className="mb-0 blue-button-cl">Creation Date:</p>
                        </div>
                        <div className="d-flex justify-content-between flex-wrap">
                          <div>
                            <p className="mb-0">{formatDateFromTimestamp(item.createdAt)}</p>
                          </div>
                         
                        </div>
                        <div className="d-flex align-items-center mt-3">
                          <div className="me-3">
                            <i className="bi bi-three-dots dots-bg p-2 rounded-3 blue-button-cl" />
                          </div>
                          <div className="w-100">
                            <button onClick={()=>navigate(`/admin/modify/${item._id}`)} className="dots-bg px-3 py-2 border-0 rounded-3 w-100 blue-button-cl">
                              Modify
                            </button>
                          </div>
                        </div>
                      </div>
                      <div className="mt-sm-0 mt-3">
                        <div className="text-center">
                          <img
                            src={item.image}
                            alt=""
                            className="group-img"
                          />
                        </div>
                        <div className="mt-2">
                          <button onClick={()=>downloadFile(item.image)} className="rounded-3 border-blue blue-button-cl bg-transparent px-2 py-1 w-100">Download</button>
                        
                        </div>
                      </div>
                    </div>
                  </div>)}
                 
                </div>
              </div>
            </div>
          </div>
        </div> */}
      </div>
      
    </section>
  )
}

export default Qrcode

function CustomTabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

CustomTabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}
