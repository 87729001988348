import React from 'react'
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Slider from 'react-slick';




function SampleNextArrow(props) {
    const { className, style, onClick } = props;
    return (
      <div
        className={className}
        style={{ ...style, display: "block", background: "#3278E4", borderRadius:"50%"  }}
        onClick={onClick}
      />
    );
  }
  
  function SamplePrevArrow(props) {
    const { className, style, onClick } = props;
    return (
      <div
        className={className}
        style={{ ...style, display: "block", background: "#3278E4", borderRadius:"50%" }}
        onClick={onClick}
      />
    );
  }

export const Home = () => {
    const slid = {
        dots: false,
        infinite: true,
        speed: 500,

        slidesToShow: 5,
        slidesToScroll: 1,
        nextArrow: <SampleNextArrow />,
      prevArrow: <SamplePrevArrow />,
        responsive: [
            {
              breakpoint: 590,
              settings: {
                slidesToShow: 3,
                slidesToScroll: 1,
                
              }
            },
            {
                breakpoint: 420,
                settings: {
                  slidesToShow: 2,
                  slidesToScroll: 1,
                  
                }
              },
        ]
    };
    const slid2 = {
        dots: true,
        arrows: false,
        autoplay: true,
        infinite: true,
        centeredmode:true,
       
        speed: 500,
        slidesToShow: 3.05,
        slidesToScroll: 1,
        responsive: [
            {
              breakpoint: 1200,
              settings: {
                slidesToShow: 2,
                slidesToScroll: 1,
                
              }
            },
            {
                breakpoint: 768,
                settings: {
                  slidesToShow: 1,
                  slidesToScroll: 1,
                  
                }
              },
        ]
    };
    return (
        <>
            <div className="top bg-lightblue mb-3 mx-3 rounded-bottom-4 py-2">
              
                {/* -----------------
      start|| hero
  ----------------- */}
                <div className="hero-sec">
                    <div className="mycontainer">
                        <div className="row d-flex justify-content-center justify-content-lg-between my-5">
                            <div className="col-lg-7 pe-5">
                                <div>
                                    <h1 className="fw-bolder display-3">
                                        <span className=" blue-clr">
                                            Unlock Infinite Possibilities with
                                        </span>
                                        QR Codes
                                    </h1>
                                    <p>
                                        Elevate your business, captivate your audience, and connect
                                        with simplicity. At InstantQRcodes.com, we empower you to
                                        create, customize, and track QR codes like never before.
                                    </p>
                                    <form action="#">
                                        <div className="row d-flex justify-content-between align-items-center  bg-white py-3 ps-4 pe-0 rounded-pill inset-field">
                                            <div className="col-sm-7 col-lg-8 col-xl-9 getstarted ">
                                                <label htmlFor="mail">
                                                    <span className=" blue-clr">Your email address</span>
                                                </label>
                                                <br />
                                                <input
                                                    type="email"
                                                    className="bg-white border-0 w-100 pe-5"
                                                    id="mail"
                                                    placeholder="Username@gmail.com"
                                                />
                                            </div>
                                            <div className="col-sm-5 col-lg-4 col-xl-3 d-none d-sm-flex justify-content-end">
                                                <button
                                                    type="submit"
                                                    className="bg-myblue text-white py-3 px-4 rounded-pill border-0 fs-6"
                                                >
                                                    Get Started
                                                </button>
                                            </div>
                                        </div>
                                        <div className="row mt-4">
                                            <div className="d-sm-none d-flex justify-content-end w-100">
                                                <button
                                                    type="submit"
                                                    className="bg-myblue text-white w-100  py-3 px-4 rounded-pill border-0 fs-6"
                                                >
                                                    Get Started
                                                </button>
                                            </div>
                                        </div>
                                    </form>
                                </div>
                            </div>
                            <div className=" col-8 col-sm-7 col-md-5 mt-5 mt-lg-0 d-flex align-items-center justify-content-center bg-blue1 ">
                                <img
                                    src={require("../assets/barcode.png")}
                                    alt="qrcode"
                                    className="img-fluid"
                                />
                            </div>
                        </div>
                    </div>
                </div>
                {/* -----------------
      end|| hero 
  ----------------- */}
            </div>
            {/* -----------------
      start || How It Works ? 
  ----------------- */}
            <div className="my-lg-5 py-3">
                <div className="work-sec mb-5 my-md-5 col-md-11 col-lg-10">
                    <div className="mycontainer mt-5">
                        <div className="row py-5 shadow-bx px-0 px-lg-5">
                            <div className="col-md-7 ps-4">
                                <h3 className="fw-normal">Easy as 1-2-3</h3>
                                <h2 className="display-3 blue-clr fw-bold">How It Works ? </h2>
                                <p>
                                    Elevate your business, captivate your audience, and connect with
                                    simplicity. At InstantQRcodes.com, we empower you to create,
                                    customize, and track QR codes like never before.
                                </p>
                            </div>
                            <div className="col-md-3 d-flex align-items-center barcode justify-content-center">
                                <img
                                    src={require("../assets/Group 43878.png")}
                                    alt="barcode"
                                    className="img-fluid"
                                />
                            </div>
                            <div className="row d-flex flex-wrap justify-content-start justify-content-lg-around pt-4">
                                <div className="col-sm-6 col-lg-4 rounded-4 ms-0 my-3">
                                    <div className="boxr px-4 h-100 py-3 ">
                                        <div className="d-flex justify-content-between align-items-center">
                                            <h2 className="blue-clr display-5 fw-semi">01</h2>
                                            <img
                                                src={require("../assets/arrow.png")}
                                                alt="cursor"
                                                className="img-fluid"
                                            />
                                        </div>
                                        <div>
                                            <p className="mb-0 fw-bolder">Select Your Content</p>
                                            <p className="mb-0">
                                                Pick the QR code type that suits your purpose, from
                                                websites to events.
                                            </p>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-sm-6 col-lg-4 rounded-4 ms-0 my-3">
                                    <div className="boxr  px-4 h-100 py-3 ">
                                        <div className="d-flex justify-content-between align-items-center">
                                            <h2 className="blue-clr display-5 fw-semi">02</h2>
                                            <img
                                                src={require("../assets/edit.png")}
                                                alt="cursor"
                                                className="img-fluid"
                                            />
                                        </div>
                                        <div>
                                            <p className="mb-0 fw-bolder">Fill in the Details</p>
                                            <p className="mb-0">
                                                Enter the necessary information, such as URLs, contact
                                                details, or text
                                            </p>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-sm-6 col-lg-4 rounded-4 ms-0 my-3">
                                    <div className="boxr px-4 h-100 py-3 ">
                                        <div className="d-flex justify-content-between align-items-center">
                                            <h2 className="blue-clr display-5 fw-semi">03</h2>
                                            <img
                                                src={require("../assets/download.png")}
                                                alt="cursor"
                                                className="img-fluid"
                                            />
                                        </div>
                                        <div>
                                            <p className="mb-0 fw-bolder">Download Your QR Code</p>
                                            <p className="mb-0">
                                                Customize the design, download in various formats, and
                                                you're ready to roll
                                            </p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {/* -----------------
      end || How It Works ? 
  ----------------- */}
            {/* -----------------
      start || Our Services 
  ----------------- */}
            <div className=" ourservice">
                <div className="mycontainer p-4">
                    <div className="row justify-content-center">
                        <div className="col-12">
                            <h2 className="display-4 blue-clr text-center mb-0 fw-bold">
                                Our Services
                            </h2>
                            <p className="text-center mb-0 fs-5">A QR code for every need</p>
                        </div>
                        <div className="row d-flex my-md-5 justify-content-center">
                            <div className="col-12 col-md-6 col-lg-6 mt-5 mt-lg-3">
                                <div className="row d-flex justify-content-between">
                                    <div className="col-12 mt-3 col-sm-6 mt-sm-0">
                                        <div className="serv-box d-flex  align-items-center mx-2 p-2 rounded-4">
                                            <img
                                                className="serv-img"
                                                src={require("../assets/apps.png")}
                                                alt="arrow"
                                            />
                                            <p className="mb-0 ms-3 serv-p">Website URL</p>
                                        </div>
                                    </div>
                                    <div className="col-12 mt-3 col-sm-6 mt-sm-0">
                                        <div className=" serv-box d-flex  align-items-center  mx-2 p-2  rounded-4">
                                            <img
                                                className="serv-img"
                                                src={require("../assets/Text.png")}
                                                alt="arrow"
                                            />
                                            <p className="mb-0 ms-3 serv-p">V Card</p>
                                        </div>
                                    </div>
                                </div>
                                <div className="row d-flex justify-content-between mt-sm-3">
                                    <div className="col-12 mt-3 col-sm-6 mt-sm-0">
                                        <div className="serv-box d-flex  align-items-center mx-2 p-2 rounded-4">
                                            <img
                                                className="serv-img"
                                                src={require("../assets/arrow.png")}
                                                alt="arrow"
                                            />
                                            <p className="mb-0 ms-3 serv-p">Apps</p>
                                        </div>
                                    </div>
                                    <div className="col-12 mt-3 col-sm-6 mt-sm-0">
                                        <div className=" serv-box d-flex  align-items-center  mx-2 p-2  rounded-4">
                                            <img
                                                className="serv-img"
                                                src={require("../assets/vcard.png")}
                                                alt="arrow"
                                            />
                                            <p className="mb-0 ms-3 serv-p">Simple text</p>
                                        </div>
                                    </div>
                                </div>
                                <div className="row d-flex justify-content-between mt-sm-3">
                                    <div className="col-12 mt-3 col-sm-6 mt-sm-0">
                                        <div className="serv-box d-flex  align-items-center mx-2 p-2 rounded-4">
                                            <img
                                                className="serv-img"
                                                src={require("../assets/gallery.png")}
                                                alt="arrow"
                                            />
                                            <p className="mb-0 ms-3 serv-p">Images</p>
                                        </div>
                                    </div>
                                    <div className="col-12 mt-3 col-sm-6 mt-sm-0">
                                        <div className=" serv-box d-flex  align-items-center  mx-2 p-2  rounded-4">
                                            <img
                                                className="serv-img"
                                                src={require("../assets/PDF.png")}
                                                alt="arrow"
                                            />
                                            <p className="mb-0 ms-3 serv-p">Pdf</p>
                                        </div>
                                    </div>
                                </div>
                                <div className="row d-flex justify-content-between mt-sm-3">
                                    <div className="col-12 mt-3 col-sm-6 mt-sm-0">
                                        <div className="serv-box d-flex  align-items-center mx-2 p-2 rounded-4">
                                            <img className="serv-img" src={require("../assets/fb.png")} alt="arrow" />
                                            <p className="mb-0 ms-3 serv-p">Facebook</p>
                                        </div>
                                    </div>
                                    <div className="col-12 mt-3 col-sm-6 mt-sm-0">
                                        <div className=" serv-box d-flex  align-items-center mx-2 p-2  rounded-4">
                                            <img
                                                className="serv-img"
                                                src={require("../assets/wifi.png")}
                                                alt="arrow"
                                            />
                                            <p className="mb-0 ms-3 serv-p">Wifi</p>
                                        </div>
                                    </div>
                                </div>
                                <div className="row d-flex justify-content-between mt-sm-3">
                                    <div className="col-12 mt-3 col-sm-6 mt-sm-0">
                                        <div className="serv-box d-flex  align-items-center mx-2 p-2 rounded-4">
                                            <img
                                                className="serv-img"
                                                src={require("../assets/video.png")}
                                                alt="arrow"
                                            />
                                            <p className="mb-0 ms-3 serv-p">Video</p>
                                        </div>
                                    </div>
                                    <div className="col-12 mt-3 col-sm-6 mt-sm-0">
                                        <div className=" serv-box d-flex  align-items-center  mx-2 p-2  rounded-4">
                                            <img
                                                className="serv-img"
                                                src={require("../assets/video.png")}
                                                alt="arrow"
                                            />
                                            <p className="mb-0 ms-3 serv-p">Video</p>
                                        </div>
                                    </div>
                                </div>
                                <div className="row d-flex justify-content-between mt-sm-3">
                                    <div className="col-12 mt-3 col-sm-6 mt-sm-0">
                                        <div className="serv-box d-flex  align-items-center mx-2 p-2 rounded-4">
                                            <img
                                                className="serv-img"
                                                src={require("../assets/PDF.png")}
                                                alt="arrow"
                                            />
                                            <p className="mb-0 ms-3 serv-p">Social Media</p>
                                        </div>
                                    </div>
                                    <div className="col-12 mt-3 col-sm-6 mt-sm-0">
                                        <div className=" serv-box d-flex  align-items-center  mx-2 p-2  rounded-4">
                                            <img
                                                className="serv-img"
                                                src={require("../assets/businesscard.png")}
                                                alt="arrow"
                                            />
                                            <p className="mb-0 ms-3 serv-p">Business Page</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-12 col-md-6 col-lg-3 mt-5 mt-lg-3 text-center">
                                <img
                                    src={require("../assets/phone-wifi.png")}
                                    className="img-fluid phone-img"
                                    alt="phone-wifi"
                                />
                            </div>
                            <div className="col-12 col-md-6 col-lg-3 mt-5 mt-lg-3">
                                <div className="d-flex flex-column wifi-txt rounded-4 px-3 py-4">
                                    <div className="">
                                        <h2 className="blue-clr">Wi-Fi</h2>
                                        <p>
                                            Avoid password problems by using a QR code to unlock Wi-Fi
                                            access. With a quick scan, customers can easily get on to
                                            your network. Fewer interruptions and super-fast connections
                                            will keep everyone happy.
                                        </p>
                                    </div>
                                    <div className="mt-auto">
                                        <a
                                            href="#"
                                            className="bg-blue w-auto text-center text-decoration-none px-3 bg-myblue text-white py-3 px-3 rounded-4 fs-6 bs-opacity mt-3 mt-md-0"
                                        >
                                            <span className="me-3">Create QR code</span>
                                            <i className="fa-solid fa-arrow-right" />
                                        </a>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {/* -----------------
      end || Our Services 
  ----------------- */}
            {/* -----------------
      start || Why Choose Us? 
  ----------------- */}
            <div className="my-5 ">
                <div>
                    <h2 className="display-4 blue-clr text-center my-3 fw-bold">
                        Why Choose Us?
                    </h2>
                </div>
                <div className="choose">
                    <div className="mycontainer peach-bg rounded-5 p-4 my-5">
                        <div className="row d-flex align-items-center justify-content-around">
                            <div className="col-lg-6">
                                <div className="d-flex flex-column ">
                                    <div className="d-flex align-items-center">
                                        <img src={require("../assets/hand.png")} alt="hand" className="hand2" />
                                        <h2 className="mx-3 display-6 mb-0 fw-semi">
                                            Limitless Creations
                                        </h2>
                                    </div>
                                    <div className="mt-3 mb-3 mb-lg-5">
                                        <p>
                                            Create as many dynamic QR codes as you desire. Share your
                                            website, videos, business info, and more, effortlessly
                                        </p>
                                    </div>
                                    <div className="mt-lg-5">
                                        <a
                                            href="#"
                                            className=" w-auto text-center d-block d-sm-inline text-decoration-none px-3 bg-myblue text-white me-sm-2 py-3 px-4 rounded-4 fs-6 bs-opacity mt-3 mt-md-0"
                                        >
                                            <span className="me-3">Get Started</span>
                                            <i className="fa-solid fa-arrow-right" />
                                        </a>
                                        <a
                                            href="#"
                                            className="contact-btn w-auto text-center d-block d-sm-inline text-decoration-none px-3 text-blue-clr ms-sm-2 py-3 px-4 rounded-4 fs-6 bs-opacity mt-3 mt-md-0"
                                        >
                                            <span className="me-3">contact Us</span>
                                            <i className="fa-solid fa-arrow-right" />
                                        </a>
                                    </div>
                                </div>
                            </div>
                            <div className="col-8 col-sm-7 col-md-5 mt-5 mt-lg-0">
                                <img
                                    src={require("../assets/limtless.png")}
                                    alt="limitless"
                                    className="img-fluid"
                                />
                            </div>
                        </div>
                    </div>
                    <div className="mycontainer lightble-bg rounded-5 p-4 my-5">
                        <div className="row d-flex align-items-center justify-content-around">
                            <div className="col-lg-6">
                                <div className="d-flex flex-column ">
                                    <div className="d-flex align-items-center">
                                        <img src={require("../assets/hand.png")} alt="hand" className="hand2" />
                                        <h2 className="mx-3 display-6 mb-0 fw-semi">
                                            Track and Thrive
                                        </h2>
                                    </div>
                                    <div className="mt-3 mb-3 mb-lg-5">
                                        <p>
                                            Dive into the data. Understand your audience better with
                                            in-depth statistics. Refine your strategies based on
                                            real-time insights
                                        </p>
                                    </div>
                                    <div className="mt-lg-5">
                                        <a
                                            href="#"
                                            className=" w-auto text-center d-block d-sm-inline text-decoration-none px-3 bg-myblue text-white me-sm-2 py-3 px-4 rounded-4 fs-6 bs-opacity mt-3 mt-md-0"
                                        >
                                            <span className="me-3">Get Started</span>
                                            <i className="fa-solid fa-arrow-right" />
                                        </a>
                                        <a
                                            href="#"
                                            className="contact-btn w-auto text-center d-block d-sm-inline text-decoration-none px-3 text-blue-clr ms-sm-2 py-3 px-4 rounded-4 fs-6 bs-opacity mt-3 mt-md-0"
                                        >
                                            <span className="me-3">contact Us</span>
                                            <i className="fa-solid fa-arrow-right" />
                                        </a>
                                    </div>
                                </div>
                            </div>
                            <div className="col-8 col-sm-7 col-md-5 mt-5 mt-lg-0">
                                <img src={require("../assets/track.png")} alt="track" className="img-fluid" />
                            </div>
                        </div>
                    </div>
                    <div className="mycontainer lightgray-bg rounded-5 p-4 my-5">
                        <div className="row d-flex align-items-center justify-content-around">
                            <div className="col-lg-6">
                                <div className="d-flex flex-column ">
                                    <div className="d-flex align-items-center">
                                        <img src={require("../assets/hand.png")} alt="hand" className="hand2" />
                                        <h2 className="mx-3 display-6 mb-0 fw-semi">
                                            Geographical Insight
                                        </h2>
                                    </div>
                                    <div className="mt-3 mb-3 mb-lg-5">
                                        <p>
                                            Know when and where your QR codes are scanned. Tailor your
                                            campaigns to resonate with your audience's location
                                        </p>
                                    </div>
                                    <div className="mt-lg-5">
                                        <a
                                            href="#"
                                            className=" w-auto text-center d-block d-sm-inline text-decoration-none px-3 bg-myblue text-white me-sm-2 py-3 px-4 rounded-4 fs-6 bs-opacity mt-3 mt-md-0"
                                        >
                                            <span className="me-3">Get Started</span>
                                            <i className="fa-solid fa-arrow-right" />
                                        </a>
                                        <a
                                            href="#"
                                            className="contact-btn w-auto text-center d-block d-sm-inline text-decoration-none px-3 text-blue-clr ms-sm-2 py-3 px-4 rounded-4 fs-6 bs-opacity mt-3 mt-md-0"
                                        >
                                            <span className="me-3">contact Us</span>
                                            <i className="fa-solid fa-arrow-right" />
                                        </a>
                                    </div>
                                </div>
                            </div>
                            <div className="col-8 col-sm-7 col-md-5 mt-5 mt-lg-0">
                                <img
                                    src={require("../assets/geographical.png")}
                                    alt="track"
                                    className="img-fluid"
                                />
                            </div>
                        </div>
                    </div>
                    <div className="mycontainer greenish-bg rounded-5 p-4 my-5">
                        <div className="row d-flex align-items-center justify-content-around">
                            <div className="col-lg-6">
                                <div className="d-flex flex-column ">
                                    <div className="d-flex align-items-center">
                                        <img src={require("../assets/hand.png")} alt="hand" className="hand2" />
                                        <h2 className="mx-3 display-6 mb-0 fw-semi">
                                            A Code for Every Need
                                        </h2>
                                    </div>
                                    <div className="mt-3 mb-3 mb-lg-5">
                                        <p>
                                            Choose from an extensive list of QR code types: links,
                                            vCards, text, calls, SMS, Wi-Fi, events, PDFs, apps, images,
                                            and more
                                        </p>
                                    </div>
                                    <div className="mt-lg-5">
                                        <a
                                            href="#"
                                            className=" w-auto text-center d-block d-sm-inline text-decoration-none px-3 bg-myblue text-white me-sm-2 py-3 px-4 rounded-4 fs-6 bs-opacity mt-3 mt-md-0"
                                        >
                                            <span className="me-3">Get Started</span>
                                            <i className="fa-solid fa-arrow-right" />
                                        </a>
                                        <a
                                            href="#"
                                            className="contact-btn w-auto text-center d-block d-sm-inline text-decoration-none px-3 text-blue-clr ms-sm-2 py-3 px-4 rounded-4 fs-6 bs-opacity mt-3 mt-md-0"
                                        >
                                            <span className="me-3">contact Us</span>
                                            <i className="fa-solid fa-arrow-right" />
                                        </a>
                                    </div>
                                </div>
                            </div>
                            <div className="col-8 col-sm-7 col-md-5 mt-5 mt-lg-0">
                                <img src={require("../assets/code.png")} alt="track" className="img-fluid" />
                            </div>
                        </div>
                    </div>
                    <div className="mycontainer purplish-bg rounded-5 p-4 my-5">
                        <div className="row d-flex align-items-center justify-content-around">
                            <div className="col-lg-6">
                                <div className="d-flex flex-column ">
                                    <div className="d-flex align-items-center">
                                        <img src={require("../assets/hand.png")} alt="hand" className="hand2" />
                                        <h2 className="mx-3 display-6 mb-0 fw-semi">
                                            A Code for Every Need
                                        </h2>
                                    </div>
                                    <div className="mt-3 mb-3 mb-lg-5">
                                        <p>
                                            Choose from an extensive list of QR code types: links,
                                            vCards, text, calls, SMS, Wi-Fi, events, PDFs, apps, images,
                                            and more
                                        </p>
                                    </div>
                                    <div className="mt-lg-5">
                                        <a
                                            href="#"
                                            className=" w-auto text-center d-block d-sm-inline text-decoration-none px-3 bg-myblue text-white me-sm-2 py-3 px-4 rounded-4 fs-6 bs-opacity mt-3 mt-md-0"
                                        >
                                            <span className="me-3">Get Started</span>
                                            <i className="fa-solid fa-arrow-right" />
                                        </a>
                                        <a
                                            href="#"
                                            className="contact-btn w-auto text-center d-block d-sm-inline text-decoration-none px-3 text-blue-clr ms-sm-2 py-3 px-4 rounded-4 fs-6 bs-opacity mt-3 mt-md-0"
                                        >
                                            <span className="me-3">contact Us</span>
                                            <i className="fa-solid fa-arrow-right" />
                                        </a>
                                    </div>
                                </div>
                            </div>
                            <div className="col-8 col-sm-7 col-md-5 mt-5 mt-lg-0">
                                <img src={require("../assets/brand1.png")} alt="track" className="img-fluid" />
                            </div>
                        </div>
                    </div>
                    <div className="mycontainer lightblue-bg rounded-5 p-4 my-5">
                        <div className="row d-flex align-items-center justify-content-around">
                            <div className="col-lg-6">
                                <div className="d-flex flex-column ">
                                    <div className="d-flex align-items-center">
                                        <img src={require("../assets/hand.png")} alt="hand" className="hand2" />
                                        <h2 className="mx-3 display-6 mb-0 fw-semi">
                                            Unleash Your Imagination
                                        </h2>
                                    </div>
                                    <div className="mt-3 mb-3 mb-lg-5">
                                        <p>
                                            With our intuitive QR code generator, you're the artist.
                                            Craft QR codes for websites, PDFs, videos, menus, business
                                            cards, and more. Your imagination is the limit{" "}
                                        </p>
                                    </div>
                                    <div className="mt-lg-5">
                                        <a
                                            href="#"
                                            className=" w-auto d-block d-sm-inline text-center text-decoration-none px-3 bg-myblue text-white me-sm-2 py-3 px-4 rounded-4 fs-6 bs-opacity mt-3 mt-md-0"
                                        >
                                            <span className="me-3">Get Started</span>
                                            <i className="fa-solid fa-arrow-right" />
                                        </a>
                                        <a
                                            href="#"
                                            className="contact-btn w-auto d-block d-sm-inline text-center text-decoration-none px-3 text-blue-clr ms-sm-2 py-3 px-4 rounded-4 fs-6 bs-opacity mt-3 mt-md-0"
                                        >
                                            <span className="me-3">contact Us</span>
                                            <i className="fa-solid fa-arrow-right" />
                                        </a>
                                    </div>
                                </div>
                            </div>
                            <div className="col-8 col-sm-7 col-md-5 mt-5 mt-lg-0">
                                <img src={require("../assets/brand2.png")} alt="track" className="img-fluid" />
                            </div>
                        </div>
                    </div>
                    <div className="mycontainer skyblue-bg rounded-5 p-4 my-5">
                        <div className="row d-flex align-items-center justify-content-around">
                            <div className="col-lg-6">
                                <div className="d-flex flex-column ">
                                    <div className="d-flex align-items-center">
                                        <img src={require("../assets/hand.png")} alt="hand" className="hand2" />
                                        <h2 className="mx-3 display-6 mb-0 fw-semi">
                                            Dynamic, Not Static
                                        </h2>
                                    </div>
                                    <div className="mt-3 mb-3 mb-lg-5">
                                        <p>
                                            Unlike the ordinary, our QR codes are dynamic. Modify and
                                            update your content on the fly without reprinting. Stay
                                            agile in a fast-paced world{" "}
                                        </p>
                                    </div>
                                    <div className="mt-lg-5">
                                        <a
                                            href="#"
                                            className=" w-auto text-center d-block d-sm-inline text-decoration-none px-3 bg-myblue text-white me-sm-2 py-3 px-4 rounded-4 fs-6 bs-opacity mt-3 mt-md-0"
                                        >
                                            <span className="me-3">Get Started</span>
                                            <i className="fa-solid fa-arrow-right" />
                                        </a>
                                        <a
                                            href="#"
                                            className="contact-btn w-auto text-center d-block d-sm-inline text-decoration-none px-3 text-blue-clr ms-sm-2 py-3 px-4 rounded-4 fs-6 bs-opacity mt-3 mt-md-0"
                                        >
                                            <span className="me-3">contact Us</span>
                                            <i className="fa-solid fa-arrow-right" />
                                        </a>
                                    </div>
                                </div>
                            </div>
                            <div className="col-8 col-sm-7 col-md-5 mt-5 mt-lg-0">
                                <img src={require("../assets/brand3.png")} alt="track" className="img-fluid" />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {/* -----------------
      end || Why Choose Us? 
  ----------------- */}
            {/* -----------------
      start || Clients we’ve got results 
  ----------------- */}
            <div className="client my-5">
                <div>
                    <h2 className="display-4 blue-clr text-center my-3 fw-bold">
                        Clients we’ve got results form.
                    </h2>
                </div>

                <div className="mycontainer mb-lg-5 pb-lg-5">
                    <div className="lightble-bg rounded-4 py-1 px-4 px-sm-5">
                        <Slider {...slid}>
                            <div>
                                <img
                                    className="slid-img"
                                    src={require("../assets/tplink.png")}
                                    alt="tplink"
                                />
                            </div>
                            <div>
                                <img
                                    className="slid-img"
                                    src={require("../assets/digital-x-logo.png")}
                                    alt="digital-x-logo"
                                />
                            </div>
                            <div>
                                <img
                                    className="slid-img"
                                    src={require("../assets/excel-brand-logo.png")}
                                    alt="excel-brand-logo"
                                />
                            </div>
                            <div>
                                <img
                                    className="slid-img"
                                    src={require("../assets/Logitech-logo-300x300.png")}
                                    alt="Logitech-logo-300x300"
                                />
                            </div>
                            <div>
                                <img
                                    className="slid-img"
                                    src={require("../assets/hikvison.png")}
                                    alt="hikvison"
                                />
                            </div>
                            <div>
                                <img
                                    className="slid-img"
                                    src={require("../assets/tplink.png")}
                                    alt="tplink"
                                />
                            </div>
                            <div>
                                <img
                                    className="slid-img"
                                    src={require("../assets/digital-x-logo.png")}
                                    alt="digital-x-logo"
                                />
                            </div>
                            <div>
                                <img
                                    className="slid-img"
                                    src={require("../assets/excel-brand-logo.png")}
                                    alt="excel-brand-logo"
                                />
                            </div>
                            <div>
                                <img
                                    className="slid-img"
                                    src={require("../assets/Logitech-logo-300x300.png")}
                                    alt="Logitech-logo-300x300"
                                />
                            </div>
                            <div>
                                <img
                                    className="slid-img"
                                    src={require("../assets/hikvison.png")}
                                    alt="hikvison"
                                />
                            </div>
                        </Slider>
                    </div>
                </div>

            </div>
            {/* -----------------
      end || Clients we’ve got results 
  ----------------- */}
            {/* -----------------
      start || Ready to Get Started
  ----------------- */}
            <div className="blue-bg py-3 my-5">
                <div className="mycontainer">
                    <div className="row d-flex flex-column align-items-center justify-content-center py-5 p-sm-5">
                        <div className="col-sm-10 col-md-8 col-lg-6  text-center">
                            <img
                                src={require("../assets/hand.png")}
                                alt="hand"
                                className="img-fluid hand-img"
                            />
                            <h2 className="fw-normal text-white text-center my-3">
                                Ready to Get Started
                            </h2>
                            <p className="text-white">
                                Experience the difference with{" "}
                                <span className="text-black">InstantQRcodes.com</span>. Sign up
                                for a free trial and explore the endless possibilities of QR codes
                            </p>
                        </div>
                    </div>
                </div>
            </div>
            {/* -----------------
          end || Ready to Get Started
      ----------------- */}
            {/* -----------------
      start || Our Blogs
  ----------------- */}
            <div className="blogs py-4">
                <div>
                    <h2 className="display-4 blue-clr text-center fw-bold">Our Blogs</h2>
                </div>
                <div className="mycontainer mt-5 my-sm-5">
                    <div className="row d-flex justify-content-between">
                        <div className=" col-md-6 my-3 my-lg-0 col-lg-4">
                            <div className="card mx-2  p-0 border-0 ">
                                <img
                                    className="card-img-top w-100 img-fluid"
                                    src={require("../assets/card1.png")}
                                    alt="Card image cap"
                                />
                                <div className="card-body">
                                    <h5>
                                        In publishing and graphic design, Lorem ipsum is a placeholder
                                    </h5>
                                    <p className="card-text">
                                        In publishing and graphic design, Lorem ipsum is a
                                        placeholder. In publishing and graphic design, Lorem ipsum is
                                        a placeholder
                                    </p>
                                </div>
                                <div className="d-flex justify-content-between align-items-center py-3">
                                    <div className="d-flex justify-content-start align-items-center ps-3 pe-1">
                                        <img src={require("../assets/alex.png")} alt="alex" />
                                        <p className="ms-2 small mb-0 fw-md">Alex matrin</p>
                                    </div>
                                    <div>
                                        <p className="me-3 mb-0 small">9-September-2023</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-6 my-3 my-lg-0 col-lg-4">
                            <div className="card mx-2  p-0 border-0 ">
                                <img
                                    className="card-img-top w-100 img-fluid"
                                    src={require("../assets/card2.png")}
                                    alt="Card image cap"
                                />
                                <div className="card-body">
                                    <h5>
                                        In publishing and graphic design, Lorem ipsum is a placeholder
                                    </h5>
                                    <p className="card-text">
                                        In publishing and graphic design, Lorem ipsum is a
                                        placeholder. In publishing and graphic design, Lorem ipsum is
                                        a placeholder
                                    </p>
                                </div>
                                <div className="d-flex justify-content-between align-items-center py-3">
                                    <div className="d-flex justify-content-start align-items-center ps-3 pe-1">
                                        <img src={require("../assets/alex.png")} alt="alex" />
                                        <p className="ms-2 small mb-0 fw-md">Alex matrin</p>
                                    </div>
                                    <div>
                                        <p className="me-3 mb-0 small">9-September-2023</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-6 my-3 my-lg-0 col-lg-4">
                            <div className="card mx-2  p-0 border-0 ">
                                <img
                                    className="card-img-top w-100 img-fluid"
                                    src={require("../assets/card3.png")}
                                    alt="Card image cap"
                                />
                                <div className="card-body">
                                    <h5>
                                        In publishing and graphic design, Lorem ipsum is a placeholder
                                    </h5>
                                    <p className="card-text">
                                        In publishing and graphic design, Lorem ipsum is a
                                        placeholder. In publishing and graphic design, Lorem ipsum is
                                        a placeholder
                                    </p>
                                </div>
                                <div className="d-flex justify-content-between align-items-center py-3">
                                    <div className="d-flex justify-content-start align-items-center ps-3 pe-1">
                                        <img src={require("../assets/alex.png")} alt="alex" />
                                        <p className="ms-2 small mb-0 fw-md">Alex matrin</p>
                                    </div>
                                    <div>
                                        <p className="me-3 mb-0 small">9-September-2023</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {/* -----------------
      end || Our Blogs
  ----------------- */}
            {/* -----------------
      start || Join the QR Revolution
  ----------------- */}
            <div className="bg-blue1 join py-5 my-5">
                <div className="mycontainer">
                    <div className="row d-flex flex-column align-items-center justify-content-center px-0 p-md-5">
                        <div className="col-sm-10 col-md-9 col-lg-7 col-xl-6  text-center">
                            <img
                                src={require("../assets/hand2.png")}
                                alt="hand"
                                className="img-fluid hand-img"
                            />
                            <h2 className="fw-normal blue-clr text-center my-3 fs-1 fw-bold">
                                Join the QR Revolution
                            </h2>
                            <p className="text-black fw-md">
                                QR codes, or Quick Response codes, have evolved. They're no longer
                                just for tracking vehicles but for transforming businesses. With
                                InstantQRcodes.com, you're at the forefront of this revolution.
                            </p>
                        </div>
                        <div className="col-md-10 col-lg-9 col-xl-8 mt-5">
                            <form action="#">
                                <div className="row d-flex justify-content-between align-items-center  bg-white py-3 px-3 ps-sm-4 rounded-pill">
                                    <div className="col-sm-7 col-sm-8 col-lg-9 getstarted ">
                                        <label htmlFor="mail">
                                            <span className="">Your email address</span>
                                        </label>
                                        <br />
                                        <input
                                            type="email"
                                            className="bg-white border-0 w-100 pe-sm-5 fw-bold text-black black-pl"
                                            id="mail"
                                            placeholder="Username@gmail.com"
                                        />
                                    </div>
                                    <div className="col-5 col-sm-4 col-lg-3 d-sm-flex justify-content-end d-none">
                                        <button
                                            type="submit"
                                            className="bg-myblue text-white py-3 px-4 rounded-pill border-0 fs-6"
                                        >
                                            Get Started
                                        </button>
                                    </div>
                                </div>
                                <div className=" row justify-content-end d-sm-none mt-3">
                                    <button
                                        type="submit"
                                        className="bg-myblue w-100 text-white py-3 px-4 rounded-pill border-0 fs-6"
                                    >
                                        Get Started
                                    </button>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
            {/* -----------------
    end || Join the QR Revolution
      ----------------- */}
            {/* -----------------
      start || What Our Clients Say
  ----------------- */}
            <div className="py-sm-4 py-lg-5 center-slider">
                <div className="mycontainer">
                    <h2 className="display-4 blue-clr text-center my-3 fw-bold">
                        What Our Clients Say
                    </h2>
                </div>
                <div className="container  my-5 pt-3 ">

                    <Slider {...slid2}>
                        <div className="py-4 my-5  crd-main">
                            <div className="crd blue-bg p-sm-5 p-2 rounded-sm-5 rounded-4">
                                <div className=" d-flex ">
                                    <div> <img src={require("../assets/alex2.png")} alt="alex" className="img-fluid" /></div>
                                    <div className="d-flex flex-column mx-3">
                                        <p className="mb-0 text-white">Alex matrin</p>
                                        <div className="d-flex">
                                            <img src={require("../assets/star.png")} alt="starr" />
                                            <img src={require("../assets/star.png")} alt="starr" />
                                            <img src={require("../assets/star.png")} alt="starr" />
                                            <img src={require("../assets/star.png")} alt="starr" />
                                            <img src={require("../assets/star.png")} alt="starr" />
                                        </div>
                                    </div>
                                </div>
                                <div className="mt-3">
                                    <p className="text-white mb-0">
                                        In publishing and graphic design, Lorem ipsum is a
                                        placeholder. In publishing and graphic design, Lorem ipsum is
                                        a placeholder In publishing and graphic design, Lore ipsum is
                                        a placeholder
                                    </p>
                                </div>
                            </div>
                        </div>
                        <div className="py-4 my-5 crd-main">
                            <div className="crd blue-bg p-sm-5 p-2 rounded-sm-5 rounded-4">
                                <div className=" d-flex ">
                                    <div> <img src={require("../assets/alex2.png")} alt="alex" className="img-fluid" /></div>
                                    <div className="d-flex flex-column mx-3">
                                        <p className="mb-0 text-white">Alex matrin</p>
                                        <div className="d-flex">
                                            <img src={require("../assets/star.png")} alt="starr" />
                                            <img src={require("../assets/star.png")} alt="starr" />
                                            <img src={require("../assets/star.png")} alt="starr" />
                                            <img src={require("../assets/star.png")} alt="starr" />
                                            <img src={require("../assets/star.png")} alt="starr" />
                                        </div>
                                    </div>
                                </div>
                                <div className="mt-3">
                                    <p className="text-white mb-0">
                                        In publishing and graphic design, Lorem ipsum is a
                                        placeholder. In publishing and graphic design, Lorem ipsum is
                                        a placeholder In publishing and graphic design, Lore ipsum is
                                        a placeholder
                                    </p>
                                </div>
                            </div>
                        </div>
                        <div className="py-4 my-5 crd-main">
                            <div className="crd blue-bg p-sm-5 p-2 rounded-sm-5 rounded-4">
                                <div className=" d-flex ">
                                    <div> <img src={require("../assets/alex2.png")} alt="alex" className="img-fluid" /></div>
                                    <div className="d-flex flex-column mx-3">
                                        <p className="mb-0 text-white">Alex matrin</p>
                                        <div className="d-flex">
                                            <img src={require("../assets/star.png")} alt="starr" />
                                            <img src={require("../assets/star.png")} alt="starr" />
                                            <img src={require("../assets/star.png")} alt="starr" />
                                            <img src={require("../assets/star.png")} alt="starr" />
                                            <img src={require("../assets/star.png")} alt="starr" />
                                        </div>
                                    </div>
                                </div>
                                <div className="mt-3">
                                    <p className="text-white mb-0">
                                        In publishing and graphic design, Lorem ipsum is a
                                        placeholder. In publishing and graphic design, Lorem ipsum is
                                        a placeholder In publishing and graphic design, Lore ipsum is
                                        a placeholder
                                    </p>
                                </div>
                            </div>
                        </div>
                        <div className="py-4 my-5 crd-main">
                            <div className="crd blue-bg p-sm-5 p-2 rounded-sm-5 rounded-4">
                                <div className=" d-flex ">
                                    <div> <img src={require("../assets/alex2.png")} alt="alex" className="img-fluid" /></div>
                                    <div className="d-flex flex-column mx-3">
                                        <p className="mb-0 text-white">Alex matrin</p>
                                        <div className="d-flex">
                                            <img src={require("../assets/star.png")} alt="starr" />
                                            <img src={require("../assets/star.png")} alt="starr" />
                                            <img src={require("../assets/star.png")} alt="starr" />
                                            <img src={require("../assets/star.png")} alt="starr" />
                                            <img src={require("../assets/star.png")} alt="starr" />
                                        </div>
                                    </div>
                                </div>
                                <div className="mt-3">
                                    <p className="text-white mb-0">
                                        In publishing and graphic design, Lorem ipsum is a
                                        placeholder. In publishing and graphic design, Lorem ipsum is
                                        a placeholder In publishing and graphic design, Lore ipsum is
                                        a placeholder
                                    </p>
                                </div>
                            </div>
                        </div>
                    </Slider></div>

            </div>
            {/* -----------------
          end || What Our Clients Say
  ----------------- */}
            {/* -----------------
      start || Contact Us
  ----------------- */}
            <div className="contact lightble-bg py-5">
                <div>
                    <h2 className="display-4 blue-clr text-center my-3 fw-bold">
                        Contact Us
                    </h2>
                </div>
                <div className="mycontainer mt-5">
                    <div className="row">
                        <div className="col-md-7 col-lg-8">
                            <form action="#">
                                <div className="row">
                                    <div className="col-12">
                                        <div className="d-flex justify-content-between m-2">
                                            <input
                                                className="form-control  fill-input me-2"
                                                type="text"
                                                placeholder="Name"
                                            />
                                            <input
                                                className="form-control  fill-input ms-2"
                                                type="email"
                                                placeholder="Your Email"
                                            />
                                        </div>
                                        <div className="m-2">
                                            <textarea
                                                name="Message"
                                                cols={30}
                                                rows={6}
                                                className="form-control  fill-input"
                                                placeholder="Message"
                                                defaultValue={""}
                                            />
                                        </div>
                                        <button
                                            type="submit"
                                            className="blue-bg mx-2 border-0 text-white px-4 py-2 rounded-3"
                                        >
                                            Send
                                        </button>
                                    </div>
                                </div>
                            </form>
                        </div>
                        <div className="col-lg-4 col-md-5 mt-4 mt-md-0 px-xl-5 text-center">
                            <img src={require("../assets/phone.png")} alt="phone" className="img-fluid" />
                        </div>
                    </div>
                </div>
            </div>
            {/* -----------------
          end || Contact Us
  ----------------- */}
        </>
    )

}

