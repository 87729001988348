import React, { useContext } from 'react';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import IconButton from '@mui/material/IconButton';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import DeleteIcon from '@mui/icons-material/Delete';
import FileCopyIcon from '@mui/icons-material/FileCopy';
import ArchiveIcon from '@mui/icons-material/Archive';

import {useNavigate } from 'react-router-dom'
import EditIcon from '@mui/icons-material/Edit';
import apiClient from '../api/apiClient';
import { async } from 'q';





 const QrPopup = ({ onEdit,handleArchive }) => {
  const navigate=useNavigate()
  const [anchorEl, setAnchorEl] = React.useState(null);

  const handleClick = (event) => {
    try {
      
      event.stopPropagation()
     
    } catch (error) {
      
    }
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

 
 

  const handleEdit = () => {
    onEdit()
    handleClose();
  };
  const handleArchiveClick = () => {

   handleArchive()

    handleClose();
  };

  return (
    <div>
      <IconButton onClick={handleClick} className=''>
        <MoreVertIcon  />
      </IconButton>
      <Menu
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={handleClose}
      >
        <MenuItem onClick={handleEdit}>
        <EditIcon/>
        Modify Content
        </MenuItem>
        <MenuItem  onClick={handleArchiveClick}>
        <ArchiveIcon/>
          Archive
        </MenuItem>
       
      </Menu>
    </div>
  );
};

export default QrPopup;
