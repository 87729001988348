import { Link } from 'react-router-dom'
import React,{useState} from 'react'
import apiClient from '../api/apiClient'
import {toast } from 'react-toastify';
import { useNavigate } from "react-router-dom";
import { login } from '../redux/authSlice';
import { useDispatch } from 'react-redux';
import Loader from '../components/Loader';
const Signup = () => {
  let initialState={
    "name":"",
    "email":"",
    "password":""
  }
  const [loading, setloading]=useState(false)
  const navigate = useNavigate();
  const dispatch=useDispatch()
  const [user,setUser]=useState(initialState)
  const handleChange=(key,value)=>{
    setUser({...user,[key]:value})
  }
  const handleSubmit=async(e)=>{
    e.preventDefault()
    setloading(true)
    const result=await apiClient.post("/auth/register",user)
    setloading(false)
    if(!result.ok) return  toast.error("Error");
    toast.success("Successfully Created")
    dispatch(login({token:result.data.token}))
    navigate("/admin/dashboard")
  }

  return (
    <div className="container-fluid">
    <section className="p-sm-4 py-3  px-md-4 px-2 d-flex flex-column">
      <div className="row main-h   mx-xl-5 mx-lg-4 mx-1 b-radius align-items-center">
        <div className="col-6 bg-right  p-5 px-lg-5 px-md-4 px-3 b-radius-left col-1-res">
          <div className="row d-flex align-items-end h-50">
            <div>
              
              <h6 className=" pt-2 fs-4 clr-btn fw-bold">
                Description Heading
              </h6>
              <p className="mb-0">
                Lorem ipsum dolor sit amet consectetur adipisicing elit.
                Dignissimos sint neque unde reiciendis nesciunt blanditiis,
                voluptatibus nobis minus voluptates distinctio aperiam fugiat
                iusto delectus ipsam.
              </p>
            </div>
          </div>
          <div className="row px-xl-5 px-lg-4 px-md-3 py-3 mt-2 d-flex align-items-end h-50 ">
            <img
              className="img-one object-contain"
              src={require("../assets/home-pic.png")}
              alt=""
            />
          </div>
        </div>
        <div className="col-6 mt-sm-0 mt-2 py-3 pt-5 px-lg-5 px-md-4 px-3 d-flex flex-column b-radius-right col-2-res">
          <div className="row">
            <div className="d-flex align-items-end h-60">
              <div className=" text-center">
              <p className="blue-m fs-3 fw-md mb-0">Register</p>
                <h6 className=" fs-2">Welcome to Our System</h6>
                <p className="px-xl-4 px-lg-2">
                  Lorem ipsum dolor sit amet consectetur adipisicing elit.
                  Quaerat deleniti, expedita maiores fugit eos ad!
                </p>
              </div>
            </div>
            <div className="col-xl-8 col-lg-10 col-md-11 col-11 mx-auto mt-0">
              <form onSubmit={handleSubmit}>
                <input
                required
                  type="text"
                  value={user.name}
                  onChange={(e)=>handleChange("name",e.target.value)}
                  className="form-control mt-3 border-0 txt p-2 px-4 p-clr"
                  placeholder="Name"
                />
                <input
                required
                  type="email"
                  value={user.email}
                  onChange={(e)=>handleChange("email",e.target.value)}
                  className="form-control border-0 mt-3 txt p-2 px-4 p-clr"
                  placeholder="Email"
                />
                <input
                required
                  type="password"
                  value={user.password}
                  onChange={(e)=>handleChange("password",e.target.value)}
                  className="form-control mt-3 border-0 txt p-2 px-4 p-clr"
                  placeholder="Password"
                />
                <div className="row mt-3 log-res">
                  <div className="col my-auto rem-res pe-0">
                    <input
                    required role="button" type="checkbox" id="remember-me" />
                    <label
                      className="p-clr ms-1"
                      role="button"
                      htmlFor="remember-me"
                    >
                      Remember Me
                    </label>
                  </div>
                  
                  <div className="col text-end log-btn">
                  {!loading?<button
                    to="/login"
                      type="submit"
                      className="btn ps-0  rounded-pill active-btn"
                    >
                      <span className="btn-inner rounded-pill">Sign up</span>
                    </button>: <div className=' d-flex justify-content-center loader-1'>
      <Loader />
    </div>}
                    {/* <a href="index.html" class="btn ps-0  rounded-pill active-btn" ><span class="btn-inner rounded-pill">Log In</span></a> */}
                  </div>
                </div>
              </form>
            </div>
          </div>
          <div className="text-center mt-auto">
            <p className="mb-0 text-dark">
              Already have an account?
              <Link to="/login" className=" text-decoration-none ">
                Login
              </Link>
            </p>
          </div>
        </div>
      </div>
    </section>
  </div>
  )
}

export default Signup
