

  import React, { useEffect, useState } from 'react'
import SocialMediaScan from '../components/SocialMediaScan'
import FeedbackScan from '../components/FeedbackScan'
import ImagesScan from '../components/ImagesScan'
import PdfScan from '../components/PdfScan'
import useApi from '../hooks/useApi.js'
import apiClient from '../api/apiClient'
import { useParams } from 'react-router-dom'
const ScanPreview = ({select,qrData}) => {
    const [data,setData]=useState()
const {id}=useParams()
    const {request,loading}=useApi(()=>apiClient.get("/qrcode/"+id))

    const fetchData=async ()=>{
        const result=await request()
        if(!result.ok) return
        setData(result.data.result)
    }
    useEffect(()=>{
fetchData()
    },[])
    // if(["Feedback","BulkUrl","Images","Pdf","SocialMedia"].includes(qrData.qr_info.type))
    if(loading||!data)
    return
console.log(data);
  return (
   <>
   {data?.qr_info?.type==="SocialMedia" &&
   <SocialMediaScan qrData={data}/>}
   {data?.qr_info?.type==="Feedback" &&
   <FeedbackScan qrData={data}/>}
   {data?.qr_info?.type==="Images" &&
   <ImagesScan qrData={data}/>}
   {data?.qr_info?.type==="Pdf" &&
   <PdfScan qrData={data}/>}
   
   </>
  )
}

export default ScanPreview