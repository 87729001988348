import * as React from 'react';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Divider from '@mui/material/Divider';
import Drawer from '@mui/material/Drawer';
import IconButton from '@mui/material/IconButton';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemText from '@mui/material/ListItemText';
import MenuIcon from '@mui/icons-material/Menu';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import { Link, NavLink, Outlet } from 'react-router-dom';
import { useLocation } from 'react-router-dom';
import { Avatar, Grid, ListItemIcon, Menu, MenuItem, Tooltip } from '@mui/material';
import PowerSettingsNewIcon from '@mui/icons-material/PowerSettingsNew';
import { useDispatch, useSelector } from 'react-redux';
import { logout } from '../redux/authSlice';
import { useNavigate } from 'react-router-dom';
import { useEffect } from 'react';
import { useState } from 'react';
import apiClient from '../api/apiClient';
import GridViewIcon from '@mui/icons-material/GridView';
import QrCodeIcon from '@mui/icons-material/QrCode';
import AddchartIcon from '@mui/icons-material/Addchart';
import ArchiveIcon from '@mui/icons-material/Archive';
import FolderIcon from '@mui/icons-material/Folder';
import TextSnippetIcon from '@mui/icons-material/TextSnippet';
import MuiDialog from './mui/MuiDialog';
import { CleanHands } from '@mui/icons-material';
const drawerWidth = 240;
const settings = [
  {
    button:'Profile',
    link:"/admin/profile",
}, 
  {
    button:'Billing',
    link:"/admin/billing",
}, 
 
];
function Navbar (props){ 
  const [mobileOpen, setMobileOpen] = React.useState(false);
  let navItems=[]
  const { window,type } = props;
  const {isLoggedIn,userType} = useSelector((state) => state.auth)
  // redux 
const dispatch = useDispatch();
const navigate = useNavigate();
const [show,setShow]=useState(false)


const [refresh,setRefresh]=useState(true)
const [user,setUser]=useState()
const fetchProfile=async()=>{
  const result=await apiClient.get("/auth/user-info")
  setUser(result)
  setRefresh(false)
}

console.log(user,"popo");
const location = useLocation();

  const handleDrawerToggle = () => {
    setMobileOpen((prevState) => !prevState);
  };
  useEffect(()=>{
    refresh &&fetchProfile()
  },[refresh])

  console.log(user?.data?.trial,"loik");
  console.log(user?.data,"loik");

   navItems = [
      { label: 'Dashboard', link: '/admin/dashboard',icon:<GridViewIcon/> },
      { label: 'Qrcode', link: '/admin/qrode',icon:<QrCodeIcon/> },
      { label: 'Analytics', link: '/admin/analytics' ,icon:<AddchartIcon/>},
      { label: 'Folders', link: '/admin/folders',icon:<FolderIcon/> },
      { label: 'Archive', link: '/admin/archive' ,icon:<ArchiveIcon/>},
      { label: 'Blogs', link: '/admin/blogWrite' ,icon:<TextSnippetIcon/>},
    ];
    const [anchorElUser, setAnchorElUser] = React.useState(null);
    const handleCloseUserMenu = () => {
      setAnchorElUser(null);
    };
    const handleOpenUserMenu = (event) => {
      setAnchorElUser(event.currentTarget);
    };
  const drawer = (
    <Box className=" h-100 bg-myblue" onClick={handleDrawerToggle} sx={{ textAlign: 'center' ,}}>
      {/* <Typography variant="h6" sx={{ my: 2 }}>
        MUI
      </Typography> */}
      <Box className="d-flex align-item-center px-1 py-2">
      <div className='d-xs-block d-sm-none ' style={{width:"9rem"}}>
          <h1>QR <span>Scanner</span></h1>
          </div>
      </Box>
      <Divider />
      <List>
        {navItems.map((value, i) => (
       <ListItem
       key={i}
       component={NavLink}
       to={value.link}
     
       sx={{
        // backgroundColor:'#4987E7',
        color: "#ffff",
        // "&.active": { backgroundColor: "#D2A78F", color: "#ffff",
        // "& .myIconClass, & .MuiTypography-root": {
        //   color: "#ffff"
        // } }
      }}
     >
      <ListItemIcon className="myIconClass" sx={{color:"#FFFFFF"}}>{value.icon}</ListItemIcon>
       <ListItemButton
         className="list-item"
        
        
       >
         <ListItemText
           primary={
             <Typography variant="body2" style={{ fontSize: 14,color:"#ffff" }}>
               {value.label}
             </Typography>
           }
         />
       </ListItemButton>
     </ListItem>
        ))}
      </List>
    </Box>
  );

  const container = window !== undefined ? () => window().document.body : undefined;
  const activeLinkStyle = {
    color: 'red', // Change this to the desired color
    /* Add other styles as needed */
  }; 
  return ( 
    <>
    <Box className="" >
      {/* <CssBaseline /> */}
      
      <AppBar className='bg-myblue mb-0' component="nav" 
      sx={{
        // backgroundColor: "#3E4755",
        height:"80px",
        width: {
          position: 'static',
          xs: '100%',
          md: '96%',
        },
      margin:{
        xs: 0,
        md: 3,
      },
       borderRadius:"20px"

      }}>
        <Toolbar className=' h-100 position-relative'>
         <IconButton
            color="inherit"
            aria-label="open drawer"
            edge="start"
            onClick={handleDrawerToggle}
            sx={{ mr: 2, display: { lg: 'none' } }}
          >
           <MenuIcon />
          </IconButton>
         
          <div className='d-none d-lg-block ' >
          <h3 className='text-dark pe-4 border-primary-subtle border-2 border-end'>QR <span className='text-white'>Scanner</span> </h3>
          </div>
          
          <Box className=" mx-auto"  sx={{ display: { xs: 'none', lg: 'flex' },justifyContent:"center",gap: 2 }}>
            {navItems.map((item, i) => (
              
              <NavLink className="" to={item.link} style={{ textDecoration: 'none' }} key={i}>
              <Button  
              className=''
                  variant='contained' 
                  key={item} 
                  
                  // startIcon={item.label === 'Dashboard' ? <GridViewIcon /> : null}
                  startIcon={item.icon }
                  sx={{
                    borderRadius:"6px",
                    // color: location.pathname === item.link ? '#000' : '#4987E7',
                    backgroundColor:location.pathname.includes(item.link) ? '#1B51A4ff' : '#4987E7',
                    // '&:hover': {
                    //   backgroundColor: location.pathname === item.link ? '#313942' : '#3E4755',
                    // },
                    // boxShadow: 'none',
                  }}
              >
                  {item.label}
              </Button>
          </NavLink>
          
            ))}
          </Box>

          <Box sx={{ flexGrow: 0 }}>
            <Tooltip title="Open settings">
              <IconButton onClick={handleOpenUserMenu} sx={{ p: 0 }}>
                <Avatar alt={user?.data.name} src={user?.data.profile} />
              </IconButton>
            </Tooltip>
            <Menu
              sx={{ mt: '45px' }}
              id="menu-appbar"
              anchorEl={anchorElUser}
              anchorOrigin={{
                vertical: 'top',
                horizontal: 'right',
              }}
              keepMounted
              transformOrigin={{
                vertical: 'top',
                horizontal: 'right',
              }}
              open={Boolean(anchorElUser)}
              onClose={handleCloseUserMenu}
            >
              {settings.map((setting) => (
                <>
                <MenuItem key={setting} onClick={handleCloseUserMenu}>
                  <Typography onClick={()=>navigate(`${setting.link}`)} textAlign="center">{setting.button}</Typography>
                </MenuItem>
                </>
              ))}
              <MenuItem  onClick={handleCloseUserMenu}>
                  <Typography onClick={()=>dispatch(logout())} textAlign="center">Logout</Typography>
                </MenuItem>
            </Menu>
          </Box>






             {/* <Box className="  d-flex w-40 justify-content-end   align-items-center gap-1 position-absolute " style={{right:"10px"}}>
            
            <PowerSettingsNewIcon className='me-2' onClick={()=>setShow(true)} sx={{ color: "#fff" }} />

              <Link className=''
               to={'/admin/profile'}
               >
                <Avatar alt="Remy Sharp" src={user?.data?.profile} />
              </Link>
            </Box> */}
            <MuiDialog 
            
            
            title={"Are you Sure want to Logout?"} 
            show={show}
            Buttons={
              ()=>(<>
              
                <Button onClick={() => setShow(false)}>Cancel</Button>
                <Button onClick={()=>dispatch(logout())} color="error" variant="contained">
                  Logout
                </Button>
              </>
              )
            }
            />
        </Toolbar>
      </AppBar>
     
      <Box component="nav" >
        <Drawer
          container={container}
          variant="temporary"
          open={mobileOpen}
          onClose={handleDrawerToggle}
          ModalProps={{
            keepMounted: true, // Better open performance on mobile.
            }}
          sx={{
            display: { xs: 'block', sm: 'none' },
            '& .MuiDrawer-paper': { boxSizing: 'border-box', width: drawerWidth },
          }}
        >
          {drawer}
        </Drawer>
        {(user?.data?.trial)&&

        <Grid container className='mt-0 d-flex align-items-center' sx={{
        backgroundColor: "#19325E",
        // height:"80px",
        width: {
          position: 'static',
          xs: '100%',
          md: '96%',
        },
      margin:{
        xs: 0,
        md: 3,
      },
      padding:"3px",
       borderRadius:"20px",
          justifyItems:'center'
      }}>
      <Grid item xs={8} className='' >
        <Grid container  >
       <Grid item xs={12} md={8} className='d-flex justify-content-md-center justify-content-start px-md-0 px-3' style={{color:"white"}}>{user?.data.trial_remaining_days}  days left in your trial</Grid>
       <Grid item xs={12} md={4} className='d-flex justify-content-md-end justify-content-start px-3' style={{color:"white"}}>Upgrade now to get access to all features</Grid>
       </Grid>
      </Grid>
      <Grid item xs={4} className='d-flex align-items-center' >
        <Button size='small' variant='contained' style={{backgroundColor:"white",color:"#3278E4"}} onClick={()=>navigate("/prices")}>Upgrade</Button>
      </Grid>
    </Grid>}
      </Box>
      
    </Box>
    
    <Box className='' sx={{marginTop:8, minHeight:'100vh'}}>
    <Outlet context={{type,setRefresh,user}} />
    </Box>
    
    </>
  );
}

export default Navbar;