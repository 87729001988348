import React,{useState,useEffect} from 'react'
import { Link, useParams,useNavigate } from 'react-router-dom'
import apiClient from '../api/apiClient'
import { downloadFile } from '../modules/dowloadHelpers'
import useApi from '../hooks/useApi'
import QrPopup from '../components/FieldEditDropdown'
import Loader from '../components/Loader'


const Archive = () => {
  const navigate=useNavigate()
  const [count, setCount] = React.useState();
  const [load, setload]=useState(false)
  const[qr, setQr]=useState()
  const [type,setType]=useState()
const {id}=useParams()
const fetchQr=async()=>{
  setload(true)
  const result=await apiClient.get(`/qrcode?archive=true`)
  setload(false)
  if(!result.ok) return 
  setCount(result.data.count)
  setQr(result.data.results)
}
const handleArchive=async(id)=>{
  
  const result=await apiClient.put(`/qrcode/${id}`,{archive:false})
 
  if(!result.ok) return 
  fetchQr()
}

useEffect(()=>{
  fetchQr()
},[])

function formatDateFromTimestamp(timestamp) {
  const date = new Date(timestamp);

  const year = date.getFullYear();
  const month = (date.getMonth() + 1).toString().padStart(2, '0');
  const day = date.getDate().toString().padStart(2, '0');

  return `${year}-${month}-${day}`;
}
  return (
    <section>
    <div className="container">



            <div className=" shadow bg-white rounded-4 py-3 px-4 mt-3">
            <div className="  ">
               {!load? <div className=" row folder-height2 pe-lg-5">
                  {qr?.map((item)=>
                  <div className=" col-lg-6 mb-3 " >
                    <div className="d-sm-flex align-items-end  justify-content-between light-blue2 rounded-4 p-3" style={{height:"250px"}}>
                      <div className="w-100  pe-3">
                        <div className=" d-flex border-bottom border-primary w-100">
                          {/* <div className="mt-2">
                            <img
                              src={require("../assets/profile-img.png")}
                              alt=""
                              className="member-img"
                            />
                          </div> */}
                          <div className="ps-2 d-flex w-100 justify-content-between">
                            <p className=" blue-button-cl">{item.name}</p>
                            <QrPopup 
                         onEdit={()=>navigate(`/admin/modifyContent/${item._id}`)}
                         handleArchive={()=>handleArchive(item._id)}
                         
                         />
                            {/* <p>Member@gmail.com</p> */}
                          </div>
                        </div>
                        <div className="mt-3">
                          <p className="mb-0 blue-button-cl">Creation Date:</p>
                        </div>
                        <div className="d-flex justify-content-between flex-wrap">
                          <div>
                            <p className="mb-0">{formatDateFromTimestamp(item.createdAt)}</p>
                          </div>
                          {/* <div className="">
                            <button className="blue-button-bg text-white px-3 py-2 rounded-3 border-0">
                              Save to folder
                            </button>
                          </div> */}
                        </div>
                        <div className="d-flex align-items-center mt-3">
                          <div className="me-3">
                            <i className="bi bi-three-dots dots-bg p-2 rounded-3 blue-button-cl" />
                          </div>
                          <div className="w-100">
                              <button onClick={()=>navigate(`/admin/modify/${item._id}`)} className="dots-bg px-3 py-2 border-0 rounded-3 w-100 blue-button-cl">
                              Modify
                            </button>
                          </div>
                        </div>
                      </div>
                      <div className="mt-sm-0 mt-3">
                        <div className="text-center" >
                          <img
                            src={item.image}
                            alt=""
                            className="group-img object-fit-fill"
                          />
                        </div>
                        <div className="mt-2">
                          <button onClick={()=>downloadFile(item.image)} className="rounded-3 border-blue blue-button-cl bg-transparent px-2 py-1 w-100">Download</button>
                          {/* <select
                            name=""
                            id=""
                            className="rounded-3 border-blue blue-button-cl bg-transparent px-2 py-1 w-100"
                          >
                            <option value="">Download</option>
                          </select> */}
                        </div>
                      </div>
                    </div>
                  </div>)}
                 {!count && <div className='m-auto'>

          
            <div className='d-flex justify-content-center aling-items-center' > 
            
                   <div style={{width:"20rem"}}>

                <img src={require("../assets/WhatsApp Image 2023-11-04 at 3.35.38 PM.jpeg")} alt="" />
                   </div>


             

            </div>
           <h3 className='text-center my-4'>Archived codes will be visible here</h3>
           <h6 className='text-center'>Use archive to store the codes you no longer need.</h6>


            </div>}
                 
                </div>: <div className='d-flex justify-content-center'>
      <Loader />
    </div>}
              </div>
            </div>
    </div>
  </section>
  )
}

export default Archive