import { createSlice } from '@reduxjs/toolkit';
import {  useNavigate } from "react-router-dom";

const initialState = {
  token:  "",
  userType:  null,
  isLoggedIn: false
};

export const counterSlice = createSlice({
  name: "auth",
  initialState,
  reducers: {
  
    login: (state, {payload}) => {
      state.token = payload.token;
      state.userType=payload.userType
      state.isLoggedIn=true

    },
    logout: (state) => {
        state.token = null;
        state.isLoggedIn=false
      }
 


  }
});

// Action creators are generated for each case reducer function
export const {
login,
logout
} = counterSlice.actions;

export default counterSlice.reducer;
