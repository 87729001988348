import React from 'react'
const StepOne = ({ nextStep, setSelected }) => {
  return (
    <div className="row me-md-5 ">
      <div className="col-sm-4 col-6 mb-3">
        <div
          onClick={() => {
            nextStep()
            setSelected("URL")
          }}
          id="tab-0"
          className="light-grey-bg rounded-3 p-3 d-flex align-items-center hover-bg"
        >
          <img
            src={require("../assets/URL.png")}
            alt=""
            className="url-img"
          />
          <p className="mb-0 ps-2">URL</p>
        </div>
      </div>
      <div className="col-sm-4 col-6 mb-3">
        <div onClick={() => {
          nextStep()
          setSelected("TEXT")
        }}
          id="tab-text"
          className="light-grey-bg rounded-3 p-3 d-flex align-items-center hover-bg"
        >
          <img
            src={require("../assets/text-2.png")}
            alt=""
            className="url-img"
          />
          <p className="mb-0 ps-2">Text</p>
        </div>
      </div>
      <div className="col-sm-4 col-6 mb-3">
        <div onClick={() => {
          nextStep()
          setSelected("EMAIL")
        }}
          id="email-text"
          className="light-grey-bg rounded-3 p-3 d-flex align-items-center hover-bg"
        >
          <img
            src={require("../assets/email.png")}
            alt=""
            className="url-img"
          />
          <p className="mb-0 ps-2">Email</p>
        </div>
      </div>
      <div className="col-sm-4 col-6 mb-3">
        <div
          onClick={() => {
            nextStep()
            setSelected("SMS")
          }}
          id="sms-text"
          className="light-grey-bg rounded-3 p-3 d-flex align-items-center hover-bg"
        >
          <img
            src={require("../assets/SMS.png")}
            alt=""
            className="url-img"
          />
          <p className="mb-0 ps-2">SMS</p>
        </div>
      </div>
      <div className="col-sm-4 col-6 mb-3">
        <div
          onClick={() => {
            nextStep()
            setSelected("WHATSAPP")
          }}
          id="whatsapp-text"
          className="light-grey-bg rounded-3 p-3 d-flex align-items-center hover-bg"
        >
          <img
            src={require("../assets/whatsapp.png")}
            alt=""
            className="url-img"
          />
          <p className="mb-0 ps-2">Whatsapp</p>
        </div>
      </div>
      <div className="col-sm-4 col-6 mb-3">
        <div
          onClick={() => {
            nextStep()
            setSelected("VCARD")
          }}
          id="vCard-text"
          className="light-grey-bg rounded-3 p-3 d-flex align-items-center hover-bg"
        >
          <img
            src={require("../assets/v-card.png")}
            alt=""
            className="url-img"
          />
          <p className="mb-0 ps-2">vCard</p>
        </div>
      </div>
      <div className="col-sm-4 col-6 mb-3">
        <div
          onClick={() => {
            nextStep()
            setSelected("SOCIAL")
          }}
          id="smedia-text"
          className="light-grey-bg rounded-3 p-3 d-flex align-items-center hover-bg"
        >
          <img
            src={require("../assets/social-media.png")}
            alt=""
            className="url-img"
          />
          <p className="mb-0 ps-2">Social Media</p>
        </div>
      </div>
      <div className="col-sm-4 col-6 mb-3">
        <div
          onClick={() => {
            nextStep()
            setSelected("FEED")
          }}
          id="feedback-text"
          className="light-grey-bg rounded-3 p-3 d-flex align-items-center hover-bg"
        >
          <img
            src={require("../assets/Feedback.png")}
            alt=""
            className="url-img"
          />
          <p className="mb-0 ps-2">Feedback</p>
        </div>
      </div>
      <div className="col-sm-4 col-6 mb-3">
        <div
          onClick={() => {
            nextStep()
            setSelected("BULK")
          }}
          id="burl-text"
          className="light-grey-bg rounded-3 p-3 d-flex align-items-center hover-bg"
        >
          <img
            src={require("../assets/URL.png")}
            alt=""
            className="url-img"
          />
          <p className="mb-0 ps-2">Bulk URL</p>
        </div>
      </div>
      <div className="col-sm-4 col-6 mb-3">
        <div
          onClick={() => {
            nextStep()
            setSelected("IMAGES")
          }}
          id="image-text"
          className="light-grey-bg rounded-3 p-3 d-flex align-items-center hover-bg"
        >
          <img
            src={require("../assets/images.png")}
            alt=""
            className="url-img"
          />
          <p className="mb-0 ps-2">Images</p>
        </div>
      </div>
      <div className="col-sm-4 col-6 mb-3">
        <div
          onClick={() => {
            nextStep()
            setSelected("PDF")
          }}
          id="pdf-text"
          className="light-grey-bg rounded-3 p-3 d-flex align-items-center hover-bg"
        >
          <img
            src={require("../assets/pdf-2.png")}
            alt=""
            className="url-img"
          />
          <p className="mb-0 ps-2">PDF</p>
        </div>
      </div>
    </div>
  )
}

export default StepOne
