import React from 'react'
import { useState } from 'react'
import QrModel from './QrModel'
import { useEffect } from 'react'
import apiClient from '../../api/apiClient'
import { toast } from 'react-toastify'

const Email = ({qrInfo,step,setStep,setQrData,...props}) => {
  let initialState={
   "email":qrInfo?.qr_info.info.to||"",
   "subject":qrInfo?.qr_info.info.subject||"",
   "text":qrInfo?.qr_info.info.text||"",
   "name":qrInfo?.name || "",
  "folder":qrInfo?.folder ||"",
    "qr_info":{"type":"Email"}
  }
const [eemail,setEemail]=useState(initialState)
const [folder,setFolder]=useState()
const [modalShow, setModalShow] = React.useState(false);
const handleChange=(key,value)=>{
  setEemail({...eemail,[key]:value})
}
const handleSubmit=async(e)=>{
  e.preventDefault()
  console.log(eemail);
  eemail.qr_info.info={text:eemail.text,subject:eemail.subject,to:eemail.email}
  // const result=await apiClient.post("/qrcode",eemail)
  // if(!result.ok) return toast.error("Error")
  //   console.log(result);
}
const fetchData=async()=>{
  const result =await apiClient.get("/folder")
setFolder(result.data.results)
}
useEffect(() => {
  fetchData()
 }, [])
 
 const [errors, setErrors] = useState({});
const validateForm = () => {
  const newErrors = {};
  if (!eemail.email) {
    newErrors.email = 'email is required';
  }
  if (!eemail.subject) {
    newErrors.subject = 'subject is required';
  }
  if (!eemail.text) {
    newErrors.text = 'text is required';
  }
  if (!eemail.name) {
    newErrors.name = 'name is required';
  }
  if (!eemail.folder) {
    newErrors.folder = 'folder is required';
  }
  if (!eemail.qr_info) {
    newErrors.qr_info = 'qr_info is required';
  }
  setErrors(newErrors);

  
  return Object.keys(newErrors).length === 0;
};

 const nextStep=()=>{
  const formIsValid = validateForm();
  if (formIsValid) {
  
  eemail.qr_info.info={text:eemail.text,subject:eemail.subject,to:eemail.email}
    const {folder,type,name,qr_info}=eemail
    if(props?.handleSubmit)
    return props?.handleSubmit({folder,type,name,qr_info})
    setQrData({folder,type,name,qr_info})
  setStep(step+1)}
}



  return (


    <div className="container">
      <div className="row">
        <div className="col-11">
          <form onSubmit={handleSubmit} id="myForm">
            <div>
              <h5 className="fw-normal">Add Content</h5>
              <div>
                <label htmlFor="" className="font-13">
                  Send to*
                </label>
                <input
                  type="email"
                  value={eemail.email}
        onChange={(e)=>handleChange("email",e.target.value)}
                  name=""
                  id=""
                  placeholder="email@email.com"
                  className="form-control shadow-none light-grey-bg border-0"
                />
                <span  className="text-danger font-12">
         {errors.email}
       </span>
              </div>
              <div className="mt-3">
                <label htmlFor="" className="font-13">
                  Email Subject
                </label>
                <input
                  type=""
                  value={eemail.subject}
                  onChange={(e)=>handleChange("subject",e.target.value)}
                  name=""
                  id=""
                  placeholder="Example- Resume, Feedback etc"
                  className="form-control shadow-none light-grey-bg border-0"
                />
                <span  className="text-danger font-12">
         {errors.subject}
       </span>
              </div>
              <div className="mt-3">
                <span className="font-12">Text (upto 300 characters)*</span>
                <textarea
                  name=""
                  id=""
                  value={eemail.text}
                  onChange={(e)=>handleChange("text",e.target.value)}
                  cols={30}
                  rows={4}
                  className="form-control shadow-none light-grey-bg border-0"
                  placeholder="Enter your text here"
                  defaultValue={""}
                />
                <span  className="text-danger font-12">
         {errors.text}
       </span>
              </div>
            </div>
            <h5 className="mt-3">Internal notes</h5>
            <div>
              <label htmlFor="for-name" className="form-label font-13 ps-2 mb-0">
                Name your code
              </label>
              <input
                type="text"
                value={eemail.name}
                onChange={(e)=>handleChange("name",e.target.value)}
                name=""
                id="for-name"
                className="form-control shadow-none light-grey-bg border-0"
                placeholder="Enter name"
              />
              <span  className="text-danger font-12">
         {errors.name}
       </span>
            </div>
            <div className="mt-3">
              
              <div className="dropdown">
      <label htmlFor="" className="form-label font-13 mb-0 ps-2">
        Folder
      </label>
       
      </div>
              <div className="dropdown">
                <button
                  className="form-select shadow-none light-grey-bg border-0 text-start grey-clr"
                  type="button"
                  data-bs-toggle="dropdown"
                  aria-expanded="false"
                >
                  {!eemail?.folder?"Folder": folder?.find(x=>x._id===eemail.folder).name}
                </button>
                <ul className="dropdown-menu w-100 p-3">
                  <li onClick={() => setModalShow(true)}
                    className="blue-button-cl pointer"
                    style={{borderBottom:"1px solid black"}}
                    // data-bs-toggle="modal"
                    // data-bs-target="#folderModal"
                  >
                    <i className="bi bi-plus blue-button-cl fs-5" /> Add
                    new Folder
                  </li>
                  {folder?.map((item)=>
                   <li style={{cursor:"pointer"}} onClick={()=>setEemail({...eemail,folder:item._id})} >{item.name}</li>
                   )}
                </ul>
                     
                <span  className="text-danger font-12">
         {errors.folder}
       </span>

              </div>
            </div>
            {step&&
      <div className="d-flex align-items-center mt-3">
              <div>
                <button onClick={() => setStep(step - 1)} className="light-blue2 px-5 py-2 blue-button-cl border-0 rounded-3">
                  Back
                </button>
              </div>
              <div className="ms-3">
                <button onClick={nextStep} className="px-5 py-2 text-white blue-button-bg border-0 rounded-3">
                  Next
                </button>
              </div>
            </div>}
      {qrInfo&&
      <div className="d-flex align-items-center mt-3">
             
              <div className="ms-3">
                <button onClick={nextStep} className="px-5 py-2 text-white blue-button-bg border-0 rounded-3">
                 Save
                </button>
              </div>
            </div>}
          </form>
          {modalShow&&  <QrModel  show={modalShow}
       handleChange={handleChange}
       value={eemail}
       fetchData={fetchData}
        onHide={() => setModalShow(false)}  />}
        </div>
      </div>
    </div>

  )
}

export default Email
