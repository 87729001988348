import React from 'react'
import SocialMediaScan from '../components/SocialMediaScan'
import FeedbackScan from '../components/FeedbackScan'
import ImagesScan from '../components/ImagesScan'
import PdfScan from '../components/PdfScan'

const Preview = ({select,qrData}) => {
  console.log(qrData,"kioplop2");
    const data=["Url", "Whatsapp","SMS","Email","Text","VCard","Feedback","BulkUrl","Images","Pdf","SocialMedia"]

  return (
   <>
   {select==="SOCIAL" &&
   <SocialMediaScan qrData={qrData}/>}
   {select==="FEED" &&
   <FeedbackScan qrData={qrData}/>}
   {select==="IMAGES" &&
   <ImagesScan qrData={qrData}/>}
   {select==="PDF" &&
   <PdfScan qrData={qrData}/>}
   
   </>
  )
}

export default Preview