import { Toolbar } from "@mui/material";
import { Container } from "@mui/system";
import React, { useEffect, useState } from "react";
import { isMobile } from "react-device-detect";
import { useLocation, useParams } from "react-router-dom";

export default function () {


    const location = useLocation();

    const blog=location.state


  return (
 <Container>
 
    {location.pathname.search("/admin/blog/detail")==-1&&<><Toolbar/>
    <Toolbar/>
    </>}

 <h1>{blog.title}</h1>

 <div className='my-2' style={{fontSize: '13px'}}>
        <div className="my-auto ">
          <div className=''><h6 className="mb-0">{blog.author}</h6></div>
        </div>
        <div className="my-auto ">
          <p className="mb-0">{blog.created_at}</p>
        </div>
      </div>
 <img  src={blog.image} alt="Card image" style={{width: isMobile?'100%':'30%'}} />

 <div style={{marginTop:40}}>
 <div dangerouslySetInnerHTML={{ __html: blog.content}} />
 </div>

      </Container>
  );
}
