import React, { useEffect, useState } from 'react'
import QrModel from './QrModel'
import apiClient from '../../api/apiClient'
import { toast } from 'react-toastify'

const Url = ({qrInfo,step,setStep,setQrData,...props}) => {
  console.log(qrInfo);
let initialState={
  "url":qrInfo?.qr_info.info.url||"",
  "name":qrInfo?.name || "",
  "folder":qrInfo?.folder ||"",
  "type":qrInfo?.type ||"",
  "qr_info":{"type":"Url"}
}


const [url,setUrl]=useState(initialState)
const [folder,setFolder]=useState()
const [modalShow, setModalShow] = React.useState(false);
const fetchData=async()=>{
  const result =await apiClient.get("/folder")
setFolder(result.data.results)
}
const handleChange=(key,value)=>{
  setUrl({...url,[key]:value})
}
const handleSubmit=()=>{
  console.log(url);
  url.qr_info.info={url:url.url}
// const result=await apiClient.post("/qrcode",url)
// if(!result.ok) return toast.error("Error")
//   console.log(result);
}
useEffect(() => {
 fetchData()
}, [])

const [errors, setErrors] = useState({});
const validateForm = () => {
  const newErrors = {};
  if (!url.url) {
    newErrors.url = 'URL is required';
  }
  if (!url.name) {
    newErrors.name = 'Name is required';
  }
  if (!url.folder) {
    newErrors.folder = 'Folder is required';
  }
  if (!url.type) {
    newErrors.type = 'Type is required';
  }
  setErrors(newErrors);

  
  return Object.keys(newErrors).length === 0;
};
// useEffect(()=>{
//   // if(!props?.handleSubmit)
//   // return
//   url.qr_info.info={url:url.url}
//     const {folder,type,name,qr_info}=url
//     setQrData({folder,type,name,qr_info})
// },[url])
const nextStep=()=>{
  const formIsValid = validateForm();
  if (formIsValid) {
    url.qr_info.info={url:url.url}
    const {folder,type,name,qr_info}=url
    if(props?.handleSubmit)
    return props?.handleSubmit({folder,type,name,qr_info})
    setQrData({folder,type,name,qr_info})
    step && setStep(step+1)}
}
  return (


    <div className="container">
      <div className="row">
        <div className="col-11">
          <form onSubmit={handleSubmit} id="myForm">
            <div>
              <label
                htmlFor="website-input"
                className="form-label w-100"
              >
                Which web address would you like this code to lead to?
              </label>
              <span className="text-danger font-12">
                URL of your website or a file*
              </span>
              <input
                id="website-input"
                type="text"
                value={url.url}
                onChange={(e)=>handleChange("url",e.target.value)}
                className="form-control shadow-none light-grey-bg border-0"
                placeholder="http://website.com"
              />
              {/* <span id="error-message" className="text-danger font-12">
                required
              </span> */}
            </div>
              <span  className="text-danger font-12">
         {errors.url}
       </span>
            <h5 className="mt-3">Internal notes</h5>
            <div>
              <label
                htmlFor="for-name"
                className="form-label font-13 ps-2 mb-0"
              >
                Name your code
              </label>
              <input
                type="text"
                value={url.name}
                onChange={(e)=>handleChange("name",e.target.value)}
                name=""
                id="for-name"
                className="form-control shadow-none light-grey-bg border-0"
                placeholder="Enter name"
              />
            </div>
            <span  className="text-danger font-12">
         {errors.name}
       </span>
            <div className="mt-3">
              
              <div className="dropdown">
      <label htmlFor="" className="form-label font-13 mb-0 ps-2">
        Folder
      </label>
       
      </div>
              <div className="dropdown">
                <button
                  className="form-select shadow-none light-grey-bg border-0 text-start grey-clr"
                  type="button"
                  data-bs-toggle="dropdown"
                  aria-expanded="false"
                >
                  {!url?.folder?"Folder": folder?.find(x=>x._id===url.folder).name}
                </button>
                <ul className="dropdown-menu w-100 p-3">
                  <li onClick={() => setModalShow(true)}
                    className="blue-button-cl pointer"
                    style={{borderBottom:"1px solid black"}}
                    // data-bs-toggle="modal"
                    // data-bs-target="#folderModal"
                  >
                    <i className="bi bi-plus blue-button-cl fs-5" /> Add
                    new Folder
                  </li>
                  {folder?.map((item)=>
                   <li style={{cursor:"pointer"}} onClick={()=>setUrl({...url,folder:item._id})} >{item.name}</li>
                   )}
                </ul>
                     


              </div>
            </div>
            <span  className="text-danger font-12">
         {errors.folder}
       </span>
            <div className="mt-3">
              <label
                htmlFor=""
                className="form-label font-13 mb-0 ps-2"
              >
                Type
              </label>
              <select
                name=""
                value={url.type}
                onChange={(e)=>handleChange("type",e.target.value)}
                id=""
                className="form-select shadow-none light-grey-bg border-0 text-start grey-clr"
              >
                <option value="s">Select</option>
                <option value="Dynamic">Dynamic</option>
                <option value="Static">Static</option>
              </select>
              <span  className="text-danger font-12">
         {errors.type}
       </span>
            </div>
           
          </form>
       {modalShow&&  <QrModel  show={modalShow}
       handleChange={handleChange}
       value={url}
       fetchData={fetchData}
        onHide={() => setModalShow(false)}  />}
        </div>
      </div>
      {step&&
      <div className="d-flex align-items-center mt-3">
              <div>
                <button onClick={() => setStep(step - 1)} className="light-blue2 px-5 py-2 blue-button-cl border-0 rounded-3">
                  Back
                </button>
              </div>
              <div className="ms-3">
                <button onClick={nextStep} className="px-5 py-2 text-white blue-button-bg border-0 rounded-3">
                  Next
                </button>
              </div>
            </div>}
      {qrInfo&&
      <div className="d-flex align-items-center mt-3">
             
              <div className="ms-3">
                <button onClick={nextStep} className="px-5 py-2 text-white blue-button-bg border-0 rounded-3">
                 Save
                </button>
              </div>
            </div>}
    </div>

  )
}
export default Url
