import { Link } from 'react-router-dom'
import React from 'react'

const ResetPassword = () => {
  return (
    <div className="container-fluid">
    <section className="p-sm-4 py-3  px-md-4 px-2 d-flex flex-column">
      <div className="row main-h   mx-xl-5 mx-lg-4 mx-1 b-radius align-items-center">
        <div className="col-6 bg-right  p-5 px-lg-5 px-md-4 px-3 b-radius-left col-1-res">
          <div className="row d-flex align-items-end h-50">
            <div>
              <h6 className=" pt-2 fs-4 clr-btn fw-bold">
                Description Heading
              </h6>
              <p className="mb-0">
                Lorem ipsum dolor sit amet consectetur adipisicing elit.
                Dignissimos sint neque unde reiciendis nesciunt blanditiis,
                voluptatibus nobis minus voluptates distinctio aperiam fugiat
                iusto delectus ipsam.
              </p>
            </div>
          </div>
          <div className="row px-xl-5 px-lg-4 px-md-3 py-3 mt-2 d-flex align-items-end h-50">
            <img
              className="img-one object-contain"
              src={require("../assets/home-pic.png")}
              alt=""
            />
          </div>
        </div>
        <div className="col-6 mt-sm-0 mt-2 py-3 pt-5 px-lg-5 px-md-4 px-3 d-flex flex-column b-radius-right col-2-res">
          <div className="row">
            <div className="d-flex align-items-end h-60">
              <div className=" text-center">
                <h6 className=" fs-2">Welcome to Our System</h6>
                <p className="px-xl-4 px-lg-2">
                  Lorem ipsum dolor sit amet consectetur adipisicing elit.
                  Quaerat deleniti, expedita maiores fugit eos ad!
                </p>
              </div>
            </div>
            <div className="col-xl-8 col-lg-10 col-md-11 col-11 mx-auto mt-4">
              <form action="login.html" method="">
                <h6 className="fs-4">Reset Password</h6>
                <input
                  type="password"
                  className="form-control border-0 txt p-2 px-4 p-clr"
                  placeholder="New Password"
                />
                <input
                  type="password"
                  className="form-control mt-3 border-0 txt p-2 px-4 p-clr"
                  placeholder="Re-Enter New Password"
                />
                <div className="row mt-3 ">
                  <div className="col ">
                    <Link to="/login" className="btn ps-0  rounded-pill active-btn">
                      <span className="btn-inner rounded-pill">Reset</span>
                    </Link>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </section>
  </div>
  )
}

export default ResetPassword
