import React, { useState } from "react";
import { IoIosArrowDown } from "react-icons/io"
import {
  Outlet,
  Link,
  NavLink,
  useLocation,
} from "react-router-dom";
import LoginIcon from '@mui/icons-material/Login';
import MedicalServicesIcon from '@mui/icons-material/MedicalServices';
import { Login, MedicalServices, MedicalServicesOutlined } from "@mui/icons-material";
import { BsTwitter } from 'react-icons/bs';
import { BsFacebook } from 'react-icons/bs';
import { BsLinkedin } from 'react-icons/bs';
import { AiFillInstagram } from 'react-icons/ai';
import { FaPaperPlane } from 'react-icons/fa'
import { useSelector } from "react-redux";

export const Layout = () => {
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const {isLoggedIn}=useSelector(state=>state.auth)
  // Access specific query parameters
  const method = searchParams.get('method');

  const [role, setRole] = useState('defaultRole');

  const handleButtonClick = (newRole) => {
    setRole(newRole);
  };
  console.log(location.pathname === "/about" );

  return (
    <div className="layout zoom">

      <nav className="navbar navbar-expand-lg navbar-light navbg  mx-3 pb-0 mt-4 rounded-top-4 bg-lightblue position-relative" style={{  zIndex: "999", height: "100%" }}>
        <div className="d-flex align-items-center justify-content-between   shrink2  py-4">
          <Link className=" ms-5" to="/home">
            <div className="" style={{ width: "7rem" }}>
              <img width={"100%"} src={require("../assets/QR Scanner.png")} alt="" />
            </div>
          </Link>

          <button
            className=" navbar-toggler navbar-togl-btn btn-light position-absolute"
            type="button"
            data-bs-toggle="collapse"
            data-bs-target="#navbarSupportedContent"
            aria-controls="navbarSupportedContent"
            aria-expanded="false"
            aria-label="Toggle navigation"
            style={{ backgroundColor: 'white', right: "10px" }}
          >
            <span className="navbar-toggler-icon"></span>
          </button>


         
        </div>
        <div className="h-100 collapse navbar-collapse" id="navbarSupportedContent">
            <ul className="navbar-nav mx-auto mb-2 mb-lg-0">
              <li className="nav-item">
                <NavLink
                  style={{fontWeight: location.pathname === "/about" ? 'bold' : '', padding: "9px 30px", fontSize: '16px', maxWidth: "fit-content" }}
                  className="nav-link text-dark"
                  exact
                  
                  activeClassName="active"
                  end="/"
                  to="/about"
                  title="About Us"
                >
                  About Us
                </NavLink>
              </li>
              <li className="nav-item">
                <NavLink
                  style={{ fontWeight: location.pathname === "/prices" ? 'bold' : '',padding: "9px 30px", fontSize: '16px', maxWidth: "fit-content" }}
                  className="nav-link text-dark"
                  exact
                  activeClassName="active"
                  end="/"
                  to="/prices"
                  title="Prices"
                >
                  Prices
                </NavLink>
              </li>

              <li className="nav-item">
                <NavLink
                  className="nav-link white_space text-dark"
                  exact
                  activeClassName="active"
                  to="/contact"
                  title="Contact Us"
                  style={{fontWeight: location.pathname === "/contact" ? 'bold' : '', fontSize: '16px', padding: "9px 30px", maxWidth: "fit-content" }}

                >
                  Contact Us
                </NavLink>
              </li>

              <li className="nav-item">
                <NavLink
                  className="nav-link text-dark"
                  style={{ fontWeight: location.pathname === "/services" ? 'bold' : '',fontSize: '16px', padding: "9px 30px", maxWidth: "fit-content" }}
                  exact
                  activeClassName="active"
                  to="/services"
                  title="Services"
                >
                  Services
                </NavLink>
              </li>
              <li className="nav-item">
                <NavLink
                  className="nav-link text-dark"
                  style={{fontWeight: location.pathname === "/blogs" ? 'bold' : '', fontSize: '16px', padding: "9px 30px", maxWidth: "fit-content" }}
                  exact
                  activeClassName="active"
                  to="/blogs"
                  title="blogs"
                >
                  Blogs
                </NavLink>
              </li>
            </ul>
            <div className=" justify-content-end mx-2 me-lg-5">
              <div className="col-lg-12 d-flex justify-content-between align-items-center mb-3 mb-lg-0 text-white gap-2 mx-auto">
{!isLoggedIn?<>

                <Link to="/login" state={{ from: "dr" }}>
                  <button
                    className="bg-transparent border-0 text-decoration-none txt-myblue me-2 me-lg-4 fs-6 bs-opacity mt-2 mt-lg-0">
                    
                    Login
                  </button>
                </Link>


                <Link to="/signup">

                  <button
                    className="bg-blue border-0 w-auto text-center text-decoration-none  bg-myblue text-white py-2 px-4 rounded-4 fs-6 bs-opacity mt-3 mt-lg-0"
                  >
                   
                    Sign Up
                  </button>
                </Link>
</>:
<Link to="/admin/dashboard" state={{ from: "dr" }}>
                  <button
                    className="bg-blue border-0 w-auto text-center text-decoration-none  bg-myblue text-white py-2 px-4 rounded-4 fs-6 bs-opacity mt-3 mt-lg-0">
                    
                    Dashboard
                  </button>
                </Link>}


              </div>
            </div>
          </div>
      </nav>

      <main className='' style={{ height: "auto" }}>
        <div className=''>
          <Outlet />
        </div>
      </main>


      <footer
        class=" text-dark p-md-4 p-2 mt-5 blue-bg"
      >
        <div class=" container py-3 ">

          <div className="d-flex justify-content-between">
            <div style={{ width: "10rem" }}>
              <img className="img-fluid" src={require("../assets/QR Scanner.png")} alt="" />
            </div>
            <div>
              <button className="btn btn-light px-3">
                Sign Up
              </button>
            </div>
          </div>
          <hr className="text-white" />



          <div class=" row py-3">

            <div class=" col-sm-12 col-md-6 col-lg-4 col-xl-4">
              <div>
                <p p class="text-upper_case mt-2 text-white fs_14px opacity_custom" >
                  In publishing and graphic design, Lorem ipsum is a placeholder text commonly.
                </p>
              </div>
              <div class="d-flex social">
                <a href="#"><img src={require("../assets/ftwitter.png")} class="bs-opacity" alt="twitter" /></a>
                <a href=""><img src={require("../assets/ffb.png")} class="bs-opacity" alt="fb" /></a>
                <a href=""><img src={require("../assets/fin.png")} class="bs-opacity" alt="in" /></a>
                <a href=""><img src={require("../assets/finsta.png")} class="bs-opacity" alt="insta" /></a>
              </div>

              {/* <div class=" d-flex gap-3">
                  <div>
                  <BsTwitter/>
                  </div>
                  <div>
                    <BsFacebook/>
                  </div>
                  <div >
                    <AiFillInstagram/>
                  </div>
                  <div>
                    <BsLinkedin/>
                    </div>
             </div> */}
              <p class="text-white my-3">© 2023 all rights reserved</p>
              {/* <div>
                <div className="text-white text-upper_case mt-3 fs_14px">
                  © 2022 all rights reserved developed and maintain by{" "}
                  <a href="https://www.fabtechsol.com/" target="_blank">
                    Fabtechsol
                  </a>
                </div>
              </div> */}
            </div>


            <div class=" col-12 col-lg-8  mt-4 mt-sm-4 mt-md-0 mt-lg-0 mt-xl-0">
              <div className="row ">

                <div className="col-6 col-md-4  style-none">
                  <h4 class="mb-2 text-white " title="Services">
                    Pages
                  </h4>
                  <Link to={"/jobs"} className="decoration-none " style={{ textDecoration: "none" }}>
                    <p className="mb-1 text-white fs_14px opacity_custom" title="Job Search">
                      Reviews
                    </p>
                  </Link>
                  <Link className="decoration-none" style={{ textDecoration: "none" }}>
                    <p className="mb-1 text-white fs_14px opacity_custom" title="Travel">
                      Bookmarks
                    </p>
                  </Link>
                  <Link className="decoration-none" style={{ textDecoration: "none" }}>
                    <p className="mb-1 text-white fs_14px opacity_custom" title="Per Diem">
                      Bookings
                    </p>
                  </Link>
                  <Link className="decoration-none" style={{ textDecoration: "none" }}>
                    <p className="mb-1 text-white fs_14px opacity_custom" title="Placement">
                      Contact
                    </p>
                  </Link>
                </div>

                <div className=" col-6 col-md-4  style-none">
                  <h4 class="mb-2 text-white " title="Resources">
                    Community
                  </h4>
                  <Link to={"/jobs"} className="decoration-none text-white" style={{ textDecoration: "none" }}>
                    <p className="mb-1  text-white fs_14px opacity_custom" title="Job Search">
                      12345 Little Lonsdale St, Melbourne

                    </p>
                  </Link>
                  <Link className="decoration-none text-white" style={{ textDecoration: "none" }}>
                    <p className="mb-1 text-white fs_14px opacity_custom" title="Clients">
                      Phone: (123) 123-456
                    </p>
                  </Link>
                </div>

                <div className=" col-md-4   style-none">
                  {/* <div class="col-12  col-md-6 col-lg-3 d-flex flex-column ">
                    <div class="test">
                      <input type="text" name="" id="" />
                        {/* <input type="text" placeholder="Subscribe" class="form-control"/> */}
                  {/* <button type="submit" class="border-0 bg-transparent">
                        <i class="mb-0 ms-2 fs-5 blue-clr fa-solid fa-paper-plane"></i>
                        </button> */}
                  {/* </div>

                    <a href="tel:+923182322323" class="d-flex align-items-center justify-content-start my-4" style={{textDecoration:'none'}}>
                        <img src="../assets/phone-wifi.png" alt="phone" class="whatsapp"/>
                        <div class="mx-2  ">
                            <p class="mb-0 white_space text-white">+92 318 232 232 3</p>
                            <p class="mb-0 white_space text-white">Call to Expert</p>
                        </div>
                    </a>

                </div>  */}


                  <div class="col-12  d-flex flex-column ">
                    <div
                      class="position-relative d-flex justify-content-between align-items-center bg-white border-0 rounded-3  px-3 py-2">
                      <input type="text" placeholder="Subscribe" class=" mb-0 w-100 border-0 fs-5 blue-clr sub-btn" />
                      <button type="submit" class="border-0 bg-transparent position-absolute" style={{ right: "10px" }}>
                        <FaPaperPlane />
                      </button>
                    </div>
                    <a href="tel:+923182322323" class="d-flex align-items-center justify-content-start my-4" style={{ textDecoration: "none" }}>
                      <img src={require("../assets/fphone.png")} alt="phone" class="whatsapp" />
                      <div class="mx-2">
                        <p class="mb-0 text-white">+92 318 232 232 3</p>
                        <p class="mb-0 text-white">Call to Expert</p>
                      </div>
                    </a>
                  </div>
                </div>

              </div>
            </div>

          </div>
        </div>
        <div className="w-100 text-center ">
          <p className="text-white">All rights reserved Maintained & Developed by <Link className="text-dark" to="https://fabtechsol.com/">Fabtechsol</Link></p>
        </div>
      </footer>

    </div>
  )
}
