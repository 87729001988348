import React from 'react'
import QrModel from './QrModel'
import { useState } from 'react';
import { useEffect } from 'react';
import apiClient from '../../api/apiClient';
import { toast } from 'react-toastify';

const Sms = ({qrInfo,step,setStep,setQrData,...props}) => {

  let initialState={
    "phone":qrInfo?.qr_info.info.rec_phone||"",
    "text":qrInfo?.qr_info.info.text||"",
    "name":qrInfo?.name || "",
  "folder":qrInfo?.folder ||"",
    "qr_info":{"type":"SMS"},
  }
  const [modalShow, setModalShow] = React.useState(false);
  const [sms,setSms]=useState(initialState)
  const handleChange=(key,value)=>{
    setSms({...sms,[key]:value})
  }
  const handleSubmit=async(e)=>{
    e.preventDefault()
    console.log(sms);
    sms.qr_info.info={text:sms.text,rec_phone:sms.phone}
   
  }
  const [folder,setFolder]=useState()
  const fetchData=async()=>{
    const result =await apiClient.get("/folder")
  setFolder(result.data.results)
  }
  useEffect(() => {
    fetchData()
   }, [])

   const [errors, setErrors] = useState({});
const validateForm = () => {
  const newErrors = {};
  if (!sms.phone) {
    newErrors.phone = 'phone is required';
  }
  if (!sms.text) {
    newErrors.text = 'text is required';
  }
  if (!sms.name) {
    newErrors.name = 'name is required';
  }
  if (!sms.folder) {
    newErrors.folder = 'folder is required';
  }
  setErrors(newErrors);

  
  return Object.keys(newErrors).length === 0;
};
   const nextStep=()=>{
    const formIsValid = validateForm();
  if (formIsValid) {
    sms.qr_info.info={text:sms.text,rec_phone:sms.phone}
    const {folder,type,name,qr_info}=sms
    if(props?.handleSubmit)
      return props?.handleSubmit({folder,name,qr_info})
    setQrData({folder,type,name,qr_info})
  setStep(step+1)}}
   
  return (


    <div className="container">
      <div className="row">
        <div className="col-11">
          <form onSubmit={handleSubmit} id="myForm">
            <div>
              <h5 className="fw-normal">Add Content</h5>
              <div>
                <label htmlFor="" className="font-13">
                  Receipt phone number*
                </label>
                <input
                  type="tel"
                  name=""
                  value={sms.phone}
                  onChange={(e)=>handleChange("phone",e.target.value)}
                  id=""
                  placeholder="Enter Phone number"
                  className="form-control shadow-none light-grey-bg border-0"
                />
                 <span  className="text-danger font-12">
         {errors.phone}
       </span>
              </div>
              <div className="mt-3">
                <span className="font-12">Text (upto 300 characters)*</span>
                <textarea
                  name=""
                  value={sms.text}
                  onChange={(e)=>handleChange("text",e.target.value)}
                  id=""
                  cols={30}
                  rows={4}
                  className="form-control shadow-none light-grey-bg border-0"
                  placeholder="Enter your text here"
                  defaultValue={""}
                />
                 <span  className="text-danger font-12">
         {errors.text}
       </span>
              </div>
            </div>
            <h5 className="mt-3">Internal notes</h5>
            <div>
              <label htmlFor="for-name" className="form-label font-13 ps-2 mb-0">
                Name your code
              </label>
              <input
                type="text"
                value={sms.name}
                onChange={(e)=>handleChange("name",e.target.value)}
                name=""
                id="for-name"
                className="form-control shadow-none light-grey-bg border-0"
                placeholder="Enter name"
              />
               <span  className="text-danger font-12">
         {errors.name}
       </span>
            </div>
            <div className="mt-3">
              
              <div className="dropdown">
      <label htmlFor="" className="form-label font-13 mb-0 ps-2">
        Folder
      </label>
       
      </div>
              <div className="dropdown">
                <button
                  className="form-select shadow-none light-grey-bg border-0 text-start grey-clr"
                  type="button"
                  data-bs-toggle="dropdown"
                  aria-expanded="false"
                >
                  {!sms?.folder?"Folder": folder?.find(x=>x._id===sms.folder).name}
                </button>
                <ul className="dropdown-menu w-100 p-3">
                  <li onClick={() => setModalShow(true)}
                    className="blue-button-cl pointer"
                    style={{borderBottom:"1px solid black"}}
                    // data-bs-toggle="modal"
                    // data-bs-target="#folderModal"
                  >
                    <i className="bi bi-plus blue-button-cl fs-5" /> Add
                    new Folder
                  </li>
                  {folder?.map((item)=>
                   <li style={{cursor:"pointer"}} onClick={()=>setSms({...sms,folder:item._id})} >{item.name}</li>
                   )}
                </ul>
                     
                <span  className="text-danger font-12">
         {errors.folder}
       </span>

              </div>
            </div>
            {step&&
      <div className="d-flex align-items-center mt-3">
              <div>
                <button onClick={() => setStep(step - 1)} className="light-blue2 px-5 py-2 blue-button-cl border-0 rounded-3">
                  Back
                </button>
              </div>
              <div className="ms-3">
                <button onClick={nextStep} className="px-5 py-2 text-white blue-button-bg border-0 rounded-3">
                  Next
                </button>
              </div>
            </div>}
      {qrInfo&&
      <div className="d-flex align-items-center mt-3">
             
              <div className="ms-3">
                <button onClick={nextStep} className="px-5 py-2 text-white blue-button-bg border-0 rounded-3">
                 Save
                </button>
              </div>
            </div>}
          </form>
          {modalShow&&  <QrModel  show={modalShow}
       handleChange={handleChange}
       value={sms}
       fetchData={fetchData}
        onHide={() => setModalShow(false)}  />}
        </div>
      </div>
    </div>

  )
}

export default Sms
