import React, { useEffect, useState } from 'react'
import apiClient from '../api/apiClient'


 const Dashboard = () =>{
  const [data,setData]=useState()
  useEffect(()=>{
    fetchScanned()

  },[])
  const fetchScanned=async()=>{
    const result=await apiClient.get(`/scan/analytics/counts?fields=qr_count,scan_count`)
    if(!result.ok) return
    console.log(result,"aqwww");
    setData(result.data.result)

  }

  return(
  <>
    <meta charSet="UTF-8" />
   
    <section>
      <div className="container">
        <div className="row">
          <div className="col-lg-10 col-sm-11 mx-auto">
            <h1 className="fw-normal txt-myblue mb-0">Dashboard</h1>
            <div className="bg-white rounded-4 shadow-sm py-3 mt-4 px-sm-4 px-2 height-max">
              <div className="grey-light p-sm-3 p-2 rounded-4 d-flex flex-sm-row flex-column align-items-center justify-content-sm-between mb-3">
                <div className="text-center">
                  <img
                    src={require("../assets/scanner.png")}
                    alt=""
                    className="scanner-img"
                  />
                </div>
                <div className="d-flex align-items-center justify-content-between mt-sm-0 mt-3">
                  <div>
                    <p className="mb-0 font-18 text-end text-uppercase">
                      QR CODES GENERATED
                    </p>
                  </div>
                  <div>
                    <h4 className="mb-0 ms-4 fw-normal">{data?.qr_count||0}</h4>
                  </div>
                </div>
                <div className=""></div>
              </div>
              <div className="grey-light p-sm-3 p-2 rounded-4 d-flex flex-sm-row flex-column align-items-center justify-content-sm-between mb-3">
                <div className="text-center">
                  <img
                    src={require("../assets/scanner.png")}
                    alt=""
                    className="scanner-img"
                  />
                </div>
                <div className="d-flex align-items-center justify-content-between mt-sm-0 mt-3">
                  <div>
                    <p className="mb-0 font-18 text-end text-uppercase">
                      VISITS CODES GENERATED
                    </p>
                  </div>
                  <div>
                    <h4 className="mb-0 ms-4 fw-normal">{data?.scan_count||0}</h4>
                  </div>
                </div>
                <div className=""></div>
              </div>
           
            </div>
          </div>
        </div>
      </div>
    </section>
    {/* Bootsrtap js */}
    {/* jquery */}
    {/* slick slider */}
    {/* Js */}
  </>
)}


export default Dashboard