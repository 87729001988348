import React from 'react';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import IconButton from '@mui/material/IconButton';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import DeleteIcon from '@mui/icons-material/Delete';
import FileCopyIcon from '@mui/icons-material/FileCopy';
import RestoreFromTrashIcon from '@mui/icons-material/RestoreFromTrash';
import BookIcon from '@mui/icons-material/Book';
import EditIcon from '@mui/icons-material/Edit';



export const UserMenuOptions = ({handleDelete,handleTrash,blogDetail,blogEdit,item, onDelete, onSelectDefaultAgency, onEdit,role }) => {
  const [anchorEl, setAnchorEl] = React.useState(null);

  const handleClick = (event) => {
    try {
      
      event.stopPropagation()
    } catch (error) {
      
    }
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleDeleted = () => {
    handleDelete(item)
    handleClose();
  };

  const handleDefault = () => {
    onSelectDefaultAgency();
    handleClose();
  };

  const handleEdit = () => {
    blogEdit(item);
    handleClose();
  };
  const handleDetail = () => {
    blogDetail(item);
    handleClose();
  };
  const handleTrashe = () => {
    handleTrash(item._id);
    handleClose();
  };

  return (
    <div>
      <IconButton onClick={handleClick}>
        <MoreVertIcon  />
      </IconButton>
      <Menu
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={handleClose}
      >
        <MenuItem onClick={handleDeleted}>
          <DeleteIcon />
          Delete
        </MenuItem>
        <MenuItem onClick={handleEdit}>
          <EditIcon />
          Edit
        </MenuItem>
        <MenuItem onClick={handleDetail}>
          <BookIcon />
          Detail
        </MenuItem>
        <MenuItem onClick={handleTrashe}>
          <RestoreFromTrashIcon />
          Trash
        </MenuItem>
      </Menu>
    </div>
  );
};

