import * as React from 'react';
import { useState } from 'react';
import { DateRangePicker,afterToday } from 'rsuite';
export default function Calender({endDate,startDate,setStartDate,setEndDate}) {
    
    function formatDate(date) {
        const day = date.getDate().toString().padStart(2, '0');
        const month = (date.getMonth() + 1).toString().padStart(2, '0');
        const year = date.getFullYear();
        // return `${day}/${month}/${year}`;
        return `${year}-${month}-${day}`;
      }
  
    const handleDateChange = (selectedDates) => {
      const [start, end] = selectedDates;
     
      setStartDate(formatDate(start));
      setEndDate(formatDate(end));
    };
    const today = new Date();

    // Function to disable dates after today
    const disabledAfterToday = date => {
      return date > today;
    };
    console.log(startDate);
  return (
    <>
    <DateRangePicker
     selected={startDate ? [startDate, endDate] : []}
     shouldDisableDate={disabledAfterToday}
     onChange={handleDateChange} />
  </>
  );
}