import React, { useEffect, useState } from 'react'
import apiClient from '../api/apiClient'
import { useNavigate } from 'react-router-dom'
import { Avatar } from '@mui/material'
import { useSelector } from 'react-redux'
const OurBlogs = () => {
  const {isLoggedIn} = useSelector((state) => state.auth)
const navigate=useNavigate()
  const [blog,setBlog]=useState()
  const fetchData=async()=>{
    const result =await apiClient.get("/blog")
    if(!result.ok) return 
    setBlog(result.data.results)
  }
  useEffect(() => {
   
    fetchData()
  }, [])
  const handleNavigate=()=>{
    if(isLoggedIn)
    {
      navigate("/admin/createqr")
    }
    else{
      navigate("/login")
    }
  }


  return (
    <>
    <div className="top bg-lightblue mb-3 mx-3 rounded-bottom-4 py-2">
     
      {/* -----------------
    start|| hero
----------------- */}
      <div className="hero-sec">
        <div className="mycontainer">
          <div className="row d-flex justify-content-center py-5 my-5">
            <div className="col-12 col-md-8 col-lg-6 text-center">
              <div>
                <h1 className="fw-bolder display-3">
                  <span className=" blue-clr">Our Blogs</span>
                </h1>
                <p>
                  Elevate your business, captivate your audience, and connect
                  with simplicity. At InstantQRcodes.com, we empower you to
                  create, customize, and track QR codes like never before.
                </p>
              </div>
              <div className="mt-5">
                <a
                 style={{cursor:"pointer"}}
                 onClick={()=>handleNavigate()}
                  className="bg-blue w-auto text-center text-decoration-none px-3 bg-myblue text-white py-3 px-4 rounded-4 fs-6 bs-opacity mt-3 mt-md-0"
                >
                  <span className="me-3">Create QR code</span>
                  <i className="fa-solid fa-arrow-right" />
                </a>
              </div>
              <div className="blogs-img mt-5">
                <img src={require("../assets/barcode.png")} alt="" />
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* -----------------
    end|| hero 
----------------- */}
    </div>
    {/* -----------------
    start || Our Blogs
----------------- */}
    <div className="blogs py-4">
      <div className="mycontainer mt-5 my-sm-5">
        <div className="row d-flex justify-content-between">
          {blog?.map((item)=>
          <div className=" col-md-6 my-3 col-lg-4">
            <div className="card mx-2  p-0 border-0 " onClick={()=>navigate('/blogs-detail/'+item._id,{state:item})}>
              <img
             style={{height:"300px",objectFit:"cover"}}
                className="card-img-top w-100 img-fluid"
                src={item.image}
                alt="Card image cap"
              />
              <div className="card-body">
                <h5>
                  {item.title}
                </h5>
                <p className="card-text">
                 {item.description}
                </p>
              </div>
              <div className="d-flex justify-content-between align-items-center py-3">
                <div className="d-flex justify-content-start align-items-center ps-3 pe-1">
                <Avatar alt={item.created_by.name} src="/static/images/avatar/1.jpg" />
                  <p className="ms-2 small mb-0 fw-md">{item.created_by.name}</p>
                </div>
                <div>
                  <p className="me-3 mb-0 small">{item.createdAt ||"9-sep-2023"}</p>
                </div>
              </div>
            </div>
          </div>)}
          {/* <div className="col-md-6 my-3 col-lg-4">
            <div className="card mx-2  p-0 border-0 ">
              <img
                className="card-img-top w-100 img-fluid"
                src={require("../assets/card2.png")}
                alt="Card image cap"
              />
              <div className="card-body">
                <h5>
                  In publishing and graphic design, Lorem ipsum is a placeholder
                </h5>
                <p className="card-text">
                  In publishing and graphic design, Lorem ipsum is a
                  placeholder. In publishing and graphic design, Lorem ipsum is
                  a placeholder
                </p>
              </div>
              <div className="d-flex justify-content-between align-items-center py-3">
                <div className="d-flex justify-content-start align-items-center ps-3 pe-1">
                  <img src={require("../assets/alex.png")} alt="alex" />
                  <p className="ms-2 small mb-0 fw-md">Alex matrin</p>
                </div>
                <div>
                  <p className="me-3 mb-0 small">9-September-2023</p>
                </div>
              </div>
            </div>
          </div>
          <div className="col-md-6 my-3 col-lg-4">
            <div className="card mx-2  p-0 border-0 ">
              <img
                className="card-img-top w-100 img-fluid"
                src={require("../assets/card3.png")}
                alt="Card image cap"
              />
              <div className="card-body">
                <h5>
                  In publishing and graphic design, Lorem ipsum is a placeholder
                </h5>
                <p className="card-text">
                  In publishing and graphic design, Lorem ipsum is a
                  placeholder. In publishing and graphic design, Lorem ipsum is
                  a placeholder
                </p>
              </div>
              <div className="d-flex justify-content-between align-items-center py-3">
                <div className="d-flex justify-content-start align-items-center ps-3 pe-1">
                  <img src={require("../assets/alex.png")} alt="alex" />
                  <p className="ms-2 small mb-0 fw-md">Alex matrin</p>
                </div>
                <div>
                  <p className="me-3 mb-0 small">9-September-2023</p>
                </div>
              </div>
            </div>
          </div>
          <div className="col-md-6 my-3 col-lg-4">
            <div className="card mx-2  p-0 border-0 ">
              <img
                className="card-img-top w-100 img-fluid"
                src={require("../assets/card1.png")}
                alt="Card image cap"
              />
              <div className="card-body">
                <h5>
                  In publishing and graphic design, Lorem ipsum is a placeholder
                </h5>
                <p className="card-text">
                  In publishing and graphic design, Lorem ipsum is a
                  placeholder. In publishing and graphic design, Lorem ipsum is
                  a placeholder
                </p>
              </div>
              <div className="d-flex justify-content-between align-items-center py-3">
                <div className="d-flex justify-content-start align-items-center ps-3 pe-1">
                  <img src={require("../assets/alex.png")} alt="alex" />
                  <p className="ms-2 small mb-0 fw-md">Alex matrin</p>
                </div>
                <div>
                  <p className="me-3 mb-0 small">9-September-2023</p>
                </div>
              </div>
            </div>
          </div>
          <div className="col-md-6 my-3 col-lg-4">
            <div className="card mx-2  p-0 border-0 ">
              <img
                className="card-img-top w-100 img-fluid"
                src={require("../assets/card2.png")}
                alt="Card image cap"
              />
              <div className="card-body">
                <h5>
                  In publishing and graphic design, Lorem ipsum is a placeholder
                </h5>
                <p className="card-text">
                  In publishing and graphic design, Lorem ipsum is a
                  placeholder. In publishing and graphic design, Lorem ipsum is
                  a placeholder
                </p>
              </div>
              <div className="d-flex justify-content-between align-items-center py-3">
                <div className="d-flex justify-content-start align-items-center ps-3 pe-1">
                  <img src={require("../assets/alex.png")} alt="alex" />
                  <p className="ms-2 small mb-0 fw-md">Alex matrin</p>
                </div>
                <div>
                  <p className="me-3 mb-0 small">9-September-2023</p>
                </div>
              </div>
            </div>
          </div>
          <div className="col-md-6 my-3 col-lg-4">
            <div className="card mx-2  p-0 border-0 ">
              <img
                className="card-img-top w-100 img-fluid"
                src={require("../assets/card3.png")}
                alt="Card image cap"
              />
              <div className="card-body">
                <h5>
                  In publishing and graphic design, Lorem ipsum is a placeholder
                </h5>
                <p className="card-text">
                  In publishing and graphic design, Lorem ipsum is a
                  placeholder. In publishing and graphic design, Lorem ipsum is
                  a placeholder
                </p>
              </div>
              <div className="d-flex justify-content-between align-items-center py-3">
                <div className="d-flex justify-content-start align-items-center ps-3 pe-1">
                  <img src={require("../assets/alex.png")} alt="alex" />
                  <p className="ms-2 small mb-0 fw-md">Alex matrin</p>
                </div>
                <div>
                  <p className="me-3 mb-0 small">9-September-2023</p>
                </div>
              </div>
            </div>
          </div> */}
        </div>
      </div>
      <ul className="d-flex justify-content-center list-unstyled mb-5 pb-5">
        <li className="page-item mx-1">
          <a
            className="page-link2 text-decoration-none bg-myblue rounded-circle small text-white"
            href="#"
          >
            1
          </a>
        </li>
        <li className="page-item mx-1">
          <a
            className="page-link2 text-decoration-none blue-clr rounded-circle small "
            href="#"
          >
            2
          </a>
        </li>
        <li className="page-item mx-1">
          <a
            className="page-link2 text-decoration-none blue-clr rounded-circle small "
            href="#"
          >
            3
          </a>
        </li>
        <li className="page-item mx-1">
          <a
            className="page-link2 text-decoration-none blue-clr rounded-circle small "
            href="#"
          >
            4
          </a>
        </li>
        <li className="page-item mx-1">
          {" "}
          <a
            className="page-link2 text-decoration-none blue-clr rounded-circle small "
            href="#"
            aria-label="Next"
          >
            <span aria-hidden="true">»</span>
          </a>
        </li>
      </ul>
    </div>
  </>
  )
}

export default OurBlogs
