import React, { useEffect, useState } from 'react'
import ToggleButton from '@mui/material/ToggleButton';
import ToggleButtonGroup from '@mui/material/ToggleButtonGroup';
import apiClient from '../api/apiClient';
import FolderIcon from '@mui/icons-material/Folder';
 import image from "../assets/frames/frame_2.svg"
 import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import { Link, useParams } from 'react-router-dom';
import Calender from '../components/mui/Calender';
import { LineChart, Line, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer, PieChart, Pie, Cell, Label } from 'recharts';
import Box from '@mui/material/Box';
import { Grid, Paper } from '@mui/material';
import { styled } from '@mui/material/styles';
import LinearProgress, { linearProgressClasses } from '@mui/material/LinearProgress';
import Loader from '../components/Loader';

const BorderLinearProgress = styled(LinearProgress)(({ theme }) => ({
  height: 10,
  borderRadius: 5,
  [`&.${linearProgressClasses.colorPrimary}`]: {
    backgroundColor: theme.palette.grey[theme.palette.mode === 'light' ? 200 : 800],
  },
  [`& .${linearProgressClasses.bar}`]: {
    borderRadius: 5,
    backgroundColor: theme.palette.mode === 'light' ? '#1a90ff' : '#308fe8',
  },
}));

const colors = ["#314A77", "#33FF57", "#3357FF"];
export const data = [
  ["Operating System", "Visit"],
  ["Android", 11],
  ["Apple", 4],
  
  
];

export const options = {
  title: "operating system",
  colors: ['#314A77', '#3278E4'],
  pieHole: 0.4,
  is3D: false,
};


const SingleQrAnalytics = () => {
  const [alignment, setAlignment] = React.useState('Qr');
  const [dataTable, setDataTable] = useState('');
  const [load, setLoad] = useState(false);
  const [select, setSelect] = useState('a');
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const [plateform, setPlateForm] = useState();
  const [alignment1, setAlignment1] = React.useState('city');
  const {id}=useParams()

  const handleChange3 = (event, newAlignment) => {
    setAlignment1(newAlignment);
  };

  const handleChange = (event, newAlignment) => {
    setAlignment(newAlignment);
  };
const [analytics,setAnalytics]=useState()
  const fetchData=async()=>{
    setLoad(true)
    const result=await apiClient.get(`/scan/analytics/?qr=${id}&period_type=${dataTable}&start_date=${startDate}&end_date=${endDate}`)
    setLoad(false)
    if(!result.ok) return
    console.log(result,"aqwww");
    setAnalytics(result.data.result)
    const transformedData = result.data.result.platforms?.map(item => ({
      name: item.name,
      value: item.percentage , // Replace "percentage" with "value"
    }));
    setPlateForm(transformedData)

  }
  useEffect(()=>{
    fetchData()
  },[dataTable,startDate,endDate])
 

  const handleChange1 = (event, newAlignment) => {
    console.log(newAlignment);
    setDataTable(newAlignment);
  };
  const toggleDate=()=>{
    setEndDate("")
    setEndDate("")
  }
  
  const [selectedValue, setSelectedValue] = useState('');
  
  function findMaxPercentageObject(data) {
    let maxPercentage = -1;
    let maxPercentageObject = null;
  
    data.forEach(obj => {
      console.log(obj,"mmmmmmmmmmmmmmmmmmmmmmmmm");
      if (obj.value > maxPercentage) {
        maxPercentage = obj.value;
        maxPercentageObject = obj;
      }
    });
  
    return maxPercentageObject;
  }
  let maxPercentageObject=""
  if(plateform)
  { maxPercentageObject = findMaxPercentageObject(plateform)
  console.log(maxPercentageObject,"lol");}
console.log(analytics?.cities[0],"zxc");
  return (
    
      <>{!load?
    <section className="px-xl-4">
      <div className="container-fluid px-md-5">
        <h2 className="blue-button-cl fw-normal ps-4">Analytics</h2>
        <div className="row ">
          <div className="col-xl-4 col-lg-6 col-sm-8 ps-md-4">
            {/* <div className="shadow p-2 light-blue2 rounded-3 d-flex justify-content-between">
              <div>
                <button className="blue-button-cl bl-bg px-4 py-2 border-0 rounded-3">
                  All Time
                </button>
              </div>
              <div>
                <button className="blue-button-cl bg-transparent px-4 py-2 border-0 rounded-3">
                  Year
                </button>
              </div>
              <div>
                <button className="blue-button-cl bg-transparent px-4 py-2 border-0 rounded-3">
                  Month
                </button>
              </div>
              <div>
              
                <div className="dropdown">
                  <button
                    className="btn bg-transparent dropdown-toggle"
                    type="button"
                    data-bs-toggle="dropdown"
                    aria-expanded="false"
                  >
                    <i className="bi bi-calendar blue-button-cl" />
                  </button>
                  <ul className="dropdown-menu">
                    <li></li>
                  </ul>
                </div>
              </div>
            </div> */}

            <ToggleButtonGroup
              className="shadow p-2 light-blue2 rounded-3 d-flex justify-content-between"
              color="primary"
              value={alignment}
              exclusive
              onChange={handleChange1}
              aria-label="Platform"
            >
              <ToggleButton
                className="blue-button-cl bl-bg px-4 py-2 border-0 rounded-3"
                onClick={() => {
                  toggleDate();
                  setSelect("a");
                }}
                style={{ backgroundColor: select === "a" && "#B8CFF6" }}
                value=""
              >
                All Time
              </ToggleButton>
              <ToggleButton
                className="blue-button-cl bl-bg px-4 py-2 border-0 rounded-3"
                onClick={() => {
                  toggleDate();
                  setSelect("b");
                }}
                style={{ backgroundColor: select === "b" && "#B8CFF6" }}
                value="yearly"
              >
                Year
              </ToggleButton>
              <ToggleButton
                className="blue-button-cl bl-bg px-4 py-2 border-0 rounded-3"
                onClick={() => {
                  toggleDate();
                  setSelect("c");
                }}
                style={{ backgroundColor: select === "c" && "#B8CFF6" }}
                value="monthly"
              >
                Month
              </ToggleButton>
              {/* <ToggleButton  className=" blue-button-cl bl-bg px-4 py-2 border-0 rounded-3 dropdown" value="calender"><ArrowDropDownIcon/></ToggleButton> */}
              {/* <div className="dropdown"> */}
              <ToggleButton
                className="blue-button-cl bl-bg px-4 py-2 border-0 rounded-3 dropdown-toggle"
                type="button"
                value=""
                onClick={() => {
                  setSelect("d");
                }}
                style={{ backgroundColor: select === "d" && "#B8CFF6" }}
                data-bs-toggle="dropdown"
                aria-expanded="false"
              >
                <i className="bi bi-calendar blue-button-cl" />
              </ToggleButton>
              <ul className="dropdown-menu">
                <Calender
                  startDate={startDate}
                  endDate={endDate}
                  setStartDate={setStartDate}
                  setEndDate={setEndDate}
                />
              </ul>
              {/* </div> */}
            </ToggleButtonGroup>
          </div>
        </div>
        <div className="row mt-4">
          <div className="col-xl-3 mt-xl-0 mt-3">
            <div className=" shadow bg-white p-5 rounded-4">
              <img
                src={analytics?.qr_scan?.qr?.image}
                style={{ maxHeight: 500, width: "100%", objectFit: "contain" }}
              />
              <div style={{ }} className='mt-3'>
                <div            className="rounded-pill px-3 py-1"
                    style={{ backgroundColor: "#c3ffff",  width: 'min-content', // Adjust the width to be the minimum necessary
                    display: 'inline-block', }}>
                  <h6
                    className="mb-0  "
                  >
                    {analytics?.qr_scan.qr.qr_info.type}
                  </h6>
                </div>
                <h6 className="mb-0 bean mt-2">
                  {" "}
                  CreatedAt: {analytics?.qr_scan.qr.createdAt.split("T")[0]}
                </h6>
                <div className=' mt-3 p-3' style={{border:' 1px solid #F1F1F1',borderRadius:"5px"}}>
                <h6 className="mb-2 text-center " style={{borderBottom:' 1px solid #F1F1F1'}}> {analytics?.qr_scan.qr.name}</h6>
                <div className="row">
                  <div className="col-6">
                    <p>Total Scan</p>
                    <h2 className="mb-0  ">{analytics?.analytics?.total}</h2>
                  </div>
                  <div className="col-6">
                    <p>Unique Scans</p>
                    <h2 className="mb-0  ">{analytics?.analytics?.u_count}</h2>
                  </div>
                </div>
                </div>
              </div>
            </div>
          </div>
          <div className="col-xl-6">
            <div className="shadow rounded-4   p-3  mt-3">
              <p className="blue-button-cl ps-4">Scans by month</p>

              <div className="" style={{ width: "100%", height: "500px" }}>
                <ChartComp data={analytics?.graph_data} type={dataTable} />
              </div>
            </div>
            <Grid container mt={4} className="p-3 " component={Paper}>
              <Grid
                item
                xs={12}
                className=" d-flex justify-content-between align-items-center"
              >
                <div>
                  <h6>Scan by location</h6>
                </div>
                <div>
                  <ToggleButtonGroup
                    color="primary"
                    value={alignment}
                    exclusive
                    onChange={handleChange3}
                    aria-label="Platform"
                  >
                    <ToggleButton
                      onClick={() => setSelect("a")}
                      style={{ backgroundColor: select === "a" && "#B8CFF6" }}
                      value="city"
                    >
                      City
                    </ToggleButton>
                    <ToggleButton
                      onClick={() => setSelect("x")}
                      style={{ backgroundColor: select === "x" && "#B8CFF6" }}
                      value="country"
                    >
                      Country
                    </ToggleButton>
                  </ToggleButtonGroup>
                </div>
              </Grid>
              {alignment1 == "city" ? (
                <Grid item xs={12} className="mt-2">
                  <Grid container className="d-flex gap-2">
                    <Grid
                      item
                      xs={12}
                      className="p-3"
                      md={4}
                      style={{ border: "1px solid grey", borderRadius: "20px" }}
                    >
                      <div>
                        <h6>Most popular location</h6>
                      </div>
                      {analytics?.cities[0]?.popular.map((city) => (
                        <div className="d-flex justify-content-between px-3">
                          <h6>{city.name}</h6>
                          <h6 className='fw-bold px-2 py-3' style={{backgroundColor:"#EBF1FC",color:"#1A90FF",borderRadius:"7px" }}>
                            {city.percentage}%
                          </h6>
                        </div>
                      ))}
                    </Grid>
                    <Grid
                      item
                      xs={12}
                      className="p-3"
                      md={6}
                      style={{ border: "1px solid grey", borderRadius: "20px" }}
                    >
                      {analytics?.cities[0]?.all.map((city, index) => (
                        <div className="d-flex justify-content-between align-items-center px-3">
                          <h6 className="w-50 ">
                            {" "}
                            <span style={{ marginRight: "5px" }}>
                              {index + 1}{" "}
                            </span>
                            {city.name}
                          </h6>
                          <Box sx={{ width: "100%" }} >
                            <BorderLinearProgress
                           style={{height:'16px',}}
                              variant="determinate"
                              value={city.percentage}
                            />
                          </Box>
                          <h6 >
                            {city.percentage}%
                          </h6>
                        </div>
                      ))}
                    </Grid>
                  </Grid>
                </Grid>
              ) : (
                <Grid item xs={12} className="mt-2">
                  <Grid container className="d-flex gap-2">
                    <Grid
                      item
                      xs={12}
                      className="p-3"
                      md={4}
                      style={{ border: "1px solid grey", borderRadius: "20px" }}
                    >
                      <div>
                        <h6>Most popular location</h6>
                      </div>
                      {analytics?.countries[0]?.popular.map((city) => (
                        <div className="d-flex justify-content-between px-3">
                          <h6>{city.name}</h6>
                          <h6 className='fw-bold px-2 py-3' style={{backgroundColor:"#EBF1FC",color:"#1A90FF",borderRadius:"7px" }}>
                            {city.percentage}%
                          </h6>
                        </div>
                      ))}
                    </Grid>
                    <Grid
                      item
                      xs={12}
                      className="p-3"
                      md={6}
                      style={{ border: "1px solid grey", borderRadius: "20px" }}
                    >
                      {analytics?.countries[0]?.all.map((city, index) => (
                        <div className="d-flex justify-content-between align-items-center px-3">
                          <h6 className="w-50 ">
                            {" "}
                            <span style={{ marginRight: "5px" }}>
                              {index + 1}{" "}
                            </span>
                            {city.name}
                          </h6>
                          <Box sx={{ width: "100%" }}>
                            <BorderLinearProgress
                              variant="determinate"
                              value={city.percentage}
                            />
                          </Box>
                          <h6>
                            {city.percentage}%
                          </h6>
                        </div>
                      ))}
                    </Grid>
                  </Grid>
                </Grid>
              )}
            </Grid>
          </div>
          <div className="col-xl-3">
            <Grid container mt={3}>
              <Grid className='' item xs={12} style={{borderBottom:"1px solid #F1F1F1"}}>
                <h6>
                Scans by operating system
                </h6>
                <Grid item xs={12}>

                <ResponsiveContainer aspect={1.5}>
  <PieChart>
    <Pie
      label={({ name, value }) => `${name}: ${value.toFixed(2)} %`}
      labelLine={false}
      data={plateform}
      cx={250}
            cy={150}
            innerRadius={80}
            outerRadius={100}
      nameKey="name"
      dataKey="value"
      // innerRadius="25%"
      // outerRadius="50%"
    >
      {plateform?.map((entry, index) => (
        <Cell key={entry.id} fill={colors[index % colors.length]} />
      ))}
       <Label
              content={<CustomLabel labelText={maxPercentageObject?.name} value={maxPercentageObject?.value} />}
              position="center"
            />
    </Pie>
    {/* <Pie
        labelLine={false}
        data={plateform}
        cx={200}
        cy={200}
        innerRadius={80}
        outerRadius={100}
        nameKey="name"
        dataKey="percentage"
      >
        {plateform.map((entry, index) => (
          <Cell key={entry.name} fill={colors[index % colors.length]} />
        ))}
        <Label
          content={({ cx, cy }) => {
            return (
              <text x={cx} y={cy} textAnchor="middle" fill="#8884d8">
                {maxPercentageObject.name} - {maxPercentageObject.percentage.toFixed(2)} %
              </text>
            );
          }}
        />
      </Pie> */}
  </PieChart>
</ResponsiveContainer>
                {/* <Chart
      chartType="PieChart"
      width="100%"
      height="400px"
      data={data}
      options={options}
    /> */}
                </Grid>
              </Grid>
            </Grid>
          </div>
        </div>
      </div>
    </section>:
   <div className='d-flex justify-content-center'>
   <Loader/>
   </div>
    }
  </>
  );
}

export default SingleQrAnalytics







  function ChartComp({data,type}){
 
    return (
      <ResponsiveContainer width="100%" height="100%">
      <LineChart
        width={500}
        height={300}
        data={data}
        margin={{
          top: 5,
          right: 30,
          left: 20,
          bottom: 5,
        }}
      >
        <CartesianGrid strokeDasharray="3 3" />
        <XAxis dataKey="formattedDate" />
        <YAxis />
        <Tooltip />
        <Legend />
        <Line
  type="monotone"
  dataKey="scans"
  name="Scans"
  strokeWidth={4}
  dot={null}
  stroke="#3278e4"
  activeDot={{ r: 8 }}
  
/>
          <Line
            type="monotone"
            dataKey="u_scans"
            name="Unique"
            strokeWidth={4}
            dot={null}
            stroke="#adc9f4"
            activeDot={{ r: 8 }}
          />
      </LineChart>
    </ResponsiveContainer>
    );
  
}





const CustomLabel = ({ viewBox, labelText, value }) => {
  const { cx, cy } = viewBox;
  return (
    <g>
      <text
        x={cx}
        y={cy}
        className="recharts-text recharts-label"
        textAnchor="middle"
        dominantBaseline="central"
        alignmentBaseline="middle"
        fontSize="15"
      >
        {labelText}
      </text>
      <text
        x={cx}
        y={cy + 20}
        className="recharts-text recharts-label"
        textAnchor="middle"
        dominantBaseline="central"
        alignmentBaseline="middle"
        fill="#0088FE"
        fontSize="26"
        fontWeight="600"
      >
        {value}
      </text>
    </g>
  );
};