import React from 'react'
import { Link } from 'react-router-dom'

const PdfScan = ({qrData}) => {
    console.log(qrData,"object");
    return (
        <div className="smartphone ">
            <div className='smartphone-inner'>
            <div className=' sec-h d-flex flex-column container text-white p-0 '  >
            <div className='p-2' style={{backgroundColor:`${qrData?.qr_info?.info?.backgroud_color || "#3278E4"}`,color:`${qrData?.qr_info?.info?.text_color || "white"}`}}>
                <div className="ms-2 fs-6">{qrData?.qr_info?.info?.company ||"Company Name"}</div>
                <div className="ms-2 h2 fw-semibold"> {qrData?.qr_info?.info?.headline ||"Name"}</div>
                <div className="ms-2 fs-5">{qrData?.qr_info?.info?.description ||" Description"}</div>
            </div>
            <div className='mt-auto text-center  mb-2 mx-2'>
                <Link className="text-center d-block text-decoration-none text-dark">{qrData?.qr_info?.info?.website ||" "}</Link>
                <button
                
                onClick={()=>window.open(qrData?.qr_info?.info?.file,"_blank")}
                className="btn  btn-block  w-100 text-white" style={{backgroundColor:`${qrData?.qr_info?.info?.button_color || "#3278E4"}`}}>{"View PDF"}</button>
            </div>
            </div>

        </div>
        </div>
    )
}

export default PdfScan
