import React, { useEffect, useRef, useState } from 'react'
import { TiTick } from "react-icons/ti";
import { Button, IconButton, Box, FormControlLabel, Grid, TextField, Typography, Switch, Radio, RadioGroup, FormLabel, FormControl, Container, InputLabel, Select, MenuItem, Paper, InputAdornment, OutlinedInput, styled } from "@mui/material";

import { toast } from 'react-toastify';
import Tooltip from '@mui/material/Tooltip';


import { tooltipClasses } from '@mui/material/Tooltip';
import StepOne from '../components/StepOne';
import StepTwo from '../components/StepTwo';
import Email from '../components/Qrcode/Email';
import BulkUrl from '../components/Qrcode/BulkUrl';
import Feedback from '../components/Qrcode/Feedback';
import SocialMedia from '../components/Qrcode/SocialMedia';
import Whatsapp from '../components/Qrcode/Whatsapp';
import Pdf from '../components/Qrcode/Pdf';
import Sms from '../components/Qrcode/Sms';
import Text from '../components/Qrcode/Text';
import Vcard from '../components/Qrcode/Vcard';
import Images from '../components/Qrcode/Images';
import Url from '../components/Qrcode/Url';
import StepThree from '../components/StepThree';
import { AiOutlineLeft } from 'react-icons/ai';
import { Link } from 'react-router-dom'
import QRCodeStyling from 'qr-code-styling';
import { toPng } from 'html-to-image';
import Stepper from '@mui/material/Stepper';
import Step from '@mui/material/Step';
import StepLabel from '@mui/material/StepLabel';
import Preview from './Preview';


import PropTypes from 'prop-types';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';

function CustomTabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

CustomTabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}



function getStyleObject(){
  return new QRCodeStyling({
    width: 280,
    height: 280,
    type: 'svg',
    data:"https://example.com",
    
    // image:"https://upload.wikimedia.org/wikipedia/commons/5/51/Facebook_f_logo_%282019%29.svg",
    // image:"https://bartztestbucket.s3.us-east-2.amazonaws.com/istockphoto-1477641458-1024x1024.jpg",
    dotsOptions: {
      color: "#000000",
      type: "square"
    },
    backgroundOptions:{
      color:"#ffffff",
    },
    cornersSquareOptions:{
      type: "dot"
    },
    imageOptions: {
      hideBackgroundDots: true,
      margin: 4,
      imageSize: 0.4,
      crossOrigin: "anonymous",
    }
  });
}



const frameData = {
  type: 'none',  /// types: 'frame1', 'frame2', 'frame3', 'none',
  text: "Scan me!",
  frameColor: "#19325e",
  textColor: "#ffffff",
  textSize: '20'
}


const CreateQr = () => {

  const [value, setValue] = React.useState(0);
  

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const ref = useRef(null);
  useEffect(() => {
    if(ref?.current)
    qrCode.append(ref.current);
  }, []);
  
  
const [qrCode]=useState(getStyleObject())
  const [step, setStep] = useState(1)
  const [select, setSelected] = useState("")
  const [qrData, setQrData] = useState()
  const [config, setConfig] = useState(qrCode?._options)
  const [frameConfig, setFrameConfig] = useState(frameData)
  const nextStep = () => {
    setStep(step + 1)
  }
  console.log(select,"kioplop")
  
  /// My code

  useEffect(() => {
    qrCode.update(config)
  }, [config])

  useEffect(() => {
    document.documentElement.style.setProperty('--frame-color', frameConfig.frameColor);
  }, [frameConfig.frameColor])



const htmlToImage = async() => {
  const contentToCapture =  document.getElementById("myContainer");

  toPng(contentToCapture, { cacheBust: false })
    .then((dataUrl) => {
      const link = document.createElement("a");
      link.download = "my-image-name.png";
      link.href = dataUrl;
      link.click();
    })
    .catch((err) => {
      console.log(err);
    });
};




  return (



    <div className='container-md pb-5  ' >

      <div className="row " >
        {(step==1) &&
          <div className="col-sm-1 pe-0">
          <Link className='ms-sm-auto text-decoration-none  light-blue2 p-2 rounded-3 mb-2 d-flex justify-content-center align-items-center' style={{ maxWidth: "fit-content", maxHeight: "fit-content" }} to="/admin/qrode"><AiOutlineLeft /></Link>

        </div>}
        <div className="col-xl-7 ">
          <Box className='p-3 pe-md-5' component={Paper} >
            {/* <div className=" d-flex justify-content-between px-md-4 pe-md-5 me-md-5">
              <div
                className={`rounded-circle border d-flex align-items-center justify-content-center ${step >= 1 && "bg-primary"}`}
                style={{ width: "2rem", height: "2rem" }}
              >

                {step >= 1 ? <TiTick /> : "1"}
              </div>
              <hr className="" />
              <div className={`rounded-circle border d-flex align-items-center justify-content-center ${step >= 2 && "bg-primary"}`}
                style={{ width: "2rem", height: "2rem" }}
              >

                {step >= 2 ? <TiTick /> : "2"}
              </div>
              <hr className="border" />
              <div
                className={`rounded-circle border d-flex align-items-center justify-content-center ${step >= 3 && "bg-primary"}`}
                style={{ width: "2rem", height: "2rem" }}
              >

                {step >= 3 ? <TiTick /> : "3"}
              </div>

            </div> */}
             <Box sx={{ width: '100%' }}>
      <Stepper activeStep={step-1}  alternativeLabel>
        {["","",""].map((label) => (
          <Step key={label}>
            <StepLabel >{label}</StepLabel>
          </Step>
        ))}
      </Stepper>
    </Box>

           


            <Box className="px-3 " maxWidth="md" style={{}}>

              <div className={`candidate-detail check ${(step == 1) ? "d-block" : "d-none"}`}>
                <p className="text-dark">Step 1: Select QR code type</p>
                <StepOne nextStep={nextStep} setSelected={setSelected} />
              </div>
              <div className={`candidate-info mt-2 ${(step == 2) ? "d-block" : "d-none"}`}>
                <p className="text-dark">Step 2: Enter Information</p>

                {select == "URL" && <Url step={step} setStep={setStep} setQrData={setQrData}/>}
                {select == "BULK" && <BulkUrl step={step} setStep={setStep} setQrData={setQrData}/>}
                {select == "FEED" && <Feedback step={step} setStep={setStep} setQrData={setQrData}/>}
                {select == "SOCIAL" && <SocialMedia  step={step} setStep={setStep} setQrData={setQrData}/>}
                {select == "WHATSAPP" && <Whatsapp step={step} setStep={setStep} setQrData={setQrData} />}
                {select == "EMAIL" && <Email  step={step} setStep={setStep} setQrData={setQrData}/>}
                {select == "PDF" && <Pdf  step={step} setStep={setStep} setQrData={setQrData}/>}
                {select == "SMS" && <Sms step={step} setStep={setStep} setQrData={setQrData}/>}
                {select == "TEXT" && <Text step={step} setStep={setStep} setQrData={setQrData} />}
                {select == "VCARD" && <Vcard step={step} setStep={setStep} setQrData={setQrData} />}
                {select == "IMAGES" && <Images step={step} setStep={setStep} setQrData={setQrData} />}




              </div>
              <div className={`candidate-info mt-2 ${(step == 3) ? "d-block" : "d-none"}`}>
                <p className="text-dark">Step 3: Modify design</p>
                <StepThree step={step} setStep={setStep} qrData={qrData} qrCode={qrCode}  setQrData={setQrData} config={config} setConfig={ setConfig } frameConfig={frameConfig} setFrameConfig={setFrameConfig}/>
              </div>

            </Box>
            {/* <div className="d-flex align-items-center mt-3">
              <div>
                <button onClick={() => setStep(step - 1)} className="light-blue2 px-5 py-2 blue-button-cl border-0 rounded-3">
                  Back
                </button>
              </div>
              <div className="ms-3">
                <button onClick={() => setStep(step + 1)} className="px-5 py-2 text-white blue-button-bg border-0 rounded-3">
                  Next
                </button>
              </div>
            </div> */}

          </Box>
           {/* ========= Modal ======= */}
  {/* <div  class="modal fade" id="folderModal" tabIndex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
    <div className="modal-dialog modal-dialog-centered">
      <div className="modal-content rounded-4">
        <div className="modal-header bg-modal rounded-modal">
          <div className="d-flex align-items-center">
            <img src={require("./../assets/folder-2.png")} alt="" className="img-folder2" /> 
            <h4
              className="modal-title fs-6 blue-button-cl fw-normal ps-2"
              id="exampleModalLabel"
            >
              Create Folder
            </h4>
          </div>
        </div>
        <div className="modal-body px-sm-5">
          <form action="">
            <div className="mb-5">
              <label htmlFor="" className="blue-button-cl form-label">
                Folder name
              </label>
              <input
                type="text"
                className="form-control shadow-none light-blue2 border-0 folder-place"
                placeholder="Folder name here"
              />
            </div>
            <div className="mt-4 d-flex align-items-center">
              <div className="w-100 me-3">
                <button
                  type="button"
                  className="bl-bg w-100 border-0 py-2 px-sm-5 rounded-3 button-clr"
                  data-bs-dismiss="modal"
                >
                  Cancel
                </button>
              </div>
              <div className="w-100">
                <button
                  type="button"
                  className="button-bg w-100 border-0 py-2 px-sm-5 rounded-3 text-white"
                >
                  Create
                </button>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div> */}
        </div>

        <div className="col-xl-4 offset-xl-1 col-md-5 p-5 mt-md-0 mt-3 " style={{display:step!==1?"block":"none"}}>
        <Box sx={{ width: '100%' }}>
    {  (["FEED","BULK","IMAGES","PDF","SOCIAL"].includes(select))&&  <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
        <Tabs value={value} onChange={handleChange} aria-label="basic tabs example">
          <Tab label="QR" {...a11yProps(0)} />
          <Tab label="Preview" {...a11yProps(1)} />
        </Tabs>
      </Box>}
      <div style={{display:value==0?"block":"none"}}>
     
          <h3 className="fw-normal">Preview</h3>
          <div className="mt-3">
           



          <div id="myContainer" style={{background:'transparent', display:"inline-block"}}>
                <div className={ `frame ${frameConfig.type !== 'none' ? 'active '+ frameConfig.type : ''}`}>
                <div className="frame-item">
                  {/* Qr Code */}
                   <div className="qr" ref={ref} ></div>
                </div>
                   <p className="text mb-3" style={
                    {color: frameConfig.textColor, fontSize: frameConfig.textSize+'px'}}>
                    {frameConfig.text || ''}</p>
                </div>
          </div>





          {/* </div> */} <br /> <br />
            <button className='px-5 py-2 text-white blue-button-bg border-0 rounded-3' onClick={htmlToImage}>
              Download</button>
          </div>
          <div className="mt-5">
            <h6 className="text-dark">Scan to see your QR code live.</h6>
            <p className="grey-clr mb-0 font-13">
              You will be able to modify and customize the design of the code in the
              final step of this code creation process.
            </p>
          </div>
        
      </div>
      <CustomTabPanel value={value} index={1}>
      <Preview select={select} qrData={qrData}/>
      </CustomTabPanel>
      
    </Box>
    </div>






       


        
      </div>



    </div>

  );
}
export default CreateQr;




