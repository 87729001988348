import React, { useEffect, useState } from 'react'
import ToggleButton from '@mui/material/ToggleButton';
import ToggleButtonGroup from '@mui/material/ToggleButtonGroup';
import apiClient from '../api/apiClient';
import FolderIcon from '@mui/icons-material/Folder';
 import image from "../assets/frames/frame_2.svg"
 import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import { Link } from 'react-router-dom';
import Calender from '../components/mui/Calender';
import MoreHorizIcon from '@mui/icons-material/MoreHoriz';
import { LineChart, Line, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer } from 'recharts';
import { toPng } from 'html-to-image';
import Box from '@mui/material/Box';
import { Grid, IconButton, Menu, MenuItem, Paper } from '@mui/material';


import { styled } from '@mui/material/styles';

import LinearProgress, { linearProgressClasses } from '@mui/material/LinearProgress';
import NavigateWrapper from '../components/NavigateWrapper';
import { Button } from 'rsuite';

const BorderLinearProgress = styled(LinearProgress)(({ theme }) => ({
  height: 10,
  borderRadius: 5,
  [`&.${linearProgressClasses.colorPrimary}`]: {
    backgroundColor: theme.palette.grey[theme.palette.mode === 'light' ? 200 : 800],
  },
  [`& .${linearProgressClasses.bar}`]: {
    borderRadius: 5,
    backgroundColor: theme.palette.mode === 'light' ? '#1a90ff' : '#308fe8',
  },
}));


const Analytics = () => {
  const [alignment, setAlignment] = React.useState('Qr');
  const [dataTable, setDataTable] = useState('');
  const [select, setSelect] = useState('a');
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const [alignment1, setAlignment1] = React.useState('city');

  const handleChange3 = (event, newAlignment) => {
    setAlignment1(newAlignment);
  };

  const handleChange = (event, newAlignment) => {
    setAlignment(newAlignment);
  };
const [analytics,setAnalytics]=useState()
  const fetchData=async()=>{
    const result=await apiClient.get(`/scan/analytics/?period_type=${dataTable}&start_date=${startDate}&end_date=${endDate}`)
    if(!result.ok) return
    console.log(result,"aqwww");
    setAnalytics(result.data.result)

  }
  useEffect(()=>{
    fetchData()
  },[dataTable,startDate,endDate])
 

  const handleChange1 = (event, newAlignment) => {
    console.log(newAlignment);
    setDataTable(newAlignment);
  };
  const toggleDate=()=>{
    setEndDate("")
    setEndDate("")
  }
  
  const [selectedValue, setSelectedValue] = useState('');
  const htmlToImage = async() => {
    const contentToCapture =  document.getElementById("myContainer");
  
    toPng(contentToCapture, { cacheBust: false })
      .then((dataUrl) => {
        const link = document.createElement("a");
        link.download = "my-image-name.png";
        link.href = dataUrl;
        link.click();
      })
      .catch((err) => {
        console.log(err);
      });
  };


  const [anchorEl, setAnchorEl] = useState();

 

  const handleMenu = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };
  
console.log(analytics?.cities[0],"zxc");
  return (
    <section className="px-xl-4">
    <div className="container-fluid px-md-5">
      <h2 className="blue-button-cl fw-normal ps-4">Analytics</h2>
      <div className="row ">
        <div className="col-xl-4 col-lg-6 col-sm-8 ps-md-4">
            {/* <div className="shadow p-2 light-blue2 rounded-3 d-flex justify-content-between">
              <div>
                <button className="blue-button-cl bl-bg px-4 py-2 border-0 rounded-3">
                  All Time
                </button>
              </div>
              <div>
                <button className="blue-button-cl bg-transparent px-4 py-2 border-0 rounded-3">
                  Year
                </button>
              </div>
              <div>
                <button className="blue-button-cl bg-transparent px-4 py-2 border-0 rounded-3">
                  Month
                </button>
              </div>
              <div>
              
                <div className="dropdown">
                  <button
                    className="btn bg-transparent dropdown-toggle"
                    type="button"
                    data-bs-toggle="dropdown"
                    aria-expanded="false"
                  >
                    <i className="bi bi-calendar blue-button-cl" />
                  </button>
                  <ul className="dropdown-menu">
                    <li></li>
                  </ul>
                </div>
              </div>
            </div> */}

<ToggleButtonGroup className="shadow p-2 light-blue2 rounded-3 d-flex justify-content-between"
      color="primary"
      value={alignment}
      exclusive
      onChange={handleChange1}
      aria-label="Platform"
    >
      <ToggleButton className="blue-button-cl bl-bg px-4 py-2 border-0 rounded-3" onClick={()=>{toggleDate()
        setSelect("a")}} style={{backgroundColor:(select === 'a') && "#B8CFF6"}} value="">All Time</ToggleButton>
      <ToggleButton className="blue-button-cl bl-bg px-4 py-2 border-0 rounded-3" onClick={()=>{toggleDate()
        setSelect("b")}} style={{backgroundColor:(select === 'b')&&"#B8CFF6"}} value="yearly">Year</ToggleButton>
      <ToggleButton  className="blue-button-cl bl-bg px-4 py-2 border-0 rounded-3" onClick={()=>{toggleDate()
        setSelect("c")}} style={{backgroundColor:(select === 'c')&&"#B8CFF6"}} value="monthly">Month</ToggleButton>
      {/* <ToggleButton  className=" blue-button-cl bl-bg px-4 py-2 border-0 rounded-3 dropdown" value="calender"><ArrowDropDownIcon/></ToggleButton> */}
      {/* <div className="dropdown"> */}
                  <ToggleButton
                    className="blue-button-cl bl-bg px-4 py-2 border-0 rounded-3 dropdown-toggle"
                    type="button"
                    value=""
                    onClick={()=>{setSelect("d")}} style={{backgroundColor:(select === 'd')&&"#B8CFF6"}}
                    data-bs-toggle="dropdown"
                    aria-expanded="false"
                  >
                    <i className="bi bi-calendar blue-button-cl" />
                  </ToggleButton>
                  <ul className="dropdown-menu">
                   <Calender
                   startDate={startDate}
                   endDate={endDate}
                   setStartDate={setStartDate}
                   setEndDate={setEndDate}
                   />
                  </ul>
                {/* </div> */}
    </ToggleButtonGroup>


        </div>
      </div>
      <div className="row mt-4">
        <div className="col-xl-9">
          <div className="shadow rounded-4 p-3 bg-white">
            <div className="row">
              <div className="col-lg-3 col-md-4 col-sm-6 mb-3">
                <div className="light-blue2 p-3 rounded-4 h-100">
                  <div className="d-flex align-items-center">
                    <div className="bl-bg p-2 rounded-3">
                      <img
                        src="https://chart.apis.google.com/chart?cht=qr&chs=250x250&chl="
                        alt=""
                        className="qr-img"
                      />
                    </div>
                    <div className="ps-2">
                      <p className="blue-button-cl font-13">Total</p>
                    </div>
                  </div>
                  <div className="px-2 mt-1">
                    <h4 className="blue-button-cl fw-normal mb-0">{analytics?.analytics?.total || 0}</h4>
                  </div>
                </div>
              </div>
              <div className="col-lg-3 col-md-4 col-sm-6 mb-3">
                <div className="light-blue2 p-3 rounded-4 h-100">
                  <div className="d-flex align-items-center">
                    <div className="bl-bg p-2 rounded-3">
                      <img
                        src="https://chart.apis.google.com/chart?cht=qr&chs=250x250&chl="
                        alt=""
                        className="qr-img"
                      />
                    </div>
                    <div className="ps-2">
                      <p className="blue-button-cl font-13">Unique</p>
                    </div>
                  </div>
                  <div className="px-2 mt-1">
                    <h4 className="blue-button-cl fw-normal mb-0">{analytics?.analytics?.u_count||0}</h4>
                  </div>
                </div>
              </div>
              <div className="col-lg-3 col-md-4 col-sm-6 mb-3">
                <div className="light-blue2 p-3 rounded-4 h-100">
                  <div className="d-flex align-items-center">
                    <div className="bl-bg p-2 rounded-3">
                      <img
                        src="https://chart.apis.google.com/chart?cht=qr&chs=250x250&chl="
                        alt=""
                        className="qr-img"
                      />
                    </div>
                    <div className="ps-2">
                      <p className="blue-button-cl font-13">
                        Active dynamic codes
                      </p>
                    </div>
                  </div>
                  <div className="px-2 mt-1">
                    <h4 className="blue-button-cl fw-normal mb-0">{analytics?.analytics?.dynamicQrTypeCount||0}</h4>
                  </div>
                </div>
              </div>
              <div className="col-lg-3 col-md-4 col-sm-6 mb-3">
                <div className="light-blue2 p-3 rounded-4 h-100">
                  <div className="d-flex align-items-center">
                    <div className="bl-bg p-2 rounded-3">
                      <img
                        src="https://chart.apis.google.com/chart?cht=qr&chs=250x250&chl="
                        alt=""
                        className="qr-img"
                      />
                    </div>
                    <div className="ps-2">
                      <p className="blue-button-cl font-13">Avg per code</p>
                    </div>
                  </div>
                  <div className="px-2 mt-1">
                    <h4 className="blue-button-cl fw-normal mb-0">{analytics?.analytics?.avgCodePerScan||0}</h4>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="shadow rounded-4 p-3  mt-3">
            <div className='d-flex justify-content-between'>

            <p className="blue-button-cl ps-4">Scans by month</p>



            <IconButton
                size="large"
                aria-label="account of current user"
                aria-controls="menu-appbar"
                aria-haspopup="true"
                onClick={handleMenu}
                color="inherit"
              >
               <MoreHorizIcon />
              </IconButton>
              <Menu
                id="menu-appbar"
                anchorEl={anchorEl}
                anchorOrigin={{
                  vertical: 'top',
                  horizontal: 'right',
                }}
                keepMounted
                transformOrigin={{
                  vertical: 'top',
                  horizontal: 'right',
                }}
                open={Boolean(anchorEl)}
                onClose={handleClose}
              >
                <MenuItem onClick={()=>{handleClose()
                htmlToImage()}
                }>Download</MenuItem>
                {/* <MenuItem onClick={handleClose}>My account</MenuItem> */}
              </Menu>














          
              {/* <Button onClick={htmlToImage}>Download</Button> */}
            </div>
            <div id="myContainer" className=' ' style={{ width: '100%',height:"500px" }}>
            <ChartComp data={analytics?.graph_data} type={dataTable}/>
    </div>

          </div>
          <Grid container mt={4} className="p-3 " component={Paper} >
          <Grid item xs={12} className=" d-flex justify-content-between align-items-center">
            <div>
              <h6>Scan by location</h6>
            </div>
            <div>
            <ToggleButtonGroup
      color="primary"
      value={alignment}
      exclusive
      onChange={handleChange3}
      aria-label="Platform"
    >
      <ToggleButton onClick={()=>setSelect("a")} style={{backgroundColor:(select === 'a')&&"#B8CFF6"}} value="city">City</ToggleButton>
      <ToggleButton onClick={()=>setSelect("x")} style={{backgroundColor:(select === 'x')&&"#B8CFF6"}} value="country">Country</ToggleButton>
      
    </ToggleButtonGroup>
            </div>
          </Grid>
          {(alignment1=="city")?
          <Grid item xs={12} className="">
            <Grid container className="d-flex gap-2">
              <Grid item xs={12} className="p-3" md={4} style={{border:"1px solid grey",borderRadius:"20px"}}>
                <div>
                  <h6>Most popular location</h6>
                </div>
                {analytics?.cities[0]?.popular.map((city)=>
                <div className='d-flex justify-content-between px-3'>
                  <h6>{city.name}</h6>
                  <h6>{city.percentage}%</h6>
                </div>)}
              </Grid>
              <Grid item xs={12} className="p-3" md={6} style={{border:"1px solid grey",borderRadius:"20px"}}>
              {analytics?.cities[0]?.all.map((city,index)=>
                <div className='d-flex justify-content-between align-items-center px-3'>
                  <h6 className='w-50 '> <span style={{marginRight:"5px"}}>{index+1} </span>{city.name}</h6>
                  <Box sx={{ width: '100%' }}>
                  <BorderLinearProgress variant="determinate" value={city.percentage} />
    </Box>
    <h6>{city.percentage}%</h6>
                </div>)}
               
              </Grid>
            </Grid>
          </Grid>:
          <Grid item xs={12} className="">
            <Grid container className="d-flex gap-2">
              <Grid item xs={12} className="p-3" md={4} style={{border:"1px solid grey",borderRadius:"20px"}}>
                <div>
                  <h6>Most popular location</h6>
                </div>
                {analytics?.countries[0]?.popular.map((city)=>
                <div className='d-flex justify-content-between px-3'>
                  <h6>{city.name}</h6>
                  <h6>{city.percentage}%</h6>
                </div>)}
              </Grid>
              <Grid item xs={12} className="p-3" md={6} style={{border:"1px solid grey",borderRadius:"20px"}}>
              {analytics?.countries[0]?.all.map((city,index)=>
                <div className='d-flex justify-content-between align-items-center px-3'>
                  <h6 className='w-50 '> <span style={{marginRight:"5px"}}>{index+1} </span>{city.name}</h6>
                  <Box sx={{ width: '100%' }}>
                  <BorderLinearProgress variant="determinate" value={city.percentage} />
    </Box>
    <h6>{city.percentage}%</h6>
                </div>)}
               
              </Grid>
            </Grid>
          </Grid>}
        </Grid>
        </div>
        <div className="col-xl-3 mt-xl-0 mt-3" >
          <div className="h-75 shadow bg-white p-5 rounded-4">
                <div className=' row d-flex align-items-center'>
                  <h5 className='col'>Scans by code</h5>
                  <ToggleButtonGroup
                  className='col'
      color="primary"
      value={alignment}
      exclusive
      onChange={handleChange}
      aria-label="Platform"
    >
      <ToggleButton value="Qr" className='px-4'>Qr</ToggleButton>
      <ToggleButton value="folder">Folder</ToggleButton>
      
    </ToggleButtonGroup>
                </div>
               
            {alignment=="Qr"?
            <div  style={{overflow:"auto", height:"100vh"}}> 
            {analytics?.all_qr_data?.map((item)=>
            <NavigateWrapper to={"/admin/analytics/"+item.qr._id}>
                    <div className='row mt-2  d-flex align-items-center ' >
                  <div className='col-3' >
                    <img src={item?.qr?.image} />

                  </div>
                  <div className='col-7 text-break'>
                 <h6 className='mb-0'>  {item.qr.name}</h6>
                <a style={{color:"grey",textDecoration:"none",fontSize:"10px"}} target='_blank' href={item?.qr?.qr_data?.data}>{item?.qr?.qr_data?.data}</a>
                  </div>
                  <div className='col-2'>
                {item?.scanCount}
                  </div>
                </div>
            </NavigateWrapper>
          )}
                </div>
:
<div  style={{overflow:"auto", height:"100vh"}}> 
{analytics?.folder_data?.map((item)=>
                <div className='row mt-2 d-flex align-items-center ' >
                  <div className='col-2 d-flex align-items-center'>
                    <FolderIcon className='' style={{fontSize:"36px",color:"grey"}}/>

                  </div>
                  <div className='col-8 text-break'>
              <h6 className='mb-0'>  {item.folder.name}</h6>
                
                <p className='mb-0'>{item.qrCount} codes</p>
                  </div>
                  <div className='col-2'>
                  {item.scanCount}
                  </div>
                </div>)}
</div>}
               
          </div>
        </div>
      </div>
    </div>
  </section>
  )
}

export default Analytics







  function ChartComp({data,type}){
 
    return (
      <ResponsiveContainer width="100%" height="100%">
        <LineChart
          width={500}
          height={300}
          data={data}
          margin={{
            top: 5,
            right: 30,
            left: 20,
            bottom: 5,
          }}
        >
          <CartesianGrid strokeDasharray="3 3" />
          <XAxis   dot={null} dataKey="formattedDate" />
          <YAxis />
          <Tooltip />
          <Legend />
          <Line
  type="monotone"
  dataKey="scans"
  name="Scans"
  strokeWidth={4}
  dot={null}
  stroke="#3278e4"
  activeDot={{ r: 8 }}
  
/>
          <Line
            type="monotone"
            dataKey="u_scans"
            name="Unique"
            strokeWidth={4}
            dot={null}
            stroke="#adc9f4"
            activeDot={{ r: 8 }}
          />
        </LineChart>
      </ResponsiveContainer>
    );
  
}
