import React, { useEffect, useState } from "react";
import QrModel from "./QrModel";
import apiClient from "../../api/apiClient";
import { toast } from "react-toastify";

const Vcard = ({qrInfo,step,setStep,setQrData,...props}) => {
  const infoData=qrInfo?.qr_info?.info
  let initialState = {
    first_name: infoData?.first_name||"",
    last_name: infoData?.last_name||"",
    company: infoData?.company||"",
    position: infoData?.position||"",
    phone: infoData?.phone||"",
    email: infoData?.email||"",
    website: infoData?.website||"",
    country: infoData?.location?.country||"",
    state: infoData?.location?.state||"",
    city: infoData?.location?.city||"",
    street: infoData?.location?.street||"",
    zip: infoData?.location?.zip||"",
    name:qrInfo?.name|| "",
    qr_info: { type: "VCard" },
    folder: qrInfo?.folder||"",
  };
  const [vcard, setVcard] = useState(initialState);
  const [modalShow, setModalShow] = React.useState(false);
  const handleChange = (key, value) => {
    setVcard({ ...vcard, [key]: value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    console.log(vcard);
return
    // const result = await apiClient.post("/qrcode", vcard);
    // if (!result.ok) return toast.error("Error");
    // console.log(result);
  };
  const [folder, setFolder] = useState();
  const fetchData = async () => {
    const result = await apiClient.get("/folder");
    setFolder(result.data.results);
  };
  useEffect(() => {
    fetchData();
  }, []);

  const [errors, setErrors] = useState({});
  const validateForm = () => {
    const newErrors = {};
    if (!vcard.first_name) {
      newErrors.fname = 'first name is required';
    }
    if (!vcard.first_name) {
      newErrors.lname = 'Last Name is required';
    }
    if (!vcard.company) {
      newErrors.company = 'company is required';
    }
    if (!vcard.position) {
      newErrors.position = 'position is required';
    }
    if (!vcard.phone) {
      newErrors.phone = 'phone is required';
    }
    if (!vcard.email) {
      newErrors.email = 'email is required';
    }
    if (!vcard.website) {
      newErrors.website = 'website is required';
    }
    if (!vcard.country) {
      newErrors.country = 'country is required';
    }
    if (!vcard.state) {
      newErrors.state = 'state is required';
    }
    if (!vcard.zip) {
      newErrors.zip = 'zip is required';
    }
    if (!vcard.city) {
      newErrors.city = 'city is required';
    }
    if (!vcard.street) {
      newErrors.street = 'street is required';
    }
    if (!vcard.name) {
      newErrors.name = 'name is required';
    }
    if (!vcard.folder) {
      newErrors.folder = 'folder is required';
    }
    setErrors(newErrors);
  
    
    return Object.keys(newErrors).length === 0;
  };

  const nextStep=()=>{
    const formIsValid = validateForm();
    if (formIsValid) {

    vcard.qr_info.info = {
      first_name: vcard.first_name,
      last_name: vcard.last_name,
      company: vcard.company,
      position: vcard.position,
      phone: vcard.phone,
      email: vcard.email,
      website: vcard.website,
      location:{
      country: vcard.country,
      state: vcard.state,
      city: vcard.city,
      street: vcard.street,
      zip: vcard.zip,}
    };
     
    const {folder,type,name,qr_info}=vcard
    if(props?.handleSubmit)
    return props?.handleSubmit({folder,type,name,qr_info})
    setQrData({folder,type,name,qr_info})
  setStep(step+1)}}

  return (
    <div className="container">
      <div className="row">
        <div className="col-11">
          <form onSubmit={handleSubmit} id="myForm">
            <div>
              <div className="row">
                <h5 className="fw-normal">Contact Information:</h5>
                <div className="col-sm-6 mb-2">
                  <label htmlFor="" className="font-13 form-label mb-0">
                    First name*
                  </label>
                  <input
                    type="text"
                    value={vcard.first_name}
                    onChange={(e) => handleChange("first_name", e.target.value)}
                    className="form-control border-0 shadow-none light-grey-bg"
                    placeholder="Herry"
                  />
                  <span  className="text-danger font-12">
         {errors.fname}
       </span>
                </div>
                <div className="col-sm-6 mb-2">
                  <label htmlFor="" className="font-13 form-label mb-0">
                    Last name*
                  </label>
                  <input
                    type="text"
                    value={vcard.last_name}
                    onChange={(e) => handleChange("last_name", e.target.value)}
                    className="form-control border-0 shadow-none light-grey-bg"
                    placeholder="Potter"
                  />
                  <span  className="text-danger font-12">
         {errors.lname}
       </span>
                </div>
                <div className="col-sm-6 mb-2">
                  <label htmlFor="" className="font-13 form-label mb-0">
                    Company*
                  </label>
                  <input
                    type="text"
                    value={vcard.company}
                    onChange={(e) => handleChange("company", e.target.value)}
                    className="form-control border-0 shadow-none light-grey-bg"
                    placeholder="Google"
                  />
                  <span  className="text-danger font-12">
         {errors.company}
       </span>
                </div>
                <div className="col-sm-6 mb-2">
                  <label htmlFor="" className="font-13 form-label mb-0">
                    Position*
                  </label>
                  <input
                    type="text"
                    value={vcard.position}
                    onChange={(e) => handleChange("position", e.target.value)}
                    className="form-control border-0 shadow-none light-grey-bg"
                    placeholder="Manager"
                  />
                  <span  className="text-danger font-12">
         {errors.position}
       </span>
                </div>
              </div>
              <h5 className="fw-normal mt-4">Contacts</h5>
              <div className="row">
                <div className="mb-2">
                  <label htmlFor="" className="font-13 form-label mb-0">
                    Phone*
                  </label>
                  <input
                    type="tel"
                    value={vcard.phone}
                    onChange={(e) => handleChange("phone", e.target.value)}
                    className="form-control border-0 shadow-none light-grey-bg"
                    placeholder={+1234567}
                  />
                  <span  className="text-danger font-12">
         {errors.phone}
       </span>
                </div>
                <div className="mb-2">
                  <label htmlFor="" className="font-13 form-label mb-0">
                    Email*
                  </label>
                  <input
                    type="email"
                    value={vcard.email}
                    onChange={(e) => handleChange("email", e.target.value)}
                    className="form-control border-0 shadow-none light-grey-bg"
                    placeholder="Potter"
                  />
                  <span  className="text-danger font-12">
         {errors.email}
       </span>
                </div>
                <div className="mb-2">
                  <label htmlFor="" className="font-13 form-label mb-0">
                    Website*
                  </label>
                  <input
                    type="text"
                    value={vcard.website}
                    onChange={(e) => handleChange("website", e.target.value)}
                    className="form-control border-0 shadow-none light-grey-bg"
                    placeholder="https://facebook.com"
                  />
                  <span  className="text-danger font-12">
         {errors.website}
       </span>
                </div>
              </div>
              <h5 className="fw-normal mt-4">Location</h5>
              <div className="row">
                <div className="col-sm-6 mb-2">
                  <label htmlFor="" className="font-13 form-label mb-0">
                    Country
                  </label>
                  <input
                    type="text"
                    value={vcard.country}
                    onChange={(e) => handleChange("country", e.target.value)}
                    className="form-control border-0 shadow-none light-grey-bg"
                    placeholder="Country"
                  />
                  <span  className="text-danger font-12">
         {errors.country}
       </span>
                </div>
                <div className="col-sm-6 mb-2">
                  <label htmlFor="" className="font-13 form-label mb-0">
                    State
                  </label>
                  <input
                    value={vcard.state}
                    onChange={(e) => handleChange("state", e.target.value)}
                    className="form-control border-0 shadow-none light-grey-bg"
                    placeholder="State"
                  />
                  <span  className="text-danger font-12">
         {errors.state}
       </span>
                </div>
                <div className="col-sm-6 mb-2">
                  <label htmlFor="" className="font-13 form-label mb-0">
                    City
                  </label>
                  <input
                    type="text"
                    value={vcard.city}
                    onChange={(e) => handleChange("city", e.target.value)}
                    className="form-control border-0 shadow-none light-grey-bg"
                    placeholder="City"
                  />
                  <span  className="text-danger font-12">
         {errors.city}
       </span>
                </div>
                <div className="col-sm-6 mb-2">
                  <label htmlFor="" className="font-13 form-label mb-0">
                    Zip
                  </label>
                  <input
                    type="text"
                    value={vcard.zip}
                    onChange={(e) => handleChange("zip", e.target.value)}
                    className="form-control border-0 shadow-none light-grey-bg"
                    placeholder={1234}
                  />
                  <span  className="text-danger font-12">
         {errors.zip}
       </span>
                </div>
                <div className="mb-2">
                  <label htmlFor="" className="font-13 form-label mb-0">
                    Street
                  </label>
                  <input
                    type="text"
                    value={vcard.street}
                    onChange={(e) => handleChange("street", e.target.value)}
                    className="form-control border-0 shadow-none light-grey-bg"
                    placeholder="Street"
                  />
                  <span  className="text-danger font-12">
         {errors.street}
       </span>
                </div>
              </div>
            </div>
            <h5 className="mt-3">Internal notes</h5>
            <div>
              <label
                htmlFor="for-name"
                className="form-label font-13 ps-2 mb-0"
              >
                Name your code
              </label>
              <input
                type="text"
                value={vcard.name}
                onChange={(e) => handleChange("name", e.target.value)}
                name=""
                id="for-name"
                className="form-control shadow-none light-grey-bg border-0"
                placeholder="Enter name"
              />
              <span  className="text-danger font-12">
         {errors.name}
       </span>
            </div>
            <div className="mt-3">
              <div className="dropdown">
                <label htmlFor="" className="form-label font-13 mb-0 ps-2">
                  Folder
                </label>
              </div>
              <div className="dropdown">
                <button
                  className="form-select shadow-none light-grey-bg border-0 text-start grey-clr"
                  type="button"
                  data-bs-toggle="dropdown"
                  aria-expanded="false"
                >
                  {!vcard?.folder?"Folder": folder?.find(x=>x._id===vcard.folder).name}
                </button>
                <ul className="dropdown-menu w-100 p-3">
                  <li
                    onClick={() => setModalShow(true)}
                    className="blue-button-cl pointer"
                    style={{ borderBottom: "1px solid black" }}
                    // data-bs-toggle="modal"
                    // data-bs-target="#folderModal"
                  >
                    <i className="bi bi-plus blue-button-cl fs-5" /> Add new
                    Folder
                  </li>
                  {folder?.map((item) => (
                    <li
                      style={{ cursor: "pointer" }}
                      onClick={() => setVcard({ ...vcard, folder: item._id })}
                    >
                      {item.name}
                    </li>
                  ))}
                </ul>
                <span  className="text-danger font-12">
         {errors.folder}
       </span>
              </div>
            </div>
            <div className="d-flex align-items-center mt-3">
              <div>
                <button onClick={() => setStep(step - 1)} className="light-blue2 px-5 py-2 blue-button-cl border-0 rounded-3">
                  Back
                </button>
              </div>
              <div className="ms-3">
                <button onClick={nextStep} className="px-5 py-2 text-white blue-button-bg border-0 rounded-3">
                  Next
                </button>
              </div>
            </div>
          </form>
          {modalShow && (
            <QrModel
              show={modalShow}
              handleChange={handleChange}
              value={vcard}
              fetchData={fetchData}
              onHide={() => setModalShow(false)}
            />
          )}
        </div>
      </div>
    </div>
  );
};

export default Vcard;
