import React ,{useState} from 'react'
import Modal from 'react-bootstrap/Modal';
import apiClient from '../../api/apiClient';
import {toast } from 'react-toastify';
import Loader from '../Loader';
const QrModel = (props) => {
  const [name,setName]=useState()
const [load,setLoad]=useState(false)
  const handleSubmit=async(e)=>{
    e.preventDefault()
    setLoad(true)
    const result=await apiClient.post("/folder",{name:name})
    if(!result.ok) return toast.error("Folder Not Created")
    setLoad(false)
    toast.success("Sucessfully Created")
    console.log(name);
    props. fetchData()
    props.onHide()
  }
  return (
    <Modal
    {...props}
    size="md"
    aria-labelledby="contained-modal-title-vcenter"
    centered
  >
    <Modal.Header closeButton  className=' bg-modal rounded-modal'>
      <Modal.Title id="contained-modal-title-vcenter">
     
          <div className="d-flex align-items-center">
            <img src={require("../../assets/folder-2.png")} alt="" className="img-folder2" /> 
            <h4
              className="modal-title fs-6 blue-button-cl fw-normal ps-2"
              id="exampleModalLabel"
            >
              Create Folder
            </h4>
          </div>
       
      </Modal.Title>
    </Modal.Header>
    <form onSubmit={handleSubmit}>
    <Modal.Body>
  
            <div className="mb-5">
              <label htmlFor="" className="blue-button-cl form-label">
                Folder name
              </label>
             
              <input
               value={name}
               onChange={(e)=>setName(e.target.value)}
                type="text"
                className="form-control shadow-none light-blue2 border-0 folder-place"
                placeholder="Folder name here"
              />
            </div>
          
        
    </Modal.Body>
    <Modal.Footer>
    <div className="mt-4 d-flex align-items-center">
              <div className="w-100 me-3">
                <button
                 onClick={()=>props.onHide()}
                  type="button"
                  className="bl-bg w-100 border-0 py-2 px-sm-5 rounded-3 button-clr"
                  data-bs-dismiss="modal"
                >
                  Cancel
                </button>
              </div>
              <div className="w-100">
              {!load?
                <button
              type='submit'
                 
                  className="button-bg w-100 border-0 py-2 px-sm-5 rounded-3 text-white"
                >
                  Create
                </button>:
                <Loader/>
                }
              </div>
            </div>
    </Modal.Footer>
    </form>
  </Modal>
  )
}

export default QrModel