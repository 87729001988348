 function downloadFile(fileUrl) {
    const fileName = decodeURIComponent(
      fileUrl.substring(fileUrl.lastIndexOf("/") + 1)
    );
    const extension = fileName.split(".").pop().split("?")[0];
  
    // Replace % with a space in the file name
    const decodedFileName = fileName.replace(/%/g, " ");
  
    fetch(fileUrl, {
      method: "GET",
      headers: {
        "Content-Type": `application/${extension}`,
      },
    })
      .then((response) => response.blob())
      .then((blob) => {
        // Create blob link to download
        const url = window.URL.createObjectURL(new Blob([blob]));
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", decodedFileName);
  
        // Append to html link element page
        document.body.appendChild(link);
  
        // Start download
        link.click();
  
        // Clean up and remove the link
        link.parentNode.removeChild(link);
      });
  }

  export {downloadFile}