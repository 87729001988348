import * as React from 'react';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import Typography from '@mui/material/Typography';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { Link } from 'react-router-dom';
import Rating from '@mui/material/Rating';
import Stack from '@mui/material/Stack';

export default function FeedbackScan({qrData}) {
  console.log(qrData,"object");
  return (
    <div className="smartphone">
      <div className="smartphone-inner">
      <div className=' sec-h d-flex flex-column container text-white p-0' >
      <div className=' p-2 text-center' style={{backgroundColor:`${qrData?.qr_info?.info?.color || "#3278E4"}`}}>
        <div className="ms-2 h2 fw-semibold"> {qrData?.qr_info?.info?.headline||"Name"}</div>
        <div className="ms-2 fs-5"> {qrData?.qr_info?.info?.description||"Description"}</div>
      </div>
      <p className='text-center'>Please Select to review Category:</p>
      {qrData?.qr_info?.info?.categories?.map((item)=>
      <div className='mt-4'>
        <Accordion>
          <AccordionSummary
            // expandIcon={<ExpandMoreIcon />}
           className='d-flex justify-content-between'
            aria-controls="panel1a-content"
            id="panel1a-header"
          >
            <Typography className='my-auto'>{item}</Typography> 

            <Stack spacing={1} className='ms-auto'>
            <Rating name="size-large" disabled defaultValue={5} size="large" />
            </Stack>
          </AccordionSummary>
          <AccordionDetails className=''>
          <div  className='text-center'>
            <Rating name="size-large" defaultValue={0} size="large" />
            </div>
            <p className='text-center'>Please rate the service</p>
            <textarea name="" className='form-control' id="" cols="30" rows="10" placeholder='Add a comment (Optional)'></textarea>
          </AccordionDetails>
        </Accordion>
      </div>)}
    </div>
      </div>
    </div>
  );
}