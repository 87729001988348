import React from 'react'
import { useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
const Services = () => {
  const navigate=useNavigate()
  const {isLoggedIn} = useSelector((state) => state.auth)
  const handleNavigate=()=>{
    if(isLoggedIn)
    {
      navigate("/admin/createqr")
    }
    else{
      navigate("/login")
    }
  }
  return (
    <>
    <div className="top bg-lightblue mb-3 mx-3 rounded-bottom-4 py-2">
     
      {/* -----------------
    start|| hero
----------------- */}
      <div className="hero-sec">
        <div className="mycontainer">
          <div className="row d-flex justify-content-center py-5 my-5">
            <div className="col-12 col-md-8 col-lg-6 text-center">
              <div>
                <h1 className="fw-bolder display-3">
                  <span className=" blue-clr">Our Services</span>
                </h1>
                <p>
                  Elevate your business, captivate your audience, and connect
                  with simplicity. At InstantQRcodes.com, we empower you to
                  create, customize, and track QR codes like never before.
                </p>
              </div>
              <div className="mt-5">
                <a
                  style={{cursor:"pointer"}}
                  onClick={()=>handleNavigate()}
                  className="bg-blue w-auto text-center text-decoration-none px-3 bg-myblue text-white py-3 px-4 rounded-4 fs-6 bs-opacity mt-3 mt-md-0"
                >
                  <span className="me-3">Create QR code</span>
                  <i className="fa-solid fa-arrow-right" />
                </a>
              </div>
              <div className="blogs-img mt-5">
                <img src={require("../assets/barcode.png")} alt="" />
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* -----------------
    end|| hero 
----------------- */}
    </div>
    {/* -----------------
    start || Our Services 
----------------- */}
    <div className=" ourservice">
      <div className="mycontainer p-4">
        <div className="row justify-content-center">
          <div className="col-12">
            <h2 className="display-4 blue-clr text-center mb-0 fw-bold">
              Our Services
            </h2>
            <p className="text-center mb-0 fs-5">A QR code for every need</p>
          </div>
          <div className="row d-flex my-md-5 justify-content-center">
            <div className="col-12 col-md-6 col-lg-6 mt-5 mt-lg-3">
              <div className="row d-flex justify-content-between">
                <div className="col-12 mt-3 col-sm-6 mt-sm-0">
                  <div className="serv-box d-flex  align-items-center mx-2 p-2 rounded-4">
                    <img
                      className="serv-img"
                      src={require("../assets/apps.png")}
                      alt="arrow"
                    />
                    <p className="mb-0 ms-3 serv-p">URL</p>
                  </div>
                </div>
                <div className="col-12 mt-3 col-sm-6 mt-sm-0">
                  <div className=" serv-box d-flex  align-items-center  mx-2 p-2  rounded-4">
                    <img
                      className="serv-img"
                      src={require("../assets/Text.png")}
                      alt="arrow"
                    />
                    <p className="mb-0 ms-3 serv-p">Text</p>
                  </div>
                </div>
              </div>
              <div className="row d-flex justify-content-between mt-sm-3">
                <div className="col-12 mt-3 col-sm-6 mt-sm-0">
                  <div className="serv-box d-flex  align-items-center mx-2 p-2 rounded-4">
                    <img
                      className="serv-img"
                      src={require("../assets/email.png")}
                      alt="arrow"
                    />
                    <p className="mb-0 ms-3 serv-p">Email</p>
                  </div>
                </div>
                <div className="col-12 mt-3 col-sm-6 mt-sm-0">
                  <div className=" serv-box d-flex  align-items-center  mx-2 p-2  rounded-4">
                    <img
                      className="serv-img"
                      src={require("../assets/SMS.png")}
                      alt="arrow"
                    />
                    <p className="mb-0 ms-3 serv-p">Sms</p>
                  </div>
                </div>
              </div>
              <div className="row d-flex justify-content-between mt-sm-3">
                <div className="col-12 mt-3 col-sm-6 mt-sm-0">
                  <div className="serv-box d-flex  align-items-center mx-2 p-2 rounded-4">
                    <img
                      className="serv-img"
                      src={require("../assets/whatsapp.png")}
                      alt="arrow"
                    />
                    <p className="mb-0 ms-3 serv-p">Whatsapp</p>
                  </div>
                </div>
                <div className="col-12 mt-3 col-sm-6 mt-sm-0">
                  <div className=" serv-box d-flex  align-items-center  mx-2 p-2  rounded-4">
                    <img
                      className="serv-img"
                      src={require("../assets/vcard.png")}
                      alt="arrow"
                    />
                    <p className="mb-0 ms-3 serv-p">vCard</p>
                  </div>
                </div>
              </div>
              <div className="row d-flex justify-content-between mt-sm-3">
                <div className="col-12 mt-3 col-sm-6 mt-sm-0">
                  <div className="serv-box d-flex  align-items-center mx-2 p-2 rounded-4">
                    <img className="serv-img" src={require("../assets/social-media.png")} alt="arrow" />
                    <p className="mb-0 ms-3 serv-p">Scial Media</p>
                  </div>
                </div>
                <div className="col-12 mt-3 col-sm-6 mt-sm-0">
                  <div className=" serv-box d-flex  align-items-center  mx-2 p-2  rounded-4">
                    <img
                      className="serv-img"
                      src={require("../assets/Feedback.png")}
                      alt="arrow"
                    />
                    <p className="mb-0 ms-3 serv-p">Feedback</p>
                  </div>
                </div>
              </div>
              <div className="row d-flex justify-content-between mt-sm-3">
                <div className="col-12 mt-3 col-sm-6 mt-sm-0">
                  <div className="serv-box d-flex  align-items-center mx-2 p-2 rounded-4">
                    <img
                      className="serv-img"
                      src={require("../assets/URL.png")}
                      alt="arrow"
                    />
                    <p className="mb-0 ms-3 serv-p">BulkUrl</p>
                  </div>
                </div>
                <div className="col-12 mt-3 col-sm-6 mt-sm-0">
                  <div className=" serv-box d-flex  align-items-center  mx-2 p-2  rounded-4">
                    <img
                      className="serv-img"
                      src={require("../assets/images.png")}
                      alt="arrow"
                    />
                    <p className="mb-0 ms-3 serv-p">Images</p>
                  </div>
                </div>
              </div>
              <div className="row d-flex justify-content-between mt-sm-3">
                <div className="col-12 mt-3 col-sm-6 mt-sm-0">
                  <div className="serv-box d-flex  align-items-center mx-2 p-2 rounded-4">
                    <img
                      className="serv-img"
                      src={require("../assets/PDF.png")}
                      alt="arrow"
                    />
                    <p className="mb-0 ms-3 serv-p ">Pdf</p>
                  </div>
                </div>
                <div className="col-12 mt-3 col-sm-6 mt-sm-0">
                  <div className=" serv-box d-flex  align-items-center  mx-2 p-2  rounded-4">
                    <img
                      className="serv-img"
                      src={require("../assets/businesscard.png")}
                      alt="arrow"
                    />
                    <p className="mb-0 ms-3 serv-p">Business Page</p>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-12 col-md-6 col-lg-3 mt-5 mt-lg-3 text-center">
              <img
                src={require("../assets/phone-wifi.png")}
                className="img-fluid phone-img"
                alt="phone-wifi"
              />
            </div>
            <div className="col-12 col-md-6 col-lg-3 mt-5 mt-lg-3">
              <div className="d-flex flex-column wifi-txt rounded-4 px-3 py-4">
                <div className="">
                  <h2 className="blue-clr">Wi-Fi</h2>
                  <p>
                    Avoid password problems by using a QR code to unlock Wi-Fi
                    access. With a quick scan, customers can easily get on to
                    your network. Fewer interruptions and super-fast connections
                    will keep everyone happy.
                  </p>
                </div>
                <div className="mt-auto">
                  <a
                    style={{cursor:"pointer"}}
                    onClick={()=>handleNavigate()}
                    className="bg-blue w-auto text-center text-decoration-none px-3 bg-myblue text-white py-3 px-3 rounded-4 fs-6 bs-opacity mt-3 mt-md-0"
                  >
                    <span className="me-3">Create QR code</span>
                    <i className="fa-solid fa-arrow-right" />
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    {/* -----------------
        end || Our Services 
    ----------------- */}
    {/* -----------------
    start || cards 
----------------- */}
    <div className="mycontainer">
      <div className="row d-flex justify-content-between">
        <div className="col-12 col-md-6 col-lg-4 mt-5">
          <div className="card1 mx-2  p-3 p-sm-5 p-md-4  border-0 mt-5 ">
            <img
              className="card-img-service img-fluid my-3"
              src={require("../assets/cardqr.png")}
              alt="cardqr"
            />
            <div className=" card-body">
              <h5>
                <span className="fw-semi blue-clr">No website needed</span>
              </h5>
              <p className="card-text">
                No website? No worries. We have created specific, customizable
                built-in landing pages for all our QR codes.
              </p>
            </div>
          </div>
        </div>
        <div className="col-12 col-md-6 col-lg-4 mt-5">
          <div className="card1 mx-2  p-3 p-sm-5 p-md-4  border-0 mt-5 ">
            <img
              className="card-img-service img-fluid my-3"
              src={require("../assets/cardqr.png")}
              alt="cardqr"
            />
            <div className=" card-body">
              <h5>
                <span className="fw-semi blue-clr">
                  The smartest QR code around
                </span>
              </h5>
              <p className="card-text">
                We have combined Artificial Intelligence and design to create
                utterly flawless QR codes. They can easily be read by any device
                on earth.
              </p>
            </div>
          </div>
        </div>
        <div className="col-12 col-md-6 col-lg-4 mt-5">
          <div className="card1 mx-2  p-3 p-sm-5 p-md-4  border-0 mt-5 ">
            <img
              className="card-img-service img-fluid my-3"
              src={require("../assets/cardqr.png")}
              alt="cardqr"
            />
            <div className=" card-body">
              <h5>
                <span className="fw-semi blue-clr">
                  Infinite customization options
                </span>
              </h5>
              <p className="card-text">
                No more boring black-and-white QR codes. Add your company logo
                and attract more scans by tailor-making your QR code with our
                endless array of easy-to-use customization options.
              </p>
            </div>
          </div>
        </div>
        <div className="col-12 col-md-6 col-lg-4 mt-5">
          <div className="card1 mx-2  p-3 p-sm-5 p-md-4  border-0 mt-5 ">
            <img
              className="card-img-service img-fluid my-3"
              src={require("../assets/cardqr.png")}
              alt="cardqr"
            />
            <div className=" card-body">
              <h5>
                <span className="fw-semi blue-clr">
                  12+ different QR code types
                </span>
              </h5>
              <p className="card-text">
                No matter your QR code need, we’ve got you covered. Create a QR
                code for something as simple as an image or as complex as a full
                restaurant menu.
              </p>
            </div>
          </div>
        </div>
        <div className="col-12 col-md-6 col-lg-4 mt-5">
          <div className="card1 mx-2  p-3 p-sm-5 p-md-4  border-0 mt-5 ">
            <img
              className="card-img-service img-fluid my-3"
              src={require("../assets/cardqr.png")}
              alt="cardqr"
            />
            <div className=" card-body">
              <h5>
                <span className="fw-semi blue-clr">
                  Keep track of everything
                </span>
              </h5>
              <p className="card-text">
                Our state-of-the-art analytics dashboard provides you with
                actionable insights about everything, including how many times
                your QR codes were scanned, where and when...
              </p>
            </div>
          </div>
        </div>
        <div className="col-12 col-md-6 col-lg-4 mt-5">
          <div className="card1 mx-2  p-3 p-sm-5 p-md-4  border-0 mt-5 ">
            <img
              className="card-img-service img-fluid my-3"
              src={require("../assets/cardqr.png")}
              alt="cardqr"
            />
            <div className=" card-body">
              <h5>
                <span className="fw-semi blue-clr">Download, share, edit</span>
              </h5>
              <p className="card-text">
                No matter what medium you print or display our QR codes on,
                they’ll maintain high resolution. Download in JPG, PNG or SVG.
                And change the content whenever you want.
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
    {/* -----------------
        end || cards 
    ----------------- */}
    <div className="services-faq ">
      <div className="mycontainer mt-5 pt-5">
        <div className="row justify-content-center py-3 my-5">
          <div className="col-lg-9">
            <h2 className="display-4 blue-clr fw-bold text-center mb-4">
              Frequently Asked Question
            </h2>
            <div className="accordion" id="accordionExample">
              <div className="accordion-item mt-4">
                <h2 className="accordion-header" id="headingOne">
                  <button
                    className="accordion-button"
                    type="button"
                    data-bs-toggle="collapse"
                    data-bs-target="#collapseOne"
                    aria-expanded="true"
                    aria-controls="collapseOne"
                  >
                    <p className="fw-bolder mb-0 blue-clr bg-transparent">
                      In publishing and graphic design, Lorem ipsum is a
                      placeholder?
                    </p>
                  </button>
                </h2>
                <div
                  id="collapseOne"
                  className="accordion-collapse collapse show"
                  aria-labelledby="headingOne"
                  data-bs-parent="#accordionExample"
                >
                  <div className="accordion-body">
                    <p className="mb-0 small">
                      In publishing and graphic design, Lorem ipsum is a
                      placeholder text commonly used to demonstrate the visual
                      form of a document or a typeface without relying on
                      meaningful content.
                    </p>
                  </div>
                </div>
              </div>
              <div className="accordion-item mt-4">
                <h2 className="accordion-header" id="headingTwo">
                  <button
                    className="accordion-button collapsed"
                    type="button"
                    data-bs-toggle="collapse"
                    data-bs-target="#collapseTwo"
                    aria-expanded="false"
                    aria-controls="collapseTwo"
                  >
                    <p className="fw-bolder mb-0 blue-clr bg-transparent">
                      In publishing and graphic design, Lorem ipsum is a
                      placeholder?
                    </p>
                  </button>
                </h2>
                <div
                  id="collapseTwo"
                  className="accordion-collapse collapse"
                  aria-labelledby="headingTwo"
                  data-bs-parent="#accordionExample"
                >
                  <div className="accordion-body">
                    <p className="mb-0 small">
                      In publishing and graphic design, Lorem ipsum is a
                      placeholder text commonly used to demonstrate the visual
                      form of a document or a typeface without relying on
                      meaningful content.
                    </p>
                  </div>
                </div>
              </div>
              <div className="accordion-item mt-4">
                <h2 className="accordion-header" id="headingThree">
                  <button
                    className="accordion-button collapsed"
                    type="button"
                    data-bs-toggle="collapse"
                    data-bs-target="#collapseThree"
                    aria-expanded="false"
                    aria-controls="collapseThree"
                  >
                    <p className="fw-bolder mb-0 blue-clr bg-transparent">
                      In publishing and graphic design, Lorem?
                    </p>
                  </button>
                </h2>
                <div
                  id="collapseThree"
                  className="accordion-collapse collapse"
                  aria-labelledby="headingThree"
                  data-bs-parent="#accordionExample"
                >
                  <div className="accordion-body">
                    <p className="mb-0 small">
                      In publishing and graphic design, Lorem ipsum is a
                      placeholder text commonly used to demonstrate the visual
                      form of a document or a typeface without relying on
                      meaningful content.
                    </p>
                  </div>
                </div>
              </div>
              <div className="accordion-item mt-4">
                <h2 className="accordion-header" id="headingFour">
                  <button
                    className="accordion-button collapsed"
                    type="button"
                    data-bs-toggle="collapse"
                    data-bs-target="#collapseFour"
                    aria-expanded="false"
                    aria-controls="collapseFour"
                  >
                    <p className="fw-bolder mb-0 blue-clr bg-transparent">
                      In publishing and graphic ?
                    </p>
                  </button>
                </h2>
                <div
                  id="collapseFour"
                  className="accordion-collapse collapse"
                  aria-labelledby="headingFour"
                  data-bs-parent="#accordionExample"
                >
                  <div className="accordion-body">
                    <p className="mb-0 small">
                      In publishing and graphic design, Lorem ipsum is a
                      placeholder text commonly used to demonstrate the visual
                      form of a document or a typeface without relying on
                      meaningful content.
                    </p>
                  </div>
                </div>
              </div>
              <div className="accordion-item mt-4">
                <h2 className="accordion-header" id="headingFive">
                  <button
                    className="accordion-button collapsed"
                    type="button"
                    data-bs-toggle="collapse"
                    data-bs-target="#collapseFive"
                    aria-expanded="false"
                    aria-controls="collapseFive"
                  >
                    <p className="fw-bolder mb-0 blue-clr bg-transparent">
                      In publishing and graphic design, Lorem?
                    </p>
                  </button>
                </h2>
                <div
                  id="collapseFive"
                  className="accordion-collapse collapse"
                  aria-labelledby="headingFive"
                  data-bs-parent="#accordionExample"
                >
                  <div className="accordion-body">
                    <p className="mb-0 small">
                      In publishing and graphic design, Lorem ipsum is a
                      placeholder text commonly used to demonstrate the visual
                      form of a document or a typeface without relying on
                      meaningful content.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </>
  )
}

export default Services
