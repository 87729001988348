import React, { useState } from "react";
import apiClient from "../../api/apiClient";
import { useEffect } from "react";
import QrModel from "./QrModel";
import { toast } from "react-toastify";

const Images = ({qrInfo,step,setStep,setQrData,...props}) => {
  console.log(qrInfo,"ioplk");
  let initialState = {
    headline:qrInfo?.qr_info?.info?.headline|| "",
    description:qrInfo?.qr_info?.info?.description|| "",
    website: qrInfo?.qr_info?.info?.website||"",
    shoping:qrInfo?.qr_info?.info?.shopping|| "",
    link: qrInfo?.qr_info?.info?.link||"",
    file:qrInfo?.qr_info?.info?.file|| "",
    backgroundCol:qrInfo?.qr_info?.info?.color|| "",
    TextCol:qrInfo?.qr_info?.info?.text|| "",
    name:qrInfo?.name||"",
    qr_info: { type: "Images" },
    folder:qrInfo?.folder||""
  };
  const [images, setimages] = useState(initialState);

  const handleChange = async (key, value) => {
    if (key == "file") {
      let formdata = new FormData();
      formdata.append("file", value);
      const result = await apiClient.post("/upload-file", formdata);
      setimages({ ...images, [key]: result.data.result });
      return;
    }
    setimages({ ...images, [key]: value });
  };

  const handleSubmit = async(e) => {
    e.preventDefault();
    console.log(images);
    images.qr_info.info = {
      headline: images.headline,
      description: images.description,
      website: images.website,
      shopping: images.shoping,
      link: images.link,
      color: images.backgroundCol,
      text: images.TextCol,
      file: images.file,
    };
    
  };

  const [modalShow, setModalShow] = React.useState(false);
  const [folder, setFolder] = useState();
  const fetchData = async () => {
    const result = await apiClient.get("/folder");
    console.log(result);
    setFolder(result.data.results);
  };
  useEffect(() => {
    fetchData();
  }, []);



  const [errors, setErrors] = useState({});
const validateForm = () => {
  const newErrors = {};
  if (!images.headline) {
    newErrors.headline = 'headline is required';
  }
  if (!images.description) {
    newErrors.description = 'description is required';
  }
  if (!images.website) {
    newErrors.website = 'website is required';
  }
  if (!images.shoping) {
    newErrors.shoping = 'shoping is required';
  }
  if (!images.link) {
    newErrors.link = 'link is required';
  }
  if (!images.file) {
    newErrors.file = 'file is required';
  }
  if (!images.backgroundCol) {
    newErrors.backgroundCol = 'background is required';
  }
  if (!images.TextCol) {
    newErrors.TextCol = 'Text is required';
  }
  
  if (!images.name) {
    newErrors.name = 'name is required';
  }
  if (!images.qr_info) {
    newErrors.qr_info = 'qr_info is required';
  }
  if (!images.folder) {
    newErrors.folder = 'folder is required';
  }
  if (!images.shoping) {
    newErrors.shoping = 'shoping is required';
  }
  setErrors(newErrors);

  
  return Object.keys(newErrors).length === 0;
};
  useEffect(()=>{
    images.qr_info.info = {
      headline: images.headline,
      description: images.description,
      website: images.website,
      shopping: images.shoping,
      link: images.link,
      color: images.backgroundCol,
      text: images.TextCol,
      file: images.file,
    };
     
    const {folder,type,name,qr_info}=images
    setQrData({folder,type,name,qr_info})
  },[images])
  const nextStep=()=>{
    const formIsValid = validateForm();
    if (formIsValid) {
      const {folder,type,name,qr_info}=images
      console.log("object");
      if(props?.handleSubmit)
      return props?.handleSubmit({folder,type,name,qr_info})
    
  setStep(step+1)}}


  return (
    <div className="container">
      <div className="row">
        <div className="col-11">
          <form onSubmit={handleSubmit} id="myForm">
            <div>
              <div className="row">
                <h5>Basic Information</h5>
                <div className="col-12 mb-2">
                  <label htmlFor="" className="form-label font-13 mb-0 ps-2">
                    Headline
                  </label>
                  <input
                    type="text"
                    name=""
                    value={images.headline}
                    onChange={(e) => handleChange("headline", e.target.value)}
                    id=""
                    placeholder="Enter name"
                    className="light-grey-bg border-0 form-control shadow-none"
                  />
                  <span  className="text-danger font-12">
         {errors.headline}
       </span>
                </div>
                <div className="col-12 mb-2">
                  <label htmlFor="" className="form-label font-13 mb-0 ps-2">
                    Description
                  </label>
                  <input
                    type="text"
                    name=""
                    value={images.description}
                    onChange={(e) =>
                      handleChange("description", e.target.value)
                    }
                    id=""
                    placeholder="More information about your gallery"
                    className="light-grey-bg border-0 form-control shadow-none "
                  />
                  <span  className="text-danger font-12">
         {errors.description}
       </span>
                </div>
                <div className="col-12 mb-2">
                  <label htmlFor="" className="form-label font-13 mb-0 ps-2">
                    Website*
                  </label>
                  <input
                    type="text"
                    value={images.website}
                    onChange={(e) => handleChange("website", e.target.value)}
                    className="form-control light-grey-bg shadow-none border-0"
                    placeholder="https:\\websiteyour.com"
                  />
                  <span  className="text-danger font-12">
         {errors.website}
       </span>
                </div>
              </div>
            </div>
            {/* <div className=" d-flex align-items-center my-3">
      <p className="mb-0 font-13 grey-clr">Button</p>
      <button className="bg-transparent border-0 text-decoration-underline blue-button-cl ms-3 font-13">
        Add button
      </button>
    </div> */}
            <div className="row">
              <div className="col-md-6 mb-2">
                <input
                  type="text"
                  name=""
                  value={images.shoping}
                  onChange={(e) => handleChange("shoping", e.target.value)}
                  id=""
                  placeholder="Start Shopping"
                  className="light-grey-bg border-0 form-control shadow-none "
                />
                <span  className="text-danger font-12">
         {errors.shoping}
       </span>
              </div>
              <div className="col-md-6 mb-2">
                <input
                  type="text"
                  value={images.link}
                  onChange={(e) => handleChange("link", e.target.value)}
                  className="form-control light-grey-bg shadow-none border-0"
                  placeholder="https:\\websiteyour.com"
                />
                <span  className="text-danger font-12">
         {errors.link}
       </span>
              </div>
            </div>
            <div className="mt-3">
              <label
                htmlFor="upload-image"
                className="border-dots d-flex justify-content-between align-items-center flex-wrap"
              >
                <div>
                  <p className="grey-clr">Drag and Drop images here</p>
                </div>
                <div className="d-flex flex-column align-items-center">
                  <img
                    src={require("../../assets/plus.png")}
                    alt=""
                    className="social-links"
                  />

                  {/* <button
                    type="button"
                    className="light-grey-bg border-0 rounded-3 py-2 px-3 mt-2 blue-button-cl"
                  >
                    Upload image
                  </button> */}
                </div>
              </label>
              <input
                type="file"
              
                onChange={(e) => handleChange("file", e.target.files[0])}
                className="d-none"
                id="upload-image"
              />
              <span  className="text-danger font-12">
         {errors.file}
       </span>
            </div>
            <div className="row mt-4">
              <h5>Color palette</h5>
              <div className="col-sm-6">
                <label htmlFor="" className="font-13 form-label mb-0 w-100">
                  Background
                </label>
                <div className="border-0 light-grey-bg rounded-3 d-flex align-items-center justify-content-between py-2 px-2">
                  <p className="mb-0 color-display">
                    {images.backgroundCol || "#3278E4"}
                  </p>
                  <input
                    type="color"
                    value={images.backgroundCol}
                    onChange={(e) =>
                      handleChange("backgroundCol", e.target.value)
                    }
                    className="shadow-none border-0 color-input"
                    defaultValue="#3278E4"
                  />
                  <span  className="text-danger font-12">
         {errors.backgroundCol}
       </span>
                </div>
              </div>
              <div className="col-sm-6 mt-sm-0 mt-3">
                <label htmlFor="" className="font-13 form-label mb-0 w-100">
                  Text
                </label>
                <div className=" border-0 light-grey-bg justify-content-between rounded-3 d-flex align-items-center py-2 px-2">
                  <p className=" mb-0 color-display">
                    {images.TextCol || "#ffff"}
                  </p>
                  <input
                    type="color"
                    value={images.TextCol}
                    onChange={(e) => handleChange("TextCol", e.target.value)}
                    className="shadow-none border-0 color-input"
                    defaultValue="#ffffff"
                  />
                  <span  className="text-danger font-12">
         {errors.TextCol}
       </span>
                </div>
              </div>
            </div>
            <h5 className="my-3">Welcome Screen</h5>
            <p>Welcome</p>
            <h5 className="my-3">Internal Notes</h5>
            <div className="mb-2">
              <label htmlFor="" className="form-label font-13 mb-0 ps-2">
                Name your code*
              </label>
              <input
                type="text"
                name=""
                value={images.name}
                onChange={(e) => handleChange("name", e.target.value)}
                id=""
                placeholder="Enter name"
                className="light-grey-bg border-0 form-control "
              />
              <span  className="text-danger font-12">
         {errors.name}
       </span>
            </div>
            <div className="mt-3">
              
              <div className="dropdown">
      <label htmlFor="" className="form-label font-13 mb-0 ps-2">
        Folder
      </label>
       
      </div>
              <div className="dropdown">
                <button
                  className="form-select shadow-none light-grey-bg border-0 text-start grey-clr"
                  type="button"
                  data-bs-toggle="dropdown"
                  aria-expanded="false"
                >
                  {!images?.folder?"Folder": folder?.find(x=>x._id===images.folder).name}
                </button>
                <ul className="dropdown-menu w-100 p-3">
                  <li onClick={() => setModalShow(true)}
                    className="blue-button-cl pointer"
                    style={{borderBottom:"1px solid black"}}
                    // data-bs-toggle="modal"
                    // data-bs-target="#folderModal"
                  >
                    <i className="bi bi-plus blue-button-cl fs-5" /> Add
                    new Folder
                  </li>
                  {folder?.map((item)=>
                   <li style={{cursor:"pointer"}} onClick={()=>setimages({...images,folder:item._id})} >{item.name}</li>
                   )}
                </ul>
                     


              </div>
            </div>
            {step&&
      <div className="d-flex align-items-center mt-3">
              <div>
                <button onClick={() => setStep(step - 1)} className="light-blue2 px-5 py-2 blue-button-cl border-0 rounded-3">
                  Back
                </button>
              </div>
              <div className="ms-3">
                <button onClick={nextStep} className="px-5 py-2 text-white blue-button-bg border-0 rounded-3">
                  Next
                </button>
              </div>
            </div>}
      {qrInfo&&
      <div className="d-flex align-items-center mt-3">
             
              <div className="ms-3">
                <button onClick={nextStep} className="px-5 py-2 text-white blue-button-bg border-0 rounded-3">
                 Save
                </button>
              </div>
            </div>}
          </form>

          {modalShow && (
            <QrModel
              show={modalShow}
              fetchData={fetchData}
              handleChange={handleChange}
              onHide={() => setModalShow(false)}
            />
          )}
        </div>
      </div>
    </div>
  );
};

export default Images;
