import { Button, Grid } from '@mui/material'
import React, { useEffect, useState } from 'react'
import apiClient from '../api/apiClient'
import { useNavigate } from 'react-router-dom'

const Billing = () => {
const navigate=useNavigate()
    const [user,setUser]=useState()
    const [data,setData]=useState()
    const fetchData=async()=>{
      const result=await apiClient.get("/scan/analytics/counts?fields=dynamic_count,static_count,scan_count")
      setData(result.data.result)
    }
    const fetchProfile=async()=>{
      const result=await apiClient.get("/auth/user-info")
      setUser(result.data)
    }
    useEffect(()=>{
        fetchData()
       fetchProfile()
      },[])
console.log(user,"oooi");
function formatDate(date) {
    const expiresAt = user?.trial?.expires_at  ;
    const expiresAt1 = user?.subscription?.expires_at  ;
console.log(expiresAt1);
    if (expiresAt ) {
        const [datePart, timePart] = expiresAt.split("T");
        const [year, month, day] = datePart.split("-");
      
        
    return `${day}/${month}/${year}`;}
  }
function formatDate1(date) {
    
    const expiresAt1 = user?.subscription?.expires_at  ;
console.log(expiresAt1);
    if (expiresAt1 ) {
        const [datePart, timePart] = expiresAt1.split("T");
        const [year, month, day] = datePart.split("-");
      
        
    return `${day}/${month}/${year}`;}
  }
  console.log(data,"asasas");
  return (
   <>
    <div className='d-flex justify-content-center'>
        <div className='col-md-5 '>
        

            <Grid container>
                <Grid item xs={12}>
                    <h2>Billing</h2>
                </Grid>
            </Grid>
            {(user?.trial)?
            <Grid container className="p-2" >
                <Grid item xs={9} style={{backgroundColor:"#EBF1FC"}} className="px-3 py-2 d-flex justify-content-between align-items-center">
                    <div>
                        <h3 className='mb-0'>Trial</h3>
                        <p className='mb-0'>Expiration Date: {formatDate(user?.trial?.expires_at)}</p>
                    </div>
                    <div>
                       <Button variant="contained" style={{backgroundColor:"white",color:"green"}}>Active</Button>
                    </div>
                </Grid>
                <Grid item xs={9} style={{backgroundColor:"#EBF1FC"}} className="px-3 py-2 d-flex justify-content-between   align-items-center">
                    <div className='p-2 mt-3' style={{backgroundColor:"white",borderRadius:"10px"}}>
                        <h6 className='mb-1'>Scan</h6>
                        <p className='d-flex text-muted justify-content-center w-100'>{data?.scan_count}/unlimited</p>
                    </div>
                    <div className='p-2 mt-3' style={{backgroundColor:"white",borderRadius:"10px"}}>
                        <h6 className='mb-1'>Dynamic Codes</h6>
                        <p className='d-flex text-muted justify-content-center w-100'>{data?.dynamic_count}/unlimited</p>
                    </div>
                    <div className='p-2 mt-3' style={{backgroundColor:"white",borderRadius:"10px"}}>
                        <h6 className='mb-1'>Static Codes</h6>
                        <p className='d-flex text-muted justify-content-center w-100'>{data?.static_count}/unlimited</p>
                    </div>
                    
                    
                </Grid>
            </Grid>:user && <Grid container className="p-2" >
                <Grid item xs={9} style={{backgroundColor:"#EBF1FC"}} className="px-3 py-2 d-flex justify-content-between align-items-center">
                    <div>
                        <h3 className='mb-0'>Subscription</h3>
                        <p className='mb-0'>Expiration Date: {formatDate1(user?.subscription?.expires_at)}</p>
                    </div>
                    <div>
                       <Button variant="contained" style={{backgroundColor:"white",color:"green"}}>Active</Button>
                    </div>
                </Grid>
                <Grid item xs={9} style={{backgroundColor:"#EBF1FC"}} className="px-3 py-2 d-flex justify-content-between   align-items-center">
                    <div className='p-2 mt-3' style={{backgroundColor:"white",borderRadius:"10px"}}>
                        <h6 className='mb-1'>Scan</h6>
                        <p className='d-flex text-muted justify-content-center w-100'>17/unlimited</p>
                    </div>
                    <div className='p-2 mt-3' style={{backgroundColor:"white",borderRadius:"10px"}}>
                        <h6 className='mb-1'>Dynamic Codes</h6>
                        <p className='d-flex text-muted justify-content-center w-100'>6/500</p>
                    </div>
                    <div className='p-2 mt-3' style={{backgroundColor:"white",borderRadius:"10px"}}>
                        <h6 className='mb-1'>Static Codes</h6>
                        <p className='d-flex text-muted justify-content-center w-100'>2/Unlimited</p>
                    </div>
                 
                    
                </Grid>
            </Grid>}
            <Grid container className="" >
                <Grid item xs={9}  className="px-3 py-2 d-flex justify-content-between   align-items-center">
                   <Button onClick={()=>navigate("/prices")} className="w-100" variant="contained" style={{backgroundColor:"#EBF1FC",color:"#3880E6"}}>Upgrade</Button>
                    
                </Grid>
                </Grid>

      
        </div>
        </div>
   </>
  )
}

export default Billing