import './App.css';
import { Route, Routes,BrowserRouter, Navigate } from "react-router-dom";
import Navbar from './components/Navbar';
import Dashboard from './pages/Dashboard';
import Qrcode from './pages/Qrcode';
import Analytics from './pages/Analytics';
import Archive from './pages/Archive';
import Folders from './pages/Folders';
import CreateQr from './pages/CreateQr';
import StepOne from './components/StepOne';
import Url from './components/Qrcode/Url';
import Login  from './pages/Login';
import Signup from './pages/Signup';
import ResetPassword from './pages/ResetPassword';
import { useEffect } from 'react';
import apiClient from './api/apiClient';
import{ Home} from './pages/Home';
import { Layout } from './pages/Layout';
import About from './pages/About';
import Prices from './pages/Prices';
import Contact from './pages/Contact';
import Services from './pages/Services';
import OurBlogs from './pages/OurBlogs';
import Profile from './pages/Profile';
import ProtectedRoute from './components/ProtectedRoute';
import StepThree from './components/StepThreeEdit';
import ContentEdit from './components/ContentEdit';
import Blog from './pages/Blog';
import BlogsDashboard from './pages/BlogsDashboard';
import BlogDetail from './components/BlogDetail';
import PdfScan from './components/PdfScan';
import ImagesScan from './components/ImagesScan';
import FeedbackScan from './components/FeedbackScan';
import SocialMediaScan from './components/SocialMediaScan';
import ScanPreview from './pages/ScanPreview';
import Loading from './components/Loading';
import Calender from './components/mui/Calender';
import Billing from './pages/Billing';
import SingleQrAnalsytics from './pages/SingleQrAnalytics';


function App() {

  return (
    <>
    <Routes>
    {/* <Route path='/layout' element={<Layout/>} /> */}
    <Route path='/login' element={<Login/>}/>
    <Route path='/signup' element={<Signup/>}/>
    <Route path='/resetpassword' element={<ResetPassword/>}/>
    <Route element={<Layout/>}>
    <Route path='/home' element={<Home/>}/>
    <Route path='/about' element={<About/>}/>
    <Route path='/prices' element={<Prices/>}/>
    <Route path='/contact' element={<Contact/>}/>
    <Route path='/services' element={<Services/>}/>
    <Route path='/pdfscan' element={<PdfScan/>}/>
    <Route path='/imagesscan' element={<ImagesScan/>}/>
  <Route path='/feedbackscan' element={<FeedbackScan/>}/>
  <Route path='/socialmediascan' element={<SocialMediaScan/>}/>
    <Route path='/blogs' element={<OurBlogs/>}/>
    <Route path='/blogs-detail/:id' element={<BlogDetail/>}/>
    <Route path='/' exact element={<Navigate replace to={"home"}/>}/>
    </Route>
  
  <Route element={<ProtectedRoute>
    <Navbar />
  </ProtectedRoute> }>
    <Route path='/admin/dashboard' element={<Dashboard/>} />
    <Route path='/admin/qrode' element={<Qrcode/>}/>
    <Route path='/admin/analytics' element={<Analytics/>}/>
    <Route path='/admin/analytics/:id' element={<SingleQrAnalsytics/>}/>
    <Route path='/admin/archive' element={<Archive/>}/>
    <Route path='/admin/folders' element={<Folders/>}/>
    <Route path='/admin/folders/:id' element={<Qrcode/>}/>
    <Route path='/admin/createqr' element={<CreateQr/>}/>
    <Route path='/admin/stepone' element={<StepOne/>}/>
    <Route path='/admin/url' element={<Url/>}/> 
    <Route path='/admin/profile' element={<Profile/>}/> 
    <Route path='/admin/billing' element={<Billing/>}/> 
    <Route path='/admin/qrode/modify/:id' element={<StepThree/>}/> 
    <Route path='/admin/qrode/modifyContent/:id' element={<ContentEdit/>}/> 
    <Route path='/admin/blog' element={<Blog/>}/> 
    <Route path='/admin/blog-edit/:id' element={<Blog/>}/> 
    <Route path='/admin/blogWrite' element={<BlogsDashboard/>}/> 
  </Route>
    
  
  <Route path='/scans/preview/:id' element={<ScanPreview/>}/>
  <Route path='/socialmediascan' element={<SocialMediaScan/>}/>
  <Route path='/can' element={<Calender/>}/>
</Routes>
    </>
  );
}

export default App;
