import React, { useState } from 'react'
import apiClient from '../api/apiClient'
import { toast } from 'react-toastify'
import { useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'

const Contact = () => {


  const {isLoggedIn} = useSelector((state) => state.auth)
  const navigate=useNavigate()
  console.log(isLoggedIn,"iopl");
let initialState={
  name:"",
  email:"",
  phone:"",
  subject:"",
  message:""
}

  const [contact,setContact]=useState(initialState)
const handleChange=(key,value)=>{
  setContact({...contact,[key]:value})
}
const handleSubmit=async(e)=>{
  e.preventDefault()
  const result =await apiClient.post("/contact",contact)
  if(!result.ok) return toast.error("Error")
  toast.success("Successfully Submit")
setContact(initialState)
  console.log(contact);
}
const handleNavigate=()=>{
  if(isLoggedIn)
  {
    navigate("/admin/createqr")
  }
  else{
    navigate("/login")
  }
}

  return (
    <>
    <div className="top bg-lightblue mb-3 mx-3 rounded-bottom-4 py-2">
     
      {/* -----------------
    start|| hero
----------------- */}
      <div className="hero-sec">
        <div className="mycontainer">
          <div className="row d-flex justify-content-center py-5 my-5">
            <div className="col-12 col-md-8 col-lg-6 text-center">
              <div>
                <h1 className="fw-bolder display-3">
                  <span className=" blue-clr">Contact Us</span>
                </h1>
                <p>
                  Elevate your business, captivate your audience, and connect
                  with simplicity. At InstantQRcodes.com, we empower you to
                  create, customize, and track QR codes like never before.
                </p>
              </div>
              <div className="mt-5">
                <a style={{cursor:"pointer"}}
                onClick={()=>handleNavigate()}
                  className="bg-blue w-auto text-center text-decoration-none px-3 bg-myblue text-white py-3 px-4 rounded-4 fs-6 bs-opacity mt-3 mt-md-0"
                >
                  <span className="me-3">Create QR code</span>
                  <i className="fa-solid fa-arrow-right" />
                </a>
              </div>
              <div className="blogs-img mt-5">
                <img src={require("../assets/barcode.png")} alt="" />
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* -----------------
    end|| hero 
----------------- */}
    </div>
    {/* -----------------
    start || Contact Us
----------------- */}
    <div className="contact1 py-5">
      <div className="mycontainer">
        <div className="row d-flex justify-content-center justify-content-lg-between align-items-center">
          <div className="col-md-10 col-12 col-lg-6">
            <div className="d-flex align-items-center px-xl-5">
              <img
                src={require("../assets/cardqr.png")}
                alt="cardqr"
                className="contact-card"
              />
              <p className="mb-0 mx-2 fs-5 fw-semi">Contact Us</p>
            </div>
            <div className="ps-xl-5">
              <h2 className="fw-bold display-3">
                <span className="fw-bold blue-clr">
                  Feel free to get in touch with
                </span>
                our expert.
              </h2>
              <p className="mb-0 mt-4">
                In publishing and graphic design, Lorem ipsum is a placeholder
                text commonly used to demonstrate the visual form of a document
                or a typeface without relying on meaningful content. Lorem ipsum
                may be used as a placeholder before final copy is available.
              </p>
            </div>
            <div className="row d-flex justify-content-start ps-xl-5">
              <div className="col-12 col-md-6 d-flex align-items-center justify-content-start my-4 ">
                <img
                  src={require("../assets/phoneblue.png")}
                  className="img-fluid blue-img"
                  alt="phone"
                />
                <div className="ms-2">
                  <p className="mb-0">+92 318 232 232 3</p>
                  <p className="mb-0 text-secondary">Info@fabtechsol.com</p>
                </div>
              </div>
              <div className="col-12 col-md-6 d-flex align-items-center justify-content-start my-lg-4 ">
                <img
                  src={require("../assets/locationblue.png")}
                  className="img-fluid blue-img"
                  alt="phone"
                />
                <div className="ms-2">
                  <p className="mb-0">
                    First Floor Akbar Plaza near rescue 1122, Sialkot City
                  </p>
                </div>
              </div>
            </div>
          </div>
          <div className="col-md-10 col-lg-6 mt-4 mt-lg-0">
            <form onSubmit={handleSubmit}>
              <div className="row">
                <div className="col-12">
                  <div className="d-md-flex justify-content-between m-2">
                    <input
                    value={contact.name}
                    onChange={(e)=>handleChange("name",e.target.value)}
                      className="form-control my-2 my-md-0 me-md-2"
                      type="text"
                      placeholder="Name"
                    />
                    <input
                    value={contact.email}
                    onChange={(e)=>handleChange("email",e.target.value)}
                      className="form-control my-2 my-md-0 ms-md-2"
                      type="email"
                      placeholder="Your Email"
                    />
                  </div>
                  <div className="d-md-flex justify-content-between m-2">
                    <input
                      value={contact.phone}
                      onChange={(e)=>handleChange("phone",e.target.value)}
                      className="form-control my-2 me-md-2"
                      type="tel"
                      placeholder="Phone Number"
                    />
                    <input
                      value={contact.subject}
                      onChange={(e)=>handleChange("subject",e.target.value)}
                      className="form-control my-2 ms-md-2"
                      type="text"
                      placeholder="Subject"
                    />
                  </div>
                  <div className="m-2">
                    <textarea
                      value={contact.message}
                      onChange={(e)=>handleChange("message",e.target.value)}
                      name="Message"
                      cols={30}
                      rows={6}
                      className="form-control "
                      placeholder="Message"
                      defaultValue={""}
                    />
                  </div>
                  <button
                    type="submit"
                    className="d-flex justify-content-between align-items-center blue-bg mt-4 mt-lg-0 mx-2 border-0 rounded-3  px-3 py-2"
                  >
                    <p className="mb-0 me-2 fs-5 text-white">Send Message</p>
                    <i className="mb-0 ms-2 fs-5 text-white fa-solid fa-paper-plane" />
                  </button>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
    {/* -----------------
        end || Contact Us
----------------- */}
    {/* -----------------
    start || banner
----------------- */}
    <div className="py-5">
      <div className="mycontainer">
        <div className="row justify-content-center my-3 blue-bg py-3 px-3 rounded-4">
          <div className="banner-bg d-flex align-items-center justify-content-between py-3 px-4">
            <div className="col-lg-6">
              <p className="mb-0 text-white fs-2">
                Feel free to get in touch with our expert
              </p>
            </div>
            <div className="col-lg-6 d-flex justify-content-end">
              <div className="banner-img">
                <img src={require("../assets/banner-img.png")} alt="" />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    {/* -----------------
        end || banner
----------------- */}
    {/* -----------------
    start || our expert 
----------------- */}
    <div className="expert">
      <div className="mycontainer">
        <div className="row d-flex justify-content-center align-items-center my-3 p-md-5">
          <div className="col-12 col-lg-3 text-center ">
            <img
              src={require("../assets/phonecircle.png")}
              className="img-fluid"
              alt="baner"
            />
          </div>
          <div className="col-12 col-lg-9 text-center text-lg-start mt-4 mt-lg-0">
            <h2 className="fw-bolder display-3">
              <span className="blue-clr">Talk with our expert &amp;</span> get
              your Issue resolved.
            </h2>
            <p className="mb-0 mt-4">
              In publishing and graphic design, Lorem ipsum is a placeholder
              text commonly used to demonstrate the visual form of a document or
              a typeface without relying on meaningful content. Lorem ipsum may
              be used as a placeholder before final copy is available.
            </p>
          </div>
        </div>
      </div>
    </div>
    {/* -----------------
        end || our expert 
----------------- */}
    {/* -----------------
    start || SUBSCRIBE 
----------------- */}
    <div className="Subscribe">
      <div className="mycontainer">
        <div className="row d-flex justify-content-center align-items-center  p-sm-5">
          <div className="col-12 col-lg-7">
            <div className="text-center">
              <img src={require("../assets/QR Scanner.png")} alt="scanner" />
              <p className="my-3">
                In publishing and graphic design, Lorem ipsum is a placeholder
                text commonly used to demonstrate the visual form of a document
                or a typeface without relying on meaningful content.
              </p>
            </div>
            <div>
              <form action="#">
                <div className="row d-flex justify-content-between align-items-center  lightblue-bg p-2 rounded-4">
                  <div className="col-12 col-sm-8  getstarted py-2 py-sm-0">
                    <input
                      type="email"
                      className="bg-transparent border-0 w-100"
                      id="mail"
                      placeholder="ENTER YOUR EMAIL ADDRESS"
                    />
                  </div>
                  <div className="col-12 col-sm-4 d-none d-sm-flex justify-content-sm-end">
                    <button
                      type="submit"
                      className="bg-myblue text-white py-3 px-4 rounded-3 border-0 fs-6"
                    >
                      SUBSCRIBE
                    </button>
                  </div>
                </div>
                <div className="row">
                  <div className="col-12 d-sm-none d-flex justify-content-end my-4 ps-0 ms-0">
                    <button
                      type="submit"
                      className="bg-myblue w-100 text-white py-3 px-4 rounded-3 border-0 fs-6"
                    >
                      SUBSCRIBE
                    </button>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  </>
  )
}

export default Contact
